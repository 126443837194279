<template>
  <div class="render">
    <div class="top">
      <h1>Аналитика</h1>
    </div>
    <div class="my_toolbar">
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          active-class="my_active_tab"
          class="item_tab"
          v-for="item in tabs"
          :key="item"
          >{{ item }}</v-tab
        >
      </v-tabs>
    </div>
    <div class="content_table">
      <v-tabs-items v-model="tab">
        <v-tab-item key="Цели">
          <FilterReports
            :c_tab="tab"
            :page_type="'reports'"
            :selects="selects_load"
            :callback="renderTargets"
          />
          <Table
            :server="false"
            :hideselect="true"
            :loading="loading_target"
            :headers="table.headers_targets"
            :data="table.targets.data"
            :perPage="perPage"
            :callback_refresh="renderTargets"
          />
        </v-tab-item>
        <v-tab-item key="Скрипты обзвона">
          <FilterReports
            :c_tab="tab"
            :page_type="'scripts'"
            :selects="selects_load"
            :callback="renderScripts"
          />
          <Table
            v-if="table.scripts.data"
            :server="false"
            :hideselect="true"
            :loading="loading_scripts"
            :headers="table.headers_scripts"
            :data="table.scripts.data"
            :perPage="perPage"
            :callback_refresh="renderScripts"
          />
        </v-tab-item>
        <v-tab-item key="Базы номеров">
          <FilterReports
            :c_tab="tab"
            :page_type="'scripts'"
            :selects="selects_load"
            :callback="renderBases"
          />
          <Table
            v-if="table.bases.data"
            :server="false"
            :hideselect="true"
            :loading="loading_bases"
            :headers="table.headers_bases"
            :data="table.bases.data"
            :perPage="perPage"
            :callback_refresh="renderBases"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
// import CreateCall from '../components/Modal/Calls/CreateCall.vue'
import Table from "../components/Table/DataTable.vue";
// import Icon from '../components/SetIcon.vue'
import FilterReports from "../components/Filter/ReportsFilter";

import { Chart as ChartJS, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(ArcElement, CategoryScale);
export default {
  components: {
    Table,
    // CreateCall,
    // Icon,
    FilterReports,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 178,
    },
    height: {
      type: Number,
      default: 178,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  beforeMount() {
    this.renderTargets();
    this.renderScripts();
    this.renderBases();
  },
  data() {
    return {
      loading_target: true,
      loading_scripts: true,
      loading_bases: true,
      perPage: 10,
      selects_load: [],
      filters: {},

      show_dialog: false,

      tabs: ["Цели", "Скрипты обзвона", "Базы номеров"],
      tab: "Цели",
      table: {
        targets: {
          data: [],
          // pageCount_server: 0,
        },
        scripts: {
          data: [],
          // pageCount_server: 0,
        },
        bases: {
          data: [],
          // pageCount_server: 0,
        },
        headers_targets: [
          { text: "Цель", value: "Target_Name" },
          { text: "Количество", value: "Amount_Target" },
          { text: "Скрипт", value: "Id_Script" },
          { text: "Цена одной конверсии", value: "Price_One_Target" },
          { text: "Процент достижения", value: "Pcosent_Convesion_Target1" },
        ],
        headers_scripts: [
          { text: "Название", value: "Id_Script" },
          { text: "Дозвонов", value: "Count_calls_true" },
          { text: "Процент недозвонов", value: "Degree_Of_Failure" },
          { text: "Расход", value: "Expense" },
          { text: "Цена одного звонка", value: "Average_Cost_One_Call" },
          { text: "Количество успешных", value: "Convesion_Target" },
          { text: "Процент успешных", value: "Pcosent_Convesion_Target" },
          { text: "Цена успешного звонка", value: "PriceOfCallWithTarget" },
        ],
        headers_bases: [
          { text: "Название", value: "Id_Base" },
          { text: "Дозвонов", value: "Count_calls_true" },
          { text: "Процент недозвонов", value: "Degree_Of_Failure" },
          { text: "Расход", value: "Expense" },
          { text: "Цена одного звонка", value: "Average_Cost_One_Call" },
          { text: "Среднее время разговора", value: "Average_Time" },
          { text: "Количество успешных", value: "Convesion_Target" },
          { text: "Процент успешных", value: "Pcosent_Convesion_Target" },
          { text: "Цена успешного звонка", value: "PriceOfCallWithTarget" },
        ],
      },
    };
  },
  computed: {},
  methods: {
    render() {
      this.renderDetail();
    },
    renderTargets(filtres = false) {
      if (!filtres) {
        filtres = {
          startDate: "",
          endDate: "",
          filterScript: NaN,
        };
      }
      this.loading_target = true;
      try {
        this.$http.post(
          "/reports/target",
          {
            // startData: startDate,
            // endData: endDate,
            // filterScript: filterScript
            ...filtres,
          },
          (data) => {
            this.table.targets.data = data;

            this.loading_target = false;
            this.selects_load = [
              ...new Set(data.map((bill) => bill.Id_Script)),
            ];
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    renderScripts(filtres = false) {
      if (!filtres) {
        filtres = {
          startDate: "",
          endDate: "",
        };
      }
      this.loading_scripts = true;
      try {
        this.$http.post(
          "/reports/scripts",
          {
            ...filtres,
          },
          (data) => {
            this.table.scripts.data = data;
            this.loading_scripts = false;
            //this.selects_load = [...new Set(data.map(bill => bill.Script))]
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    renderBases(filtres = false) {
      if (!filtres) {
        filtres = {
          startDate: "",
          endDate: "",
        };
      }
      this.loading_bases = true;
      try {
        this.$http.post(
          "/reports/bases",
          {
            ...filtres,
          },
          (data) => {
            this.table.bases.data = data;
            this.loading_bases = false;
            //this.selects_load = [...new Set(data.map(bill => bill.Script))]
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.content_table {
  margin-top: 24px;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.add {
  border: none;
  padding: 12px 20px 12px 22px;
  background: #fee600 !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2b2d33;
  cursor: pointer;
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.add .icon {
  margin-right: 11px;
}

.item_tab {
  text-transform: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #808185;
}

.my_active_tab {
  color: #2b2d33;
}

.my_toolbar {
  margin-top: 16px;
}

.num_big {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #404247;
  max-width: 75px;
  position: absolute;
  top: 55px;
  width: 75px;
  left: 51px;
}

.block_item {
  padding: 16px 24px;
  border-radius: 8px;

  width: 265px;
  margin-bottom: 36px;
}

.block_item:nth-child(odd) {
  margin-right: 20px;
}

.small_blocks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
}

.block_item .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
  margin-bottom: 12px;
  text-align: left;
}

.block_item .num {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}

.my_red .num {
  color: #ee505a;
}

.my_gray {
  background: #f8f8f8;
}

.my_red {
  background: #fdeeee;
}

.my_green {
  background: #e8f7f4;
}

.block {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 24px;
  justify-content: center;
  max-width: 600px;
}

.big_block {
  background: #e8f7f4;
  border-radius: 8px;
  display: flex;
  padding: 24px 16px;
  min-width: 500px;
  max-height: 230px;
}

.right {
  margin-left: 32px;
}

.heading {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  line-height: 130%;
  color: #404247;
}

.robot,
.client,
.silence {
  text-align: left;
  position: relative;
  margin-top: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  padding-left: 25px;
  line-height: 120%;
  color: #404247;
}

.robot:before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #faa373;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.client:before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #57b6ed;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.silence:before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #9a99fb;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.content {
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1000;
}

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 500px;
}

.subtitle {
  max-width: 450px;
  margin-bottom: 32px;
}

.left {
  position: relative;
}

@media (max-width: 1000px) {
  .filters {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .top {
    justify-content: center;
  }

  .big_block {
    flex-direction: column;
    max-height: initial;
    min-width: initial;
  }

  .num_big {
    left: calc(50% - 36px);
  }

  .right {
    margin-left: 0;
  }
}

@media (max-width: 620px) {
  .block_item:nth-child(odd) {
    margin-right: 0;
  }

  .small_blocks {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  h1 {
    margin-top: 15px;
  }
}
</style>
