import { render, staticRenderFns } from "./CardIntegration.vue?vue&type=template&id=50d78cad&scoped=true"
import script from "./CardIntegration.vue?vue&type=script&lang=js"
export * from "./CardIntegration.vue?vue&type=script&lang=js"
import style0 from "./CardIntegration.vue?vue&type=style&index=0&id=50d78cad&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d78cad",
  null
  
)

export default component.exports