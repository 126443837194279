<template>
	<div class="render">
		<AccountActivation/>
		<v-breadcrumbs class="bc" v-if="!history_hide" :items="breadcrumbs" large>
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item
					@click="returnMain(item.click)"
					:disabled="item.disabled"
				>
					{{ item.text }}
				</v-breadcrumbs-item>
			</template>
		</v-breadcrumbs>
		<div v-if="history_hide" class="content">
			<div class="left">
				<div class="inform">
					<div class="balance">
						<div class="title">
							Баланс
						</div>
						<div class="num">
							{{ this.$store.getters['user/getBalance'] }} ₽
						</div>
					</div>
				</div>
				<div @click="openBalanceAdd" class="btn_style_1 btn_style">Пополнить</div>
				<div class="history" @click="breadActiveClick(0)">Смотреть историю баланса</div>
			</div>
			<div class="left">
				<div class="inform">
					<div class="balance">
						<div class="title">
							Минуты
						</div>
						<div class="num">
							{{ this.$store.getters['user/getMinutes'] }}
						</div>
					</div>
				</div>
				<div @click="showBuyMinutes = true" class="btn_style_2 btn_style">Купить минуты</div>
				<div class="history" @click="breadActiveClick(1)">Смотреть историю покупок</div>

			</div>
			<div class="additional_services">
				<div class="block_service">
					<h3>Другие услуги</h3>
					<div class="history history_service" @click="breadActiveClick(2)">Смотреть историю оплаченных услуг</div>
				</div>

				<div class="content">
					<div class="bg_card_services" v-for="(service, key) of servicesArr" :key="key">
						<div class="services_card_head mb-6">
							<img src="../assets/img/png/services.png" alt="services_icon">
							<p>{{ service.text }}</p>
						</div>
						<div class="services_card_footer">
							<div>то <span class="text_bold">{{ service.cost }} ₽</span></div>
							<a class="history" :href="service.link">Подробнее</a>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div v-if="!history_hide && breadActive === 0" class="content_table">
			<div class="history_title">История баланса</div>
			<div class="desc">Здесь отображены все денежные операции вашего аккаунта</div>
			<Table :headers="headers" :data="dataPayments" :hideselect="true"/>
		</div>
		<div v-if="!history_hide && breadActive === 1" class="content_table">
			<div class="history_title">История покупок минут</div>
			<div class="desc">Здесь отображены все покупки пакетов минут вашего аккаунта</div>
			<Table :headers="headersMinutes" :data="dataBuyMinutes" :hideselect="true"/>
		</div>
		<div v-if="!history_hide && breadActive === 2" class="content_table">
			<div class="history_title">История оплаченных услуг</div>
			<div class="desc">Здесь отображены все оплаченные услуги вашего аккаунта</div>
			<Table :headers="headersServices" :data="dataBuyServices" :hideselect="true"/>
		</div>
		<!-- <CompanyData /> -->
		<SuccessBalance :dialogstate="showSuccessBalance" :dialogclose="closeSuccessBalance"/>
		<Balance :dialogstate="showBalanceAdd" :dialogclose="closeBalanceAdd" :opensucces="openSuccessBalance" :updatetable="getBalanceTable"/>
		<!--    <CompanyEdit :dialogstate="showCompanyEdit" :dialogclose="closeCompanyEdit" :setbar="setTopBar"/>-->
		<BuyMinutes :dialogShow="showBuyMinutes" @close="dialogBuyMinutesClose" @addBalance="openBalanceAdd(150)"/>
	</div>
</template>
<script>
import SuccessBalance from '../components/Modal/Cabinet/BalanceSuccess.vue'
//import CompanyData from '../components/Modal/Cabinet/CompanyData.vue'
//import CompanyEdit from '../components/Modal/Cabinet/CompanyEdit.vue'
import Balance from '../components/Modal/Cabinet/BalanceAdd.vue'
//import Icon from '../components/SetIcon.vue'
import Table from '../components/Table/DataTable.vue'
import AccountActivation from '@/components/Modal/Cabinet/AccountActivation.vue'
import BuyMinutes from "@/components/Modal/Cabinet/BuyMinutes";

export default {
	components: {
		SuccessBalance,
		//Icon,
		Table,
		//CompanyEdit,
		Balance,
		AccountActivation,
		BuyMinutes
	},
	data() {
		return {
			history_hide: true,
			// activated: this.$store.getters['user/getUserFields'].user_status,
			email: this.$store.getters['user/getEmail'],
			balance: this.$store.getters['user/getUserFields'].balance,
			email_upadted: false,
			password: "",
			new_password: "",
			update_password_status: 0,
			call_end_notification: this.$store.getters['user/getUserFields'].call_end_notification,
			low_balance_notification: this.$store.getters['user/getUserFields'].low_balance_notification,
			headers: [
				{text: '№', align: 'start', value: 'id'},
				{text: 'Тип операции', value: 'type'},
				{text: 'Сумма', value: 'sum'},
				{text: 'Дата пополнения', value: 'date'},
				{text: 'Статус', value: 'status'}
			],
			headersMinutes: [
				{text: '№', align: 'start', value: 'id'},
				{text: 'Цена', value: 'price'},
				{text: 'Минуты', value: 'minutes'},
				{text: 'Дата покупки', value: 'created_at'},
				{text: 'Статус', value: 'status'}
			],
			headersServices: [
				{text: '№', align: 'start', value: 'id'},
				{text: 'Услуга', value: 'service'},
				{text: 'Стоимость', value: 'price'},
				{text: 'Дата покупки', value: 'created_at'},
			],
			dataPayments: this.$store.getters['user/getUserFields'].payments,
			dataBuyMinutes: [],
			dataBuyServices: [],
			showBalanceAdd: false,
			showSuccessBalance: false,
			showBuyMinutes: false,
			breadActive: 0,
			servicesArr: [],

		}
	},
	computed: {
		breadcrumbs(){
			return [
				{
					text: 'Личный кабинет',
					disabled: false,
					click: 'index'
				},
				{
					text: this.breadActive === 0 ? 'История пополнения баланса'
						: this.breadActive === 1 ? 'История покупок минут'
						: this.breadActive === 2 ? 'История оплаченных услуг' : '',
					disabled: true,
					click: false,
				}
			]
		}
	},
	methods: {
		breadActiveClick(num){
			this.breadActive = num
			this.history_hide = false
			if(num === 1){
				this.$http.post("/cabinet/get_minute_table",
				{}, (result) => {
					if (result) {
						result.map((item, inx) => item.id = inx + 1)
						this.dataBuyMinutes = result
					}
				})
			} else if (num === 2){
				this.$http.post("/cabinet/get_service_table",
				{}, (result) => {
					if (result) {
						result.map((item, inx) => item.id = inx + 1)
						this.dataBuyServices = result
					}
				})
			}
		},
		dialogBuyMinutesClose(){
			this.showBuyMinutes = false
		},
		getBalanceTable() {
			this.data = this.$store.getters['user/getUserFields'].payments
		},
		openSuccessBalance() {
			this.showSuccessBalance = true;
		},
		closeSuccessBalance() {
			this.showSuccessBalance = false
		},
		openBalanceAdd( sec = 0) {
			setTimeout(() => {this.showBalanceAdd = true;}, sec)
		},
		closeBalanceAdd() {
			this.showBalanceAdd = false
		},

		returnMain(page) {
			if (page == "index") {
				this.history_hide = true
			}
		},

		renderExtraServices() {
			this.$http.post("/extra_services/list_services",
				{}, (result) => {
					if (result) {
						this.servicesArr = result.filter((service) => service.text && service.cost && service.link)
												.sort((a,b) => a.priority - b.priority)
					}
				})
		},
	},
	mounted() {
		this.renderExtraServices()
	}
}
</script>
<style scoped>
.v_email_updated_massage {
	text-align: left;
	color: green;
}

.v_err_updated_massage {
	text-align: left;
	color: #b01111;
}

.mb26 {
	margin-bottom: 26px;
}

.top {
	margin-bottom: 24px;
}

.block_service {
	display: flex;
	align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.history_title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 8px;
}

.desc {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 150%;
	color: #55575C;
	text-align: left;
	margin-bottom: 16px;
}

.ok {
	margin-top: 30px;
	background: #E8F7F4;
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px;
}

.btn_style {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #2B2D33;
	background: #FEE600;
	border-radius: 8px;
	padding: 13px;
	margin-top: 40px;
	cursor: pointer;
}

.btn_style_1 {
	background: #FEE600;
}

.btn_style_2 {
	background: none;
	border: 1px solid #FEE600;
}

.history {
	margin: 8px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
	position: relative;
	width: max-content;
}

.history:after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	background: #00809C;
	position: absolute;
	bottom: -4px;
}

.history_service {
    /*margin: 0 0 15px 0;*/
    margin: 0;
}

.save {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	padding: 9px 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	margin-top: 16px;
	line-height: 110%;
	color: #2B2D33;
	max-width: max-content;
	cursor: pointer;
}

.top {
	display: flex;
	flex-direction: column;
}

.inform {
	display: flex;
}

.bg_card_services{
	background: #FFFFFF;
	width: 49%;
	padding: 24px;
	min-width: 600px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	margin-bottom: 30px;
}

.left {
	background: #FFFFFF;
	padding: 24px;
	min-width: 600px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	/*width: 50%;*/
	width: 49%;
	height: max-content;
	margin-bottom: 50px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.right {
	width: 50%;
	padding-left: 32px;
}

h1 {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
}

h2 {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 24px;
}

.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.balance {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.left .title {
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 120%;
	color: #55575C;
	text-align: left;
}

.left .num {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.alert {
	background: #FEF3EC;
	padding: 16px 24px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;

}

.left_text {
	display: flex;
	align-items: center;
}

.left_text .text {
	margin-left: 18px;
}

.mmm_data {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	text-align: center;
	color: #2B2D33;
	padding: 7px 17px;
	cursor: pointer;
}

.ok .text {
	color: #008E7D;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
}

.additional_services {
	width: 100%;
}

.services_card_footer {
	display: flex;
	justify-content: space-between;
}

.services_card_head {
	display: flex;
	justify-content: flex-start;
}

.services_card_head p {
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 140%;
	margin-left: 50px;
	margin-right: 40px;
	text-align: left;
}

.services_card_footer a {
	margin: 0;
	text-decoration: none;
	height: max-content;
}

.additional_services h3 {
	text-align: left;
	/*margin-bottom: 25px;*/
	font-size: 24px;
}

.text_bold {
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
}

@media (max-width: 820px) {
	.alert {
		flex-direction: column;
	}

	.mmm_data {
		margin-top: 15px;
	}
}

@media (max-width: 650px) {
	.left {
		margin: 15px;
		min-width: initial;
		width: 100%;
	}

	.right {
		width: 100%;
		padding: 15px;
	}
}

@media (max-width: 520px) {
	.inform {
		flex-direction: column;
	}

	.balance {
		margin-bottom: 15px;
	}

	.history_title {
		padding: 10px;
	}

	.desc {
		padding: 10px;
	}
}
</style>
