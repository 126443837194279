<template>
  <div class="player"  v-bind:class="{ freeze: freeze }">
    <audio :id="item.id" v-if="audio_src || !syntese">
      <source :src="audio_src" type="audio/ogg">
      <source :src="audio_src" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>
    <a class="download" :href="item.record" target="_blank">
      <Icon name="download" />
    </a>
    <div class="btn_control" @click="controlPlay(item)">
      <Icon :name="play_status" />
    </div>
    <div class="time" v-if="!syntese || syntese_src">
      {{calcMin(currentTime)}} / {{calcMin(duration)}}
    </div>
    
    <v-slider
      class="slider"
      :max="duration"
      min="0"
      step="1"
      track-fill-color="#404247"
      thumb-color="#FED500"
      track-color="#AAABAD"
      color="#404247"
      hide-details
      v-model="currentTime"
      @change="changeTime"
    ></v-slider>
  </div>
</template>
<script>
import Icon from './SetIcon.vue'
export default {
  props: ['item','syntese'],
  components: {
    Icon
  },
  data(){
    return {
      currentTime: 0,
      syntese_src:false,
      freeze:false,
      audio_src:false,
      duration: 0,
      play_status:"play_active"
    }
  },
  methods: {
	loadAudioFile(){
		//обнуление перед лоадом
		this.currentTime = 0
		// this.syntese_src = false
		// this.freeze = false
		// this.audio_src = false
		this.duration = 0
		this.play_status = "play_active"

		let _this = this
		let audio = document.getElementById(this.item.id)
		this.audio_src=this.item.record

		if (audio)
		{
		audio.addEventListener('canplaythrough', () => {
			_this.duration = audio.duration
		})
		audio.addEventListener('ended', () => {
			_this.play_status = 'play_active'
		})
		audio.load()
		}

	},
    changeTime(e){
      let audio = document.getElementById(this.item.id)
      this.currentTime = e
      audio.currentTime = e
    },
    calcMin(sec){
      let seconds = Math.floor(sec % 60)
      let minutes = Math.floor(sec / 60)
      if(minutes.toString().length == 1) minutes = '0' + minutes
      if(seconds.toString().length == 1) seconds = '0' + seconds
      return `${minutes}:${seconds}`
    },
    controlPlay(item){
      let _this = this
      let audio
      if (this.syntese && this.syntese_src!=this.item.text_data.text+this.item.text_data.voice)
      {
        _this.audio_src=false
        _this.freeze=true
        this.$http.post("/script/syntese",
        {
          text: this.item.text_data.text,
          voice:this.item.text_data.voice
        },(data)=>{
          
          _this.syntese_src=this.item.text_data.text+this.item.text_data.voice;
          _this.audio_src=data;
          
          setTimeout(function()
          {
            _this.freeze=false
            audio=document.getElementById(_this.item.id)
            _this.duration = audio.duration
            _this.controlPlay(item)
            audio.addEventListener('ended', () => {
              _this.play_status = 'play_active'
            })
          },1000)
        
        })
        return false;
      }
      
      audio = document.getElementById(item.id)
      if(audio){
        if(this.play_status == 'play_active') {
          audio.play()
          audio.addEventListener('timeupdate', function() {
            _this.currentTime = Math.floor(audio.currentTime)
          })
          this.play_status = 'pause'
        } else {
          this.play_status = 'play_active'
          audio.pause()
        }
      }
    },
  },
	watch: {
	item: function (){
		console.log('сукаааааа ВАТЧ')
		this.loadAudioFile()
	}
  },
  mounted(){
    // let _this = this
    // const audio = document.getElementById(this.item.id)
    // this.audio_src=this.item.record
	//
    // if (audio)
    // {
    // audio.addEventListener('canplaythrough', () => {
    //   _this.duration = audio.duration
    // })
    // audio.addEventListener('ended', () => {
    //   _this.play_status = 'play_active'
    // })
    // }
	console.log('Сейчас юзаем МАУНТЕД!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
	this.loadAudioFile()
  }
}
</script>
<style scoped>
.freeze
{
opacity: 0.3;
pointer-events: none;  
}
.time
{
width: 110px;  
}
.record .player{
  display: flex;
  align-items: center;
}
.record .player .icon{
  margin-right: 10px;
}
.btn_control{
  width:30px;
  overflow: hidden;
  cursor: pointer;
}
.sliderMy{
  cursor: pointer;
}
@media (max-width: 600px){
  .slider{
    display: none;
  }
  .player{
    justify-content: center;
  }
}
</style>