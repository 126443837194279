<template>
  <!-- Шаблон компонента уведомлений -->
  <div class="notification-component w-100">
  
    <!-- Блок, отображаемый, если текущий пользователь не является администратором -->
    <div v-if="!$store.getters['user/getUserFields'].is_admin">
      <p>Вы не администратор, авторизуйтесь как администратор!</p>
    </div>
    
    <!-- Блок для администратора -->
    <div class="w-100 pl-r-2" v-else>
      <div class="notification-content w-100 p-1">
      
        <!-- Область ввода уведомления -->
        <div class="notification-inputs">  
          <!-- Поле для ввода номера, видимо только если не отправлять всем пользователям -->
          <v-text-field
            v-if="!sendToAllUsers"
            v-model="recipientNumber"
            label="Номер получателя"
            placeholder="+7 ___ ___-__-__"
            type="tel"
            v-maska="'+7 ### ###-##-##'"
            hide-details="auto"
            required
            class="w-250p"
          />
          <!-- Флажок для отправки всем пользователям -->
          <div class='newStyle'>
            <v-checkbox class='newModel' v-model="sendToAllUsers" label="Отправить всем пользователям"/>  
          </div>
          <!-- Поле для ввода текста уведомления -->
          <v-text-field
            v-model="notificationText"
            label="Текст уведомления"
            hide-details="auto"
            required
            auto-grow
          />
        </div>
        
        <!-- Кнопка для создания уведомления -->
        <div class="notification-controls">
          <button @click="sendNotification" class="word_control_save">Создать уведомление</button>
        </div>
        
        <!-- Оповещение об успешном создании уведомления -->
        <p class="save_message" v-if="saveMessage">Уведомление создано!</p>
      </div>
      
      <!-- Заголовок блока с непрочитанными уведомлениями -->
      <div class="top">
        <h1>Непрочитанные уведомления</h1>
      </div>
      
      <!-- Таблица для отображения уведомлений -->
      <Table
        class="w-100"
        :headers="table.headers"
        :hideselect="true"
        :data="table.data"
        :perPage="10"
        :loading="loading"
        page_type="your_page_type"
      ></Table>
    </div>
  </div>
</template>

<script>
// Импорт компонента таблицы
import Table from '../../components/Table/DataTable.vue'

export default {
  components: {
    Table
  },
  name: "NotificationComponent",
  data() {
    return {
      // Данные для таблицы
      table: {
        data: [],
        headers: [
          { text: 'Телефон', value: 'phone' },
          { text: 'Текст уведомления', value: 'text' },
          { text: 'Последняя авторизация', value: 'last_auth' },
        ]
      },
      // Текст уведомления, флажок отправки всем пользователям, номер получателя, оповещение об успешном создании
      notificationText: "",
      sendToAllUsers: false,
      recipientNumber: "",
      saveMessage: false,
      // Флаг загрузки данных
      loading: false
    };
  },
  methods: {
    // Метод для отправки уведомления
    sendNotification() {
      const data = {
        text: this.notificationText,
        sendToAllUsers: this.sendToAllUsers,
        number: this.sendToAllUsers ? null : this.recipientNumber
      };
      this.$http.post("/cabinet/add_notifications", data, result => {
        if (result) {
          // Оповещение об успешном создании уведомления
          this.saveMessage = true;
          setTimeout(() => {
            this.fetchData()
            this.saveMessage = false;
          }, 3000);
        }
      });
    },
    // Метод для получения данных
    fetchData() {
  this.loading = true;
  this.$http.post("/cabinet/get_all_notifications", {}, result => {
    const notifications = result;

    if (Array.isArray(notifications)) {
      // Преобразование данных для отображения в таблице
      this.table.data = notifications.map(notification => {
        return {
          phone: notification.phoneNumber,
          text: JSON.parse(notification.text).join(', '),
          last_auth: notification.last_auth
        };
      });
    }

    this.loading = false;
  });
},
  },
  mounted() {
    // Вызывается при монтировании компонента
    this.fetchData(); // Загружаем данные при монтировании
  }
};
</script>

<style scoped>
/* Стили для компонента */
.notification-component {
  text-align: center;
  width: 1000px;
  margin: 0 auto;
  margin-top: 1%;
}

.notification-content {
  display: inline-block;
  text-align: left;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.notification-inputs {
  margin-bottom: 20px;
}

.word_control_save {
  border: none;
  background: #fee600;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2b2d33;
  cursor: pointer;
  transition-duration: 0.3s;
  height: 40px;
  padding: 8px;
}

.word_control_save:hover {
  opacity: 0.9;
}

.save_message {
  margin-top: 20px;
  color: #1caf1c;
  font-size: 16px;
}

.top {
  margin-bottom: 24px;
  margin-left: 1%;
  height: 20px;
  position: relative;
}

.w-100 {
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

.p-1 {
  padding: 1%;
}

.pl-r-2 {
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 0px;
  padding-top: 0px;
}

.w-250p {
  width: 250px;
}

.newStyle {
  height: 50px;
  position: relative;
}

.newModel {
  position: absolute;
  top: 10px;
}

.h1 {
  position: absolute;
  top: 5px;
}
</style>