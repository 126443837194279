<template>
    <div>
        <p :class="shortOrLong()">{{text}}</p>
    </div>
</template>
<script>
export default {
    props: ['text'],
    methods: {
        shortOrLong(){
            if(this.text == 'Или') return 'short'
            else return 'long'
        }
    }
}
</script>
<style scoped>
p{
    position: relative;
}
p::before{
    content: '';
    left: 0;
    bottom: 12px;
    position: absolute;
    background: #D5D5D6;
    width: 130px;
    height: 1px;
}
p::after{
    content: '';
    right: 0;
    bottom: 12px;
    position: absolute;
    background: #D5D5D6;
    width: 130px;
    height: 1px;
}
p.long::after{
    width: 75px;
}
p.long::before{
    width: 75px;
}
</style>