<template>
	<div class="render">
		<div class="top">
			<h1>Номера</h1>
			<div class="add" @click="buyNumberFun()">
				<Icon name="plus"/>
				Добавить номер
			</div>
		</div>
		<!-- <div class="my_toolbar">
			<v-tabs v-model="tab" align-with-title>
				<v-tabs-slider color="yellow"></v-tabs-slider>
				<v-tab active-class="my_active_tab" class="item_tab" v-for="item in tabs" :key="item">{{ item }}</v-tab>
			</v-tabs>
		</div> -->
		<div class="content_table">

			<!-- <v-tabs-items v-model="tab">
				<v-tab-item :key="tabs[0]"> -->
					<div class="statistics_number">
						<div v-for="(stat, key) in statisticsNumber" :key="key" class="statistics_number__card">
							<div class="statistics_number__card__title">{{stat.title}}</div>
							<div class="statistics_number__card__number">{{stat.value}}</div>
						</div>
					</div>
					<v-container fluid>
						<v-row>
							<v-col
								cols="12"
								sm="4"
								md="4"
								xl="3"
								class="mt-6"
								v-for="card in arrNumberUser"
								:key="card.num"
							>
								<div class="card_rent_number">
									<div class="card_rent_number__active">
										<div class="number">{{card.num}}</div>
										<div class="number_active">
											<span v-if="card.standart != 1">Продлевать</span>
											<span v-if="card.standart == 1">Вечный номер</span>
											<v-switch
												v-if="card.standart != 1"
												v-model="card.active"
												color="info"
												:value="card.active"
												hide-details
												@click="toggleCardStatus(card.clear)"
											></v-switch>
										</div>
									</div>
									<div class="card_rent_number__scripts mb-7">
										<span>Cценарий входящего звонка</span>
										<v-combobox
											class="input_custom mt-2"
                      @change="toggleIncomingScript(card.clear,card.scriptCall.id)"
											v-model="card.scriptCall"
											item-value="id"
											:items="scriptsCall"
											item-text="name"
											hide-selected
											solo
											no-data-text="Скриптов нет"
											color="#FEE600"
											height="40"
											hide-details
											error-count="0"
											width="450"
										>
										</v-combobox>
									</div>
									<div class="card_rent_number_footer">
										<div v-bind:class="{ opacitizero: card.standart == 1 }">
											<span class="cost">{{card.cost}} руб./мес.</span><br>
											<span :style="{color: colorDate(card.date) ? '#E98C00' : '#55575C'}" class="next_buy">Следующее списание {{refactorDate(card.date)}}</span>
										</div>
										<!-- <div class="delete_icon" @click="deleteNumberOne(card.num)">
											<Icon name="delete" />
										</div> -->
									</div>
								</div>
							</v-col>
						</v-row>
					</v-container>
				<!-- </v-tab-item>
				<v-tab-item :key="tabs[1]">

				</v-tab-item>
			</v-tabs-items> -->
		</div>
		<FrameModalPopup :dialogShow="deletePopup" @close="closePopup">
			<div>
				<Icon name="warning"/>
			</div>
			<div class="popup_title">
				После удаления номера вы потеряете все оставшееся время аренды
			</div>
			<div class="popup_number">
				Вы уверены, что хотите удалить номер входящего <br><span>{{deleteNum}}</span> ?
			</div>
			<div class="my_btn add_modal" @click="deleteNumber()">Удалить номер</div>
		</FrameModalPopup>
		<FrameModalPopup :dialogShow="buyNumberPopup" @close="closePopup">
			<div v-if="resultBuyNumber.popupShow">
				<div class="popup_title">Добавить номер для входящих</div>
				<div class="search rel">
					<v-text-field
						v-model="filtersNumberBuy"
						type="tel"
						class="input_custom"
						placeholder="Введите номер"
						error-count="0"
						height="40"
						v-maska=""
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>
				</div>
				<div class="all_number_buy">
					<div class="all_number_buy__card" v-for="(number, key) in filteredNumbers" :key="key">
						<div class="all_number_buy__info">
							{{ number.num }} <br>
							<span>{{ number.cost }} руб. / мес.</span>
						</div>
						<div @click="buyRentNumber(number.clear)" class="replenishment">
							Арендовать номер
						</div>
					</div>
				</div>
			</div>
			<div v-else class="center">
				<div v-if="!resultBuyNumber.answerBack">
					<v-progress-circular
							indeterminate
							color="amber"
					></v-progress-circular>
					<div class="popup_title">Покупаем. Пожалуйста подождите</div>
				</div>
				<div v-else>
					<div v-if="resultBuyNumber.answerBack !== 1">
						<Icon name="warning"/>
					</div>
					<div v-if="resultBuyNumber.answerBack === 1">
						<div class="icon_green_ok"><Icon name="green_ok"/></div>
					</div>
					<div v-if="resultBuyNumber.answerBack === 1" class="popup_title">Номер успешно добавлен <br>в список арендованных номеров!</div>
					<div v-if="resultBuyNumber.answerBack === 2" class="popup_title">Недостаточно средств для аренды номера.</div>
					<div v-if="resultBuyNumber.answerBack === 3" class="popup_title">К сожалению номер уже занят :(</div>
				</div>
			</div>
		</FrameModalPopup>
	</div>
</template>
<script>

import Icon from '@/components/SetIcon'
import FrameModalPopup from "@/components/Modal/FrameModalPopup";
// import FilterCustom from '@/components/FilterMy.vue'
export default {
	name: "IncomingCalls",
	components: {Icon, FrameModalPopup},
	props: {},
	data() {
		return {
			showPopupRentNumber: false,
			tabs: ['Номера для входящих', 'История оплат'],
			tab: 'Номера для входящих',
			activeNumber: false,
			scriptsCall: [],
			selects: '',
			deletePopup: false,
			buyNumberPopup: false,
			filtersNumberBuy: '',
			deleteNum: '',
			arrNumberUser: [],
			arrNumberBuy: [],
			resultBuyNumber: {
				answerBack: '',
				popupShow: true
			},
			filteredNumbers: [],
		}
	},
	mounted() {
		this.render()
	},
	computed: {
		statisticsNumber(){
			return [
				{title: 'Всего номеров', value: this.arrNumberUser.length},
				{title: 'Активных номеров', value: this.arrNumberUser.reduce((count, cart) => {
						if (cart.active === true) count += 1
						return count
					}, 0)},
				{title: 'Сумма аренды', value: this.arrNumberUser.reduce((count, cart) => {
						if (cart.active === true) count += cart.cost
						return count
					}, 0)},
			]
		},
	},
	watch: {
		filtersNumberBuy(newVal){
			if (newVal) {
				this.filteredNumbers = this.arrNumberBuy.filter((item) => item.clear.includes(newVal))
			} else {
				this.filteredNumbers = this.arrNumberBuy
			}
		}
	},
	methods: {

    toggleIncomingScript(number,script_id){
      this.$http.post("/numbers/switch_incoming",
          {
            number:number,
            script_id:script_id
          }, (data) => {
            if (!data.ok) {
              alert("active error: please call to support")
            }
          })

    },
		toggleCardStatus(number){

			this.$http.post("/numbers/active_toggle",
				{ number:number }, (data) => {
					if (!data.ok) {

						alert("active error: please call to support")
					}
				})

		},
		render(){
			// пиздует запрос в бызу

			this.$http.post("/numbers/get_user_numbers",
				{ }, (data) => {
					let result_arr;
					let result = [];
					if (data.ok) {
							result_arr = data.data;
						let numberValueReduced = phone => {
								return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
							}
            console.log(result_arr)
						for(let item of result_arr){
								result.push({
									num : numberValueReduced(item.phone),
									clear : item.phone,
									cost : item.standart == 1 ?0:300,
									active : item.active == 0?false:true,
									scriptCall : item.scriptCall,
									date : item.date_end.substr(0, 10),
									standart: item.standart
								})
						}
						this.arrNumberUser = result
					}
					// console.log(result)

				})


			let _this = this
			this.$http.post("/script/list",
				{}, (data) => {
					console.log('/script/list', data)
            this.scriptsCall.push({id: 0, name: 'Без скрипта'})
					data.forEach((item) => {
						_this.scriptsCall.push({id: item.guid, name: item.name})
					})
				})
		},
		buyNumberFun(){
			// обнуляем значения
			this.resultBuyNumber.popupShow = true
			this.resultBuyNumber.answerBack = ''

			// тут пиздует запрос на дохуя номеров
			this.$http.post("/numbers/get_free_numbers",
				{}, (data) => {
					let result;
					if (data.ok) {
						result = data.data;
						let numberValueReduced = phone => {
							return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
						}
						for (let item of result) {
							item.num = numberValueReduced(item.clear);
							item.cost = 300;
						}
						this.arrNumberBuy = result
						this.filteredNumbers = result
					}
					console.log(result)

				})
			this.buyNumberPopup = true
			this.resultBuyNumber.popupShow = true
		},
		deleteNumberOne(num) {
			this.deletePopup = true
			this.deleteNum = num
		},
		deleteNumber(){
			alert('Удаляем номер который был выбран для удаления')
			this.deletePopup = false
		},
		closePopup(){
			this.deletePopup = false
			this.buyNumberPopup = false
		},
		refactorDate(date){
			return date.split('-').reverse().join('.')
		},
		colorDate(date){
			let today = new Date() //.toISOString().split('T')[0]
			let buyDate = new Date(date)

			let timeDiff = Math.abs(today.getTime() - buyDate.getTime());
			let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
			return diffDays < 7
		},
		buyRentNumber(number){
			// тут короче пиздует запрос на бек о покупке номера, ждем что скажет и обробатываем, а не забиваем хуй
			this.resultBuyNumber.popupShow = false
			this.$http.post("/numbers/rent_number",
				{number:number }, (data) => {
					if (data.ok) {
						if (data.status === 1) this.render()
						this.resultBuyNumber.answerBack = data.status
						// this.resultBuyNumber.popupShow = false
					}
				})

		},
	}
}
</script>
<style scoped>
.icon {
	justify-content: center;
}
.icon_green_ok {
    width: 33px;
    height: 33px;
	border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #008e7d;
	margin: 0 auto;
}
.all_number_buy__info {
	font-weight: 600;
	font-size: 16px;
}
.all_number_buy__info > span {
	font-weight: 400;
	font-size: 15px;
	color: #00809C;
}
.all_number_buy__card {
	display: flex;
	/*height: 72px;*/
	padding: 15px 0;
	justify-content: space-between;
	border-bottom: 1px solid #F0F0F0;
	text-align: left;
}

.pos_rel {
    position: relative;
}
.replenishment {
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	padding: 12px 17px;
	height: 44px;
	align-items: center;
	text-align: center;
	color: #2B2D33;
	background: #FFFFFF;
	border: 1px solid #FEE600;
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
}
.all_number_buy {
	border: 1px solid #E8E8E8;
	border-radius: 4px;
	width: 100%;
	padding: 0 17px;
	margin: 15px 0;
	max-height: 415px;
	overflow-y: scroll;
	min-width: 470px;
}
.opacitizero {
	opacity: 0;
}

.delete_icon
{
  width: 48px;
  height: 48px;
    cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  border: 1px solid #FEE600;
  border-radius: 8px;
  display: flex;
}
.statistics_number {
	display: flex;
	background: none;
}

.statistics_number__card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 24px;
	/*width: 195px;*/
	height: 95px;
	background: #FFFFFF;
	border-radius: 8px;
	margin-right: 20px;
}

.v-tabs-items {
	background: none;
}

.statistics_number__card__title {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
}

.statistics_number__card__number {
	/*font-family: 'Manrope';*/
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
}

.card_rent_number {
	background: #FFFFFF;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding: 20px 26px;
}

.card_rent_number__active {
	display: flex;
	margin-bottom: 27px;
	justify-content: space-between;
	align-items: center;
}
.card_rent_number__active > .number_active {
	display: flex;
}
.number_active > span {
	font-weight: 500;
	font-size: 13px;
	line-height: 167%;
	margin-right: 7px;
}
.card_rent_number__active > .number {
	font-weight: 700;
	font-size: 19px;
}
.card_rent_number__scripts > span {
	width: 100%;
	text-align: left;
	display: block;
}
.card_rent_number_footer {
	display: flex;
	justify-content: space-between;
	text-align: left;
}
.card_rent_number_footer .cost{
	font-weight: 700;
	font-size: 17px;
	color: #00809C;
}
.card_rent_number_footer .next_buy{
	font-weight: 500;
	font-size: 15px;
	/*color: #55575C;*/
}


.my_btn {
	text-transform: none;
	border: none;
	background: none;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
}

.add_modal {
	border: none;
	width: 100%;
	padding: 12px 20px 12px 22px;
	background: #FEE600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	justify-content: center;
	color: #2B2D33;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-style: normal;
}

.popup_title{
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: #2B2D33;
	margin: 16px 0 19px 0;
}
.popup_number {
	font-size: 17px;
	margin-bottom: 16px;
}
.popup_number > span {
	font-weight: 600;
}

.search {
	position: relative;
	width: 100%;
}

.search_btn {
	right: 10px;
	top: 10px;
}
.abs {
	position: absolute;
	top: 11px;
	right: 13px;
	cursor: pointer;
}

.content_table {
	margin-top: 24px;
}

.blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.add {
	border: none;
	padding: 12px 20px 12px 22px;
	background: #FEE600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	color: #2B2D33;
	cursor: pointer;
	display: flex;
	margin-top: 24px;
	justify-content: center;
}

.add .icon {
	margin-right: 11px;
}

.item_tab {
	text-transform: none !important;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #808185;
}

.my_active_tab {
	color: #2B2D33;
}

.my_toolbar {
	margin-top: 16px;
}

.num_big {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: #404247;
	max-width: 75px;
	position: absolute;
	top: 55px;
	width: 75px;
	left: 51px;
}

.block_item {
	padding: 16px 24px;
	border-radius: 8px;

	width: 265px;
	margin-bottom: 36px;
}

.block_item:nth-child(odd) {
	margin-right: 20px;
}

.small_blocks {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
	/*justify-content: center;*/
}

.block_item .heading {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #404247;
	margin-bottom: 12px;
	text-align: left;
}

.block_item .num {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.my_red .num {
	color: #EE505A;
}

.my_gray {
	background: #f8f8f8;
}

.my_red {
	background: #FDEEEE;
}

.my_green {
	background: #E8F7F4;
}

.block {
	display: flex;
	flex-wrap: wrap;
	padding: 32px 24px;
	justify-content: center;
	max-width: 600px;
	height: min-content;
}

.big_block {
	background: #E8F7F4;
	border-radius: 8px;
	display: flex;
	padding: 24px 16px;
	min-width: 500px;
	max-height: 230px;
}

.right {
	margin-left: 32px;
}

.heading {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	text-align: left;
	line-height: 130%;
	color: #404247;
}

.robot, .client, .silence {
	text-align: left;
	position: relative;
	margin-top: 24px;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	padding-left: 25px;
	line-height: 120%;
	color: #404247;
}

.robot:before {
	display: block;
	content: '';
	width: 18px;
	height: 18px;
	background: #FAA373;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
}

.client:before {
	display: block;
	content: '';
	width: 18px;
	height: 18px;
	background: #57B6ED;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
}

.silence:before {
	display: block;
	content: '';
	width: 18px;
	height: 18px;
	background: #9A99FB;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
}

.content {
	height: 100%;
	display: flex;
	position: relative;
	z-index: 1000;
}

.empty {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 500px;
}

.subtitle {
	max-width: 450px;
	margin-bottom: 32px;
}

.left {
	position: relative;
}

@media (max-width: 1000px) {
	.filters {
		flex-direction: column;
	}
}

@media (max-width: 650px) {
	.top {
		justify-content: center;
	}

	.big_block {
		flex-direction: column;
		max-height: initial;
		min-width: initial;
	}

	.num_big {
		left: calc(50% - 36px);
	}

	.right {
		margin-left: 0;
	}
}

@media (max-width: 620px) {
	.block_item:nth-child(odd) {
		margin-right: 0;
	}

	.small_blocks {
		flex-direction: column;
	}
}

@media (max-width: 400px) {
	h1 {
		margin-top: 15px;
	}
}
</style>
