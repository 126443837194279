<template>
	<div class="filters">
		<div class="filter">
			<div class="filter_item filter_range" v-if="visible.date">
				<div class="wrap_titler">
					<div class="titler">Выберите период</div>
					<div v-if="filters.rangePicker.length" class="clear" @click="filters.rangePicker=[]">
						<Icon name="close"/>
					</div>

				</div>
				
				<v-menu
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<div class="rel" v-bind="attrs" v-on="on">
							<v-text-field
								v-model="dateRangeText"
								readonly
								class="input_custom input_range"
								hide-details
								height="40"
							></v-text-field>
							<Icon class="abs" name="calendar"/>
						</div>
					</template>
					<v-date-picker
						v-model="filters.rangePicker"
						range
						locale="ru"
						no-title
						first-day-of-week="1"
						color="#000"
						event-color="#00A5BC"
					></v-date-picker>
				</v-menu>
			</div>
			<div v-if="filters.rangePicker.length">
    <div class="filter_item">
        <div class="wrap_titler">
            <div class="titler">Выберите диапазон времени</div>
            <div v-if="filters.selectedStartTime || filters.selectedEndTime" class="clear" @click="clearSelectedTimeRange">
                <Icon name="close"/>
            </div>
        </div>
        <div class="time-range-picker">
            <input type="time" v-model="filters.selectedStartTime" class="time-input" @input="handleStartTimeChange" @keydown="disableClear">
            <span> - </span>
            <input type="time" v-model="filters.selectedEndTime" class="time-input" @input="handleEndTimeChange" @keydown="disableClear">
        </div>
    </div>
</div>	
			<div class="filter_item" v-if="visible.calls">
				
				<div class="wrap_titler">
					<div class="titler">Выберите обзвон</div>
					<div v-if="filters.current_call" class="clear" @click="filters.current_call=null">
						<Icon name="close"/>
					</div>
				</div>
				<v-combobox
					class="input_custom"
					v-model="filters.current_call"
					:items="visible.calls"
					hide-selected
					solo
					no-data-text="Обзвонов нет"
					color="#FEE600"
					height="40"
					hide-details
					error-count="0"
					width="450"
				>
				</v-combobox>
			</div>

			<div class="filter_item" v-if="visible.phone">
				<div class="wrap_titler">
					<div class="titler">Поиск по номеру</div>
					<div v-if="filters.search" class="clear" @click="filters.search=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.search"
						type="tel"
						class="input_custom"
						placeholder="Введите номер"
						error-count="0"
						height="40"
						v-maska="maskaFilter"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>

			<div class="filter_item" v-if="visible.text">
				<div class="wrap_titler">
					<div class="titler">Поиск по фразе</div>
					<div v-if="filters.text" class="clear" @click="filters.text=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.text"
						type="text"
						class="input_custom"
						placeholder="Введите фразу"
						error-count="0"
						height="40"
						v-maska="''"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>



			<div class="filter_item" v-if="visible.nickname">
				<div class="wrap_titler">
					<div class="titler">Поиск по псевдониму</div>
					<div v-if="filters.searchNickname" class="clear" @click="filters.searchNickname=null">
						<Icon name="close"/>
					</div>
				</div>
				<div class="search rel">
					<v-text-field
						v-model="filters.searchNickname"
						type="tel"
						class="input_custom"
						placeholder="Введите псевдоним"
						error-count="0"
						height="40"
						v-maska="''"
						:hide-details="true"
					></v-text-field>
					<div class="abs search_btn">
						<Icon name="search"/>
					</div>

				</div>
			</div>

      <div class="filter_item" v-if="visible.visible">
        <div class="wrap_titler">
          <div class="titler">Поиск по менеджеру</div>
          <div v-if="filters.searchManager" class="clear" @click="filters.searchManager=null">
            <Icon name="close"/>
          </div>
        </div>
        <div class="search rel">
          <v-text-field
              v-model="filters.searchManager"
              type="tel"
              class="input_custom"
              placeholder="Введите псевдоним"
              error-count="0"
              height="40"
              v-maska="''"
              :hide-details="true"
          ></v-text-field>
          <div class="abs search_btn">
            <Icon name="search"/>
          </div>

        </div>
      </div>
      
      <div class="filter_item" v-if="visible.target">
				<div class="wrap_titler">
					<div class="titler">Цель</div>
					<div v-if="filters.target" class="clear" @click="filters.target=null">
						<Icon name="close"/>
					</div>
				</div>
				<v-combobox
					class="input_custom"
					v-model="filters.target"
					:items="visible.targets"
					hide-selected
					solo
					no-data-text="Целей нет"
					color="#FEE600"
					height="40"
					hide-details
					error-count="0"
					width="450"
				>
				</v-combobox>
			</div>

      <div class="filter_item" v-if="visible.managerVisible">
        <v-checkbox v-model="filters.visibleManager" label="Не показывать скрытые"></v-checkbox>
      </div>


			<div class="apply" @click="filterUpdate()">
				Применить
			</div>
      <div class="apply" @click="exportExcel()">
        Экспорт
      </div>
		</div>

	</div>
</template>
<script>
import Icon from './SetIcon.vue'

export default {
	components: {
		Icon
	},
	computed: {
		dateRangeText() {
			if (this.filters.rangePicker.length) return this.filters.rangePicker.join(' — ')
			return []
		},
		maskaFilter() {
			return this.visible.maska ? this.visible.maska : '+7 (###) ###-##-##'
		}
	},
	methods: {
		disableClear(event) {
      if (event.keyCode === 8 || event.keyCode === 46) {
        event.preventDefault();
      }
    },
	handleStartTimeChange(){
  if (this.filters.selectedStartTime !== null) {
    const localDate = new Date(`${this.filters.rangePicker[0]}T${this.filters.selectedStartTime}`);
    const utcOffsetInMinutes = localDate.getTimezoneOffset();
    const utcDate = new Date(localDate.getTime() + (utcOffsetInMinutes * 60000));
    const hours = utcDate.getHours();
    const minutes = utcDate.getMinutes();
    const formattedTimeStart = `${utcDate.getFullYear()}-${(utcDate.getMonth() + 1).toString().padStart(2, '0')}-${utcDate.getDate().toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    this.filters.utcStart = formattedTimeStart;
  }
},

		handleEndTimeChange() {
			if (this.filters.selectedEndTime !== null) {
			const localDate1 = new Date(`${this.filters.rangePicker[1]}T${this.filters.selectedEndTime}`);
const utcOffsetInMinutes1 = localDate1.getTimezoneOffset();
const utcDate1 = new Date(localDate1.getTime() + (utcOffsetInMinutes1 * 60000));
const hours1 = utcDate1.getHours();
const minutes1 = utcDate1.getMinutes();
const formattedTimeEnd = `${utcDate1.getFullYear()}-${(utcDate1.getMonth() + 1).toString().padStart(2, '0')}-${utcDate1.getDate().toString().padStart(2, '0')} ${hours1.toString().padStart(2, '0')}:${minutes1.toString().padStart(2, '0')}:59`;
this.filters.utcFinish = formattedTimeEnd
}
		},
		clearSelectedTimeRange() {
		this.filters.utcStart = null;
		this.filters.utcFinish = null;
        this.filters.selectedStartTime = null;
        this.filters.selectedEndTime = null;
    },
    exportExcel(){
    if (window.location.pathname === '/analytics' && this.filters && this.filters.rangePicker && (!this.filters.rangePicker || this.filters.rangePicker.length === 0)) {
        alert('Поле "Выберите период" не может быть пустым')
        return
      }
      this.validateData()
      let filters = {
        select: this.filters.current_call,
        target: this.filters.target,
        date: this.filters.rangePicker,
        search: this.filters.search,
        searchNickname: this.filters.searchNickname,
        searchManager: this.filters.searchManager,
        active_client: this.filters.visibleManager,
		selectedStartTime: this.filters.utcStart || null,
		selectedEndTime: this.filters.utcFinish || null,
        text: this.filters.text
      }
      this.$emit('exportExcel', filters)
    },
		validateData() {
			if (this.filters.rangePicker.length > 0) {
				if (this.filters.rangePicker.length == 1) {
					this.filters.rangePicker[1] = this.filters.rangePicker[0]
				}

				let start = new Date(this.filters.rangePicker[0])
				let end = new Date(this.filters.rangePicker[1])
				if (end.getTime() < start.getTime()) {

					this.filters.rangePicker[1] = this.filters.rangePicker[0]
				}
			}

		},
		doSearch() {
			this.$http.post("/analytics/number", {number: this.filters.search}, data => {
				this.callback(data)
			})
		},

		today() {
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
		filterUpdate() {

      if(window.location.pathname === '/analytics' && this.filters && this.filters.rangePicker && (!this.filters.rangePicker || this.filters.rangePicker.length === 0)){
        alert('Поле "Выберите период" не может быть пустым')
        return 
      }  
			this.validateData()
			this.handleStartTimeChange()
			this.handleEndTimeChange()

			let filters = {
				select: this.filters.current_call,
				target: this.filters.target,
				date: this.filters.rangePicker,
				selectedStartTime: this.filters.utcStart || null,
				selectedEndTime: this.filters.utcFinish || null,
				search: this.filters.search,
				searchNickname: this.filters.searchNickname,
				searchManager: this.filters.searchManager,
				active_client: this.filters.visibleManager,
				text: this.filters.text
			}
			// console.log(filters)
			this.$emit('updateFilter', filters)
		},

	},
	mounted() {
		this.filterUpdate()
	},
	data() {
		let dateRange = []
		if (this.visible.date == "today") {
			dateRange = [this.today(), this.today()]
		}
		const defaultStartTime = '00:00';
		const currentTime = new Date();
		const hours = currentTime.getHours().toString().padStart(2, '0');
		const minutes = currentTime.getMinutes().toString().padStart(2, '0');
		const defaultEndTime = `${hours}:${minutes}`;
		return {
			filters: {
				startPicker: '',
				finishPicker: '',
				selectedStartTime: defaultStartTime,
				selectedEndTime: defaultEndTime,
				rangePicker: dateRange,
				utcStart:null,
				utcFinish:null,
				search: '',
				searchNickname: '',
				searchManager: '',
				current_call: null,
				text: '',
        visibleManager:true
			}
		}
	},
	props: [
		'visible', 'callback'
	]
}
</script>
<style scoped>
.wrap_titler {
	display: flex;
	justify-content: space-between;
}

.clear {
	cursor: pointer;
}

.ranger {
	position: absolute;
}

.rel {
	position: relative;
}

.input_range {
	min-width: 250px;
}

.abs {
	position: absolute;
	top: 13px;
	right: 13px;
	cursor: pointer;
}

.search {
	position: relative;
}

.search_btn {
	right: 10px;
	top: 10px;
}

.apply {
	background: #FFF;
	border: 1px solid #FED500;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #2B2D33;
	padding: 10px 20px;
	margin: 24px 30px 0 30px;
	max-height: 40px;
	cursor: pointer;
}

.btn_sort {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
}

.btn_sort .icon {
	margin-left: 8px;
}

.filter {
	display: flex;
	position: relative;
}

.calls {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #FFF4E8;
	padding: 22px 32px;
	border-radius: 8px 8px 0 0;
}

.titler {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #404247;
    text-align: left;
    margin-bottom: 6px;
    white-space: nowrap;
}

.filter_item .time-range-picker {
	display: flex;
  margin-right: 10px;
}

.filter_item {
	position: relative;
	display: flex;
	margin-right: 8px;
	display: flex;
	flex-direction: column;
}



.time-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #AAABAD;
  border-radius: 6px;
}

@media (max-width: 800px) {
	.filters {
		justify-content: center;
	}

	.filter {
		flex-direction: column;
	}

	.filter_item {
		margin-top: 10px;
		margin-right: 0;
		margin-left: 0;
	}
}



</style>
