<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="add">Создать интеграцию {{type}}</div>
      </template>
      <div @click="close" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
      
        <TelegramForm v-if="type == 'Telegram'" :update="updateData" :pre_data="pre_data"/>
        <TildaForm v-if="type=='Tilda'" :update="updateData" :pre_data="pre_data"/>
        <WebHook v-if="type=='Webhook'" :update="updateData" :pre_data="pre_data"/>
          
      <div class="add btn_save" @click="save">Сохранить</div>
      <div class="cancel cancel_btn" @click="close">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import Icon from '../../SetIcon.vue'
import TelegramForm from './Form/TelegramForm.vue'
import TildaForm from './Form/TildaForm.vue'
import WebHook from './Form/WebHook.vue'
export default {
  props: ['callback_refresh','data','show_dialog','type'],
  components: {
    Icon,
    TelegramForm,
    TildaForm,
    WebHook
  },
  watch: {
    show_dialog: function (val) {
      
      if (val)
      {
        this.id=this.data.id
        this.pre_data=this.data.item   
        this.save_data=this.data.item   
      }
      this.dialog=val
    },
  },
  data(){
    return {
      dialog: false,
      id:false,
      save_data:false,
      pre_data:false,
      tab: 'manual',
    
    }
  },
  methods: {
  close(norender=true)
  {
      this.id=false
      this.pre_data=false
      this.save_data=false
      this.dialog=false
      this.callback_refresh(norender)
  },
  updateData(save)
  {
    this.save_data=save
  },
   save()
   {
     
     if (this.save_data)
     {
       this.$http.post("/integrations/add",
       {
         type:this.type,
         id: this.id,
         save:this.save_data
       },(data)=>{
         if (data)
         {
           this.close(false)
         }
         
       })
     }
     
   }
 }
}
</script>
<style scoped>
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>