<template>
  <div class="render">
	<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
    <div v-else class="content_table">
      <div class="top">
        <h1>Черный список</h1>
      </div>
      <div class="black_control">
          <v-text-field v-model="filter_form.searchString" class="mr-5 search_black"  placeholder="Номер" type="text" ></v-text-field>
          <div @click="makeUserFilters" class="black_control_save">Искать</div>
          <v-text-field v-model="addPhoneValue" class="mr-5  ml-10 search_black"  placeholder="Номер" type="text" ></v-text-field>
          <div @click="addPhone" class="black_control_save">Добавить номер</div>
      </div>
      <Table
        :page_type="'admin_blacklist'"
        :loading="loading"
        :server="true"
        :perPage="perPage"
        :items="action_items"
        :headers="table.headers"
        :pageCount_server="table.pageCount_server"
        :callback_edit="deletePhone"
        :callback_refresh="renderList"
        @delete_black="deletePhone"
        :data="table.data"
      />
  </div>
  </div>
</template>
<script>

import Table from '../../components/Table/DataTable.vue'
export default {
  components: {
    Table
    },
  data(){
    return {
      user_login:"",
      table_filters:{},
      user_filters:{},
      filter_form:{
        searchString:''
      },
      addPhoneValue:'',
      loading:false,
      perPage:10,
      action_items: [
            {title: 'Удалить',action:'delete_black'},
        ],
      table: {
        data: [],
        pageCount_server:100,
        headers: [
          { text: 'Телефон', value: 'phone' },
          // { text: 'Дата добавления', value: 'created_at' },
          { text: 'Действия', value: 'actions', sortable: false }
        ]
      },

    }
  },
  mounted(){
    this.getListCount()
  },
  methods: {
    makeUserFilters(){

        let have_form = false;
        for(let el in this.filter_form){
          if(this.filter_form[el]){
            have_form = true;
          }
        }


        if(have_form){
          this.filter_form.searchString?
          this.user_filters['equal'] = {phone:Number(this.filter_form.searchString)}:false;


          this.renderList(this.table_filters,true)
        }else{
          this.user_filters={}
          this.renderList(this.table_filters)
        }
        this.getListCount()

    },

    renderList(filters, user_filter_mode = false ){

        let compile_filters;

        if(!user_filter_mode){
          this.table_filters = filters
        }

        compile_filters = {
          ...this.table_filters,
          ...this.user_filters
        }

        compile_filters.perPage = this.perPage
        this.$http.post("/admin_balcklist/list", {
          filters: compile_filters,
        }, data => {
          this.table.data = data;
        })


    },

    getListCount(){
        let filters = {
          ...this.filters,
          ...this.user_filters
        }

        this.$http.post("/admin_balcklist/count", {
          filters:filters
        }, data => {
          this.table.pageCount_server = data[0].count;
        })

    },
    addPhone(){
      this.$http.post("/admin_balcklist/add", {
        phone:this.addPhoneValue
      }, () => {
        this.addPhoneValue=''
        this.makeUserFilters()
      })
    },
    deletePhone(item){
      let {phone} = item
      this.$http.post("/admin_balcklist/delete", {
        phone:phone
      }, () => {
        this.makeUserFilters()
      })

    }
  },
}
</script>
<style scoped>

.black_control_save:hover{
    opacity: 1;
      transition-duration: 0.3s;
}
.black_control_save{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #fee600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2b2d33;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 200px;
  opacity: 0.8;
  transition-duration: 0.3s;
}
.search_black{
    max-width: 249px;
}
.black_control{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

p{
  text-align:left;
}
.v_email_updated_massage{
  text-align: left;
  color: green;
}
.v_err_updated_massage{
  text-align: left;
  color: #b01111;
}
.mb26{
  margin-bottom: 26px;
}
.top{
  margin-bottom: 24px;
}
.history_title{
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 8px;
}
.desc{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #55575C;
  text-align: left;
  margin-bottom: 16px;
}
.ok{
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}
.cashup{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  background: #FEE600;
  border-radius: 8px;
  padding: 13px;
  margin-top: 40px;
  cursor: pointer;
}
.history{
  margin: 8px auto 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
  position: relative;
  width: max-content;
}
.history:after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #00809C;
  position: absolute;
  bottom: -4px;
}
.save{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  padding: 9px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 16px;
  line-height: 110%;
  color: #2B2D33;
  max-width: max-content;
  cursor: pointer;
}
.top{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.inform{
  display: flex;
}
.left{
  background: #FFFFFF;
  padding: 24px;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 50%;
  height: max-content;
}
.content{
  display: flex;
}
.right{
  width: 50%;
  padding-left: 32px;
}
h1{
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
}
h2{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 24px;
}
.label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.balance{
  width: 40%;
}
.left .title{
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
}
.left .num{
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}
.alert{
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}
.left_text{
  display: flex;
  align-items: center;
}
.left_text .text{
  margin-left: 18px;
}
.mmm_data{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}
.vback{
  border: 1px solid #c3c4c6;
  padding: 10px;
  border-radius: 10px;
}
.vback:hover{
  cursor:pointer;
}
.ok .text{
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
</style>
