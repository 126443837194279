<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="490" >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="add">
          <Icon name="plus"/>
          Добавить скрипт звонка
        </div>
      </template>

      <div @click="close" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          Введите название сценария
        </div>
        <div>
          <v-form ref="form">
          <p class="modal_subtitle">Назовите сценарий</p>
          <v-text-field v-model="name" placeholder="Тестовый сценарий звонка" :rules="rules"></v-text-field>
          </v-form>
        </div>
        <div v-if="!guid" class="add create_modal" @click="save">Создать</div>
        <v-btn v-if="guid" class="add create_modal" @click="save">Переименовать</v-btn>
        <div class="cancel_modal" @click="close">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  props: ['callback_refresh','show_dialog','id','scenario_name'],
  components: {
    Icon
  },
  watch: {
    show_dialog: function (val) {
      this.dialog=val
      this.name=this.scenario_name
      this.guid=this.id
    }
  },
  methods: {
    close()
    {
      this.name=''
      this.guid=false
      this.dialog=false
      this.callback_refresh(false)
    },
    save()
    {
    if (this.$refs.form.validate())  // как работает validate?
    {
      let save={
        name:this.name,
        created_at:Math.round(new Date().getTime()/1000),
        data:false
      }
      if (this.id)
      {
        save={
          name:this.name
        }
      }
      this.$http.post("/script/save",
      {
        id: this.id,
        save:save
      },(data)=>{
        if (data)
        {
          this.callback_refresh()
          this.close(false)
        }
        
      })
    }
    }
  },
  data(){
    return {
      dialog: false,
      name:'',
      guid:false,
      tab: 'manual',
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 50 || 'Max 50 символов'
      ],
    }
  }
}
</script>
<style scoped>
.cancel_modal{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}
.create_modal{
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
 .add{
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add .icon{
    margin-right: 11px;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }
</style>