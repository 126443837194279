<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" class="replenishment" :class="location">Пополнить</button>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          Пополнение баланса
        </div>
        <div class="input_wrapper">
          <div class="label">Сумма</div>
          <v-text-field placeholder="5000₽" type="number"></v-text-field>
        </div>
        <div class="my_btn add_modal">Пополнить</div>
        <div class="my_btn" @click="dialog = false">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['location'],
  data(){
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.input_wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label{
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  margin-bottom: 6px;
}
.replenishment{
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  padding: 7px 17px;
  align-items: center;
  text-align: center;
  color: #2B2D33;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 35px;
  cursor: pointer;
}
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}

.add_modal{
  border: none;
  width: 100%;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  justify-content: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-style: normal;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>