<template>
  <div class="footer">
    <div class="left">
      <Icon name="checked"/>
      Выбрано: <b>{{getCounter}}</b>
    </div>
    
    <div v-if="this.page == 'calls'" class="sec">
      <a @click="resume()" class="button resume" >Возобновить выбранные</a>
         </div>
         <div class="center">
          <!-- <a class="button double" color="fff">Дублировать выбранное</a> -->
          <a @click="deleteTable()" class="button delete" color="fff">Удалить выбранные</a>
          
        </div>
         <div v-if="this.page == 'calls'" class="third">
          <a @click="stop()" class="button stop" >Остановить выбранные</a>
        </div>
   

    <Error :callback_refresh="clearRefreshFooter" :show_dialog="show_dialog_error" :title="show_dialog_error_message" :btn="{text: show_dialog_error_btn}" />

  


    
  </div>
</template>
<script>
import Error from '../components/Modal/ErrorModal.vue'
import Icon from './SetIcon.vue'
export default {
  data(){
    return {
      counter:0,
      page:2,
      show_dialog_error: false,
      show_dialog_error_message: "",
      show_dialog_error_btn: "",
    }
  },
  components: {
    Icon,
    Error
  },
  mounted(){
    this.page = this.$store.getters['notification/getFooterState'].page_type;
    console.log(this.$store.getters['notification/getFooterState'].page_type,123)
  },
  computed:{
    getCounter: function () {

      return this.$store.getters['notification/getFooterState'].data.length;
    }
  },
  
  methods: {
    async clearRefreshFooter(){
      this.$store.getters['notification/getFooterState'].cb_refresh()
      this.$store.getters['notification/getFooterState'].cb_clear()
      let data = {
        hide:true,
        data:[],
        page_type:"none",
        cb_refresh:false,
        cb_clear:false
      }
      this.$store.commit('notification/setFooterState',data)
    },
    deleteTable(){
      let arr = this.$store.getters['notification/getFooterState'].data;
      let list_guid = arr.map((element)=>element.guid)
      let footerPage = this.$store.getters['notification/getFooterState'].page_type;
      switch (footerPage) {
          case 'base_main':
              this.$http.post("/base/batchdelete",
              {
                data:list_guid
              },()=>{
                  this.clearRefreshFooter()
              })
            break;
          case 'base_phones':
                this.$http.post("/base/batchdeletelead",
                {
                  data:list_guid
                },()=>{
                    this.clearRefreshFooter()
                })
              break;
          case 'base_black':
                this.$http.post("/base/batchdeletelead",
                {
                  data:list_guid
                },()=>{
                    this.clearRefreshFooter()
                })
              break;
          case 'scripts':
                this.$http.post("/script/batchdelete",
                {
                  data:list_guid
                },()=>{
                    this.clearRefreshFooter()
                })
              break;
          case 'calls':
                this.$http.post("/call/batchdelete",
                {
                  data:list_guid
                },()=>{
                    this.clearRefreshFooter()
                })
              break;

        }

    },
    resume(){
      let arr = this.$store.getters['notification/getFooterState'].data;
      // let list_guid = arr.map((element)=>element.guid)
      let footerPage = this.$store.getters['notification/getFooterState'].page_type;
      const filteredListGuid = arr.filter(element => element.status !== 'success').map(element => element.guid);
      switch (footerPage) {
         
          case 'calls':
                this.$http.post("/call/resume",
                {
                  data:filteredListGuid
                },(data)=>{
                  console.log(data,12312313)
                  if (data.errored) {
          this.show_dialog_error = true
          this.show_dialog_error_message = data.error_message
          this.show_dialog_error_btn = "Пополнить"
          if (data.error_message == "Вам необходимо активировать аккаунт") {
            this.show_dialog_error_btn = "Активировать"
          }
        } else {
          this.clearRefreshFooter()
        }
                   })
              break;

        }

    },
    stop(){
     
      let arr = this.$store.getters['notification/getFooterState'].data;
      // let list_guid = arr.map((element)=>element.guid)
      const filteredListGuid = arr.filter(element => element.status !== 'success').map(element => element.guid);
      let footerPage = this.$store.getters['notification/getFooterState'].page_type;
      switch (footerPage) {
         
          case 'calls':
                this.$http.post("/call/stopChosen",
                {
                  data:filteredListGuid
                },()=>{
                    this.clearRefreshFooter()
                })
              break;

        }

    }
  }
}
</script>
<style scoped>
.double{

  color: #55575C;
}
.delete{
  color: #EE505A;
}
.footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.left{
  display: flex;
  align-items: center;
}
.left .icon{
  margin-right: 10px;
  margin-left: 16px;
}
.close{
  cursor: pointer;
  position: relative;
  top: initial;
  right: initial;
}
.button{
  background: none;
  border: none;
  margin: 0 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  cursor: pointer;
}
.button:hover{
  text-decoration: underline;
}
</style>

