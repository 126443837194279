<template>
  <div class="robot_replic">
    <v-toolbar flat>
      <template v-slot:extension>
        <v-tabs  v-model="data_local.type" centered>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab active-class="active_tab" v-for="(item,i) in tabs" :key="i" >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="data_local.type">
      <v-tab-item v-for="(item, j) in tabs" :key="j" >
        <v-card v-if="'Синтез речи' == item" flat>
          <div class="item_tab">
            <h3>Текст реплики робота</h3>
            <v-textarea
              class="my_textarea mb-2"
              name="input-7-1"
              auto-grow
              @input="input"
              @change="input"
              v-model="data_local.text"
              required
              placeholder="Доброго времени суток, вас интересует услуга банкротства?" :rules="[rules.length, rules.aria]" hide-details="auto"
            ></v-textarea>
            <v-select
              height="40"
              :items="items"
              @change="input"
              item-value="id"
              item-text="name"
              item-color="#fee600"
              v-model="data_local.voice"
            ></v-select>
            <div class="record">
              <Player v-if="data_local.text.length>2" :syntese="true" :item="{record:false,text_data:data_local, id: 'fsfetgseg'}"/>
            </div>

          </div>
        </v-card>
        <v-card v-if="'Аудиозапись' == item" flat>
          <div class="item_tab">
            <h3 class="h3_audio_file">Аудио-файл</h3>
            <FileDrop :isload="file_play" :data="data_local" types="WAV,MP3" url="/script/load" formats=".wav, .mp3" :callback_file="load_file" description=""/>

<!--            <div class="record">-->
<!--              <Player v-if="file_play" :item="{record:data_local.download, id: 'download'}"/>-->
<!--            </div>-->

          </div>
        </v-card>
        <v-card v-if="'Запись речи' == item" flat>
          <div class="item_tab audio_rec">
            <h3>Запишите аудио через микрофон</h3>
              <div class="wrapper">
                <div v-if="recordLoad">
                  Обработка
                </div>
                <div v-if="!recordLoad && isRecord" class="record">
                  <Player :item="{record: data_local.record, id: 'record'}" />
                  <div class="clear" @click="clearRecord">Перезаписать</div>
                </div>
                <audio id="start" src="../../../../assets/sounds/start.mp3"></audio>
                <audio id="finish" src="../../../../assets/sounds/finish.mp3"></audio>
                <div class="start_wrapper" v-if="!isRecord && !recordLoad">
                  <div class="start" :class="{'stop':record.state == '0'}" @click="controlRecord">{{record.state}}</div>
                  <div class="status_wrapper">
                    <div v-if="record.state == '0'" class="status">Идет запись</div>
                    <div class="time">{{calcMin(record.time)}}</div>
                  </div>
                </div>
              </div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Player from '../../../AudioPlayer.vue'
import FileDrop from '../../../FileDrop.vue'
export default {
  props: ['data','edit_callback'],
  components: {
    FileDrop,
    Player
  },
  methods:{
    calcMin(sec){
      let seconds = Math.floor(sec % 60)
      let minutes = Math.floor(sec / 60)
      if(minutes.toString().length == 1) minutes = '0' + minutes
      if(seconds.toString().length == 1) seconds = '0' + seconds
      return `${minutes}.${seconds}`
    },
    clearRecord(){
      this.data_local.record = false
      this.isRecord = false
      this.recordLoad = false
		this.record.time = 0
		this.valinEmit()
    },
    controlRecord() {
      let _this = this
      let voice = []
      if (this.record.mediaRecorder) {
        this.record.mediaRecorder.stop()
        document.getElementById('finish').play()
        this.record.state = 'REC'
        this.record.mediaRecorder = false
        this.record.time = 0
        clearInterval(this.interval)
      }
      else {
        this.record.state = '0'
        document.getElementById('start').play()
        setTimeout(() => {
          navigator.mediaDevices.getUserMedia({ audio: true})
          .then(stream => {

            this.record.mediaRecorder = new MediaRecorder(stream)
            voice = []
            this.record.mediaRecorder.start()
            _this.interval = setInterval(() => {
              _this.record.time++
            }, 1000)
            this.record.mediaRecorder.addEventListener("dataavailable",function(event) {

              voice.push(event.data)
              const voiceBlob = new Blob(voice, {
                type: 'audio/ogg; codecs=opus'
              })
              let reader = new FileReader()
              reader.readAsDataURL(voiceBlob)
              reader.onloadend = function() {
                _this.recordLoad=true
                _this.$http.post("/script/load", {
                  file:reader.result
                },(data)=>{
					if(data === 'error'){
						alert('Ошибка сети!')
						_this.recordLoad=false
					} else {
						_this.data_local.record=data
						_this.isRecord=true
						_this.recordLoad=false
						_this.valinEmit()
					}
                })
                // load_file(base64data,_this,id,2)
              }
            })
          })
        }, 300)
      }
    },
    load_file(url)
    {
      if (url)
      {
        this.file_play=true
      }
      else
      {
        this.file_play=false
      }

      this.data_local.download=url
		this.valinEmit()
    },

    input()
    {
		this.valinEmit()
      //if (this.data_local.type==0 && this.data_local.text.length>1)
      if (this.data_local.type==0)
      {
        this.edit_callback(this.data_local);
        return true
      }
      this.edit_callback(false)
    },
	valinEmit(){
			this.$emit('valid', {
				isRecord: this.isRecord,
				file_play: this.file_play,
				aria: this.rules.length(this.data_local.text) === true && this.rules.aria(this.data_local.text) === true
			})
	},
	mountedRecordAndDownload(){
	if (this.data_local.record)
      {
		console.log(this.isRecord)
        this.isRecord=true
      }
      else
      {
        this.isRecord=false
      }
      if (this.data_local.download)
      {
        this.file_play=true;
      }
      else
      {
        this.file_play=false;
      }
	}
  },
  watch: {
    data: function (val) {
      this.data_local=val
      this.mountedRecordAndDownload()
		this.valinEmit()
    }
  },
  mounted()
  {
    this.data_local=this.data
    this.mountedRecordAndDownload()
	this.valinEmit()
  },
  data(){
    return {
      record: {
        src: false,
        time: 0,
        mediaRecorder: false,
        states: ['ready', 'recording', 'play', 'delete'],
        state: 'REC'
      },
      model:0,
      isRecord:false,
      recordLoad:false,
      isPlay:false,
      file_play:false,
      items: [
        {id:"alena",name:"Алена"},
        {id:"filipp",name:"Василий"},
        {id:"jane",name:"Глаша"},
        {id:"omazh",name:"Анжела"},
        {id:"zahar",name:"Михаил"},
        {id:"ermil",name:"Николай"},
      ],
      data_local:{
        type:0,
        record:false,
        voice:"alena",
        text:'',
        download:false,
      },
      tabs: ['Синтез речи', 'Аудиозапись', 'Запись речи'],
      tab_items: [
        { title: 'Текст реплики робота', vs: 'Синтез речи'},
        { title: 'Текст реплики робота', vs: 'Аудиозапись'}
      ],
		rules: {
        length: value => value.trim() !== ''  || `  Поле не может быть пустым`,
        aria: value => (value).match(/[!"№;%:$*(/)_&#@`~='{}]/g) === null || '  Доступные символы в тексте только +.,?'
      },
    }
  }
}
</script>

<style scoped>
.status_wrapper{
  display: flex;
  flex-direction: column;
}
.status{
  font-style: normal;
  margin-bottom: 9px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #E34747;
}
.wrapper{
  padding: 14px;
  border: 1px solid #DBD9D9;
  width: 430px;
  height: 183px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.time{
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  color: #000000;
}
.start_wrapper{
  display: flex;
  align-items: center;
}
.start.stop:before{
  content:'';
  width: 28px;
  height: 28px;
  background: #fff;
  position: absolute;
  top: 28px;
  left: 28px;
}
.record{
  width: 100%;
}
.record .player{
  display: flex;
  align-items: center;
}
.record .player .time{
  width: 150px;
}
.audio_rec{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.h3_audio_file{
	width: 100%;
	text-align: center;
}
.active_tab{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}
.btn_unlock{
  font-style: normal;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  margin-bottom: 19px;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
}
.play{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
}
.isplay
{
pointer-events:none;
opacity: 0.5;
}
.start{
  position: relative;
  cursor: pointer;
  background: linear-gradient(133.64deg, #FD6D6D 6.47%, #DE4040 95.37%);
  line-height: 70px;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #FFF;
  padding: 6px 12px;
  border-radius: 50%;
  width: 83px;
  height: 83px;
  margin-right: 100px;
}
.clear{
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #E34747;
  padding: 6px 12px;
  margin-top: 35px;
}
.btn_play{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0 0 0!important;
  cursor: pointer;
}
.btn_play .icon{
  margin-right: 6px;
}
.my_input{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  position: relative;
}
.my_input .icon{
  position: absolute;
  right: 7px;
  top: 17px;
}
.subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.modal_header{
  display: flex;
  align-self: flex-end;
}
.item_tab{
  margin: 32px auto 0 auto;
  max-width: 600px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.modal_title{
margin-top: 32px;
margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
@media (max-width: 600px){
  .wrapper{
    width: 100%;
  }
}
</style>
