<template>
  <div class="flex">
    <input class="login_input" :class="{ danger: isDanger }"  v-model="phone" placeholder="+7 ___ ___-__-__" type="tel" v-maska="'+7 ### ###-##-##'" @change="cb"/>
    <span v-if="isDanger">{{dangerMessage}}</span>
  </div>
 
</template>
<script>
import { ref } from 'vue'

export default {
  props: ['phoner','callback','isPhone',ref,'phoneLand'],
  data(){
    return {
      isDanger:false,
      dangerMessage:"",
      phone: ''
    }
  },
  mounted(){

   
    setTimeout(() =>{
      const inputElement = document.querySelector('.login_input');
    console.log(this.$props)
    if (inputElement) {
    
    inputElement.value = this.$props.phoneLand
    }
    },300)
   
  
    
  },
  methods: {
    cb(){
      this.isDanger=false 
      if (this.phone.length<16)
      {
      this.isDanger=true 
      this.dangerMessage="Номер телефона не корректный" 
      }
      this.callback(this.phone)
      // this.phoner = this.phone
    }
  }
}
</script>
<style scoped>
  .login_input{
    height: 48px;
    background: #FFF;
    border: 1px solid #AAABAD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 12px;
  }
  .danger
  {
    border:1px solid red;
  }
  .flex{ 
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
  }
  span{ 
    font-size: 13px;
    color: #EE505A;
    margin: 4px 0px;
    text-align: left;
  }
</style>