<template>
  <div class="scale">
    <div class="btn_scale minus" @click="current_scale-=10">
      <Icon name="minus" />
    </div>
    <div class="num">
      {{current_scale}}%
    </div>
    <div class="btn_scale plus" @click="current_scale+=10">
      <Icon name="plus" />
    </div>
  </div>
</template>
<script>
import Icon from './SetIcon.vue'
export default {
  components: {
    Icon
  },
  data(){
    return {
      current_scale: 100
    }
  },
  watch: {
    current_scale: function () {
      if (this.current_scale<60)
      {
        this.current_scale=60
      }
      if (this.current_scale>120)
      {
        this.current_scale=120
      }
      window.scriptScale(this.current_scale)
    }
  }
}
</script>
<style scoped>
.scale{
  user-select: none;
  display: flex;
  align-items: center;
}
.btn_scale{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  cursor: pointer;
}
.num{
  margin-left: 8px;
  margin-right: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  min-width: 40px;
}

</style>