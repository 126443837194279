<template>
<v-form ref="form">
  <div class="modal_title">
    Новая интеграция WebHook
  </div> 
  <div>
    <p class="modal_subtitle">Введите название</p>
    <v-text-field  @input="input" v-model="data.name" placeholder="Отдел продаж" :rules="rules" solo dense></v-text-field>
  </div>
  <div>
    <p class="modal_subtitle">Выберите базу</p>
    <v-select v-model="data.base" 
    :items="bases"
     @change="input"
    item-value="id"
    item-text="name"
    ></v-select>
  </div>
  <div>
    <p class="modal_subtitle">Выберите метод</p>
    <v-select v-model="data.method" 
    :items="methods"
     @change="input"
    ></v-select>
  </div>
  <div>
    <p class="modal_subtitle">Параметры</p>
    <v-textarea
         name="input-8-1"
         auto-grow
         no-resize
         value="phones - массив телефонов"
    ></v-textarea>
  </div>
</v-form>
</template>
<script>
export default {
  props: ['update','pre_data'],
  data(){
    return {
      errors:0,
      methods:["GET","POST"],
      bases:[],
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 20 || 'Max 20 символов'
      ],
      data:{
        name:"",
        base:"",
        method:"GET",
      }
    }
  },
  watch:{
    pre_data:function()
    {
      this.render()
    }
  },
  mounted()
  {
    this.listBases()
  },
  methods: {
  render()
  {
    if (this.pre_data)
    {
      
      this.data=JSON.parse(JSON.stringify(this.pre_data))
    }
    else
    {
      
      this.data={
        name:"",
        base:"",
        method:"GET",
      }
    }
  },
  listBases()
  {
      let _this=this
      this.bases.length=0
      this.$http.post("/base/shortlist",
      {
        
      },(data)=>{
      data.forEach((item)=>{
        _this.bases.push({id:item.guid,name:item.name})
      })
      })  
      this.render()
  },
  input()
  {
    if (this.$refs.form.validate())
    {
      this.update(this.data)
    }
    else
    {
      this.update(false)
    }
  }
  }
  
}
</script>
<style scoped>
.list{
  list-style: none;
  text-align: left;
  margin-bottom: 24px;
}
.list li{
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #55575C;
  margin-bottom: 12px;
  margin-left: 16px;
}
.list li:before{
  content: '';
  background: #55575C;
  display: block;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  left: -7px;
  top: 7px;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}

.textarea{
  padding: 16px 0;
}

.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>