<template>
  <div class="render">
    <v-breadcrumbs class="bc" v-if="!if_main" :items="breadcrumbs" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          @click="returnMain"
          :disabled="item.disabled"
        > 
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="top">
      <h1>{{current.title}}</h1>
      <CreateBase v-if="if_main" :lead_type="tablist" :callback_refresh="renderBases" :groups ='this.groups' type="addGroup" class="custom-button"/>
      <CreateBase v-if="if_main" type="create" :groups ='this.groups' :callback_refresh="renderBases"/>
      <CreateBase v-if="!if_main" :lead_type="tablist" :guid="this.id" :callback_refresh="renderBases" type="add"/>
    </div>
    <div class="content_table">
      <Table v-if="if_main"
        :page_type="'base_main'"
        :callback_delete="deleteBase"
        :items="action_items"
        :loading="loading"
        :perPage="perPage"
        :server="false"
        :callback_refresh="renderBases"

        @delete="deleteBase"
        @edit="editBaseEmit"
        @view="viewBase"


        :callback_edit="editBase"
        :callback_view="viewBase"

        :headers="cases.main.headers"
        :data="cases.main.data"
      />
      <div v-else>
        <v-tabs
          class="tabs_table_inner"
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab class="item_tab" active-class="active_tab" v-for="item in tabs" @click="tablist=item.id" :key="item.id">{{ item.name }}</v-tab>
        </v-tabs>
      </div>

      <v-tabs-items class="actions" v-model="tab" v-if="!if_main">
        <v-tab-item v-for="item in tabs" :key="item.id">
          <Table
            :page_type="item.page_type"
            :loading="loading"
            :server="true"
            :perPage="perPage"
            :items="action_items_server"
            :headers="cases[item.id].headers"
            :pageCount_server="cases[item.id].pageCount_server"

            @deletelead="deleteLead"

            :callback_edit="editBase"
            :callback_view="viewBase"
            :callback_refresh="renderBases"
            :data="cases[item.id].data"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <EditBase :edit_base="edit_base" :show_dialog="edit_popup" :edit_callback="editBase" :groups="groups"/>

  </div>
</template>
<script>

import Table from '../components/Table/DataTable.vue'
import EditBase from '../components/Modal/Bases/EditBase.vue'
import CreateBase from '../components/Modal/Bases/CreateBase.vue'
export default {
  components: {
    Table, CreateBase,EditBase
  },
  data(){
    return {
      groups: [],
      if_main: true,
      filters:{},
      loading:true,
      perPage:10,
      tablist:"all",
      edit_popup:false,
      edit_base:{
        id:0,
        item:{}
      },
      breadcrumbs: [
        {
          text: 'Базы номеров',
          disabled: false,
          click:'index'
        },
        {
          text: 'Link 2',
          disabled: true,
          click: false,
        }
      ],
      current: {
        title: ''
      },
      default: {
        title: 'Базы номеров'
      },
      detail: {
        title: 'Какая то база номеров A1'
      },
      tabs: [ {id:'all',name:'Все номера',page_type:'base_phones'}, {id:'black',name:'Чёрный список',page_type:'base_black'}],
      tab: null,
      action_items: [
          {title: 'Редактировать',action:'edit'},
          {title: 'Посмотреть номера',action:'view'},
          {title: 'Удалить',action:'delete'},
        ],
      action_items_server: [
          {title: 'Удалить',action:'deletelead'},
        ],
      cases: {
        main: {
          data: [],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'id',  value: 'guid' },
            { text: 'Название', value: 'name' },
            { text: 'Название группы', value: 'groupName' },
            { text: 'Количество номеров', value: 'count' },
            { text: 'Чёрный список',value: 'blacklist_count'},
            { text: 'Уникальность', value: 'unique' },
            
            { text: 'Дата создания', value: 'created_at' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        },
        'all': {
          data: [],
          pageCount_server:0,
          headers: [
            { text: '№', align: 'start', value: 'id', sortable: false },
            { text: 'Номер', value: 'phone' },
            { text: 'Дата создания', value: 'created_at' },
            { text: 'Оператор', value: 'operator' },
            { text: 'Регион', value: 'region' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        },
        'black': {
          data: [],
          pageCount_server:0,
          headers: [
            { text: '№', align: 'start', value: 'id', sortable: false },
            { text: 'Номер', value: 'phone' },
            { text: 'Оператор', value: 'operator' },
            { text: 'Регион', value: 'region' },
            { text: 'Дата создания', value: 'created_at' },
            { text: 'Будет удален', value: 'dead_at' },
            { text: 'Действия', value: 'actions', sortable: false }
          ]
        }
      }

    }
  },
  beforeMount(){
    this.current = this.default
    this.renderBases()
  },
   methods: {
    deleteLead(item){
      this.$http.post("/base/deletelead", { id: item.guid }, data => {
        this.renderBases()
        return data
      })
    },
    deleteBase(item){
      this.$http.post("/base/delete", { id: item.guid }, data => {
        this.renderBases()
        return data
      })
    },

    returnMain(){
      this.if_main = true
      this.reset()
      this.renderBases()
    },

    editBaseEmit(item){
      this.editBase(true,{id:item.guid,item:item})
    },
    editBase(visible,base,edit){

      if (base){
        this.edit_base=base
      }
      if (edit){
        this.renderBases()
      }
      this.edit_popup = visible
    },

    renderBases(filters = false){
      this.loading = true
      if (this.if_main){
        this.$http.post("/base/list", {}, data => {
         
  this.loading = false;
  const groupNames = data.map(item => item.groupName);
  const uniqueGroupnames = [...new Set(groupNames)];
  const clearGroups = uniqueGroupnames.filter(group => group !== undefined);
  this.groups = clearGroups
  this.cases.main.data = data;
});
      }
      else
      {

        if (!filters){
          filters = this.filters
        }
        filters.blacklist = 0
        if (this.tablist == "black"){
          filters.blacklist = 1
        }

        filters.perPage = this.perPage
        this.filters = filters
        this.$http.post("/base/leads", {
          
          filters: filters,
          id: this.id
        }, data => {
          this.loading = false
          this.cases[this.tablist].data = data.leads
          data.leads.forEach(lead => {
              if (lead.dead_at) {
                  let year = parseInt(lead.dead_at.split('-')[0], 10);
                  if (year >= 2029) {
                      lead.dead_at = "-";
                  }
              }
          });
          if (data.count) {
            this.cases[this.tablist].pageCount_server = data.count
          }
        })
      }
    },
    viewBase(item){
      let guid = item.guid
      let name = item.name

      this.id = guid
      this.current.title = name
      this.breadcrumbs[1].text = name
      this.renderBases({tab:"all"})
      this.if_main = false
    },
    reset(){
      this.current.title = "Базы номеров"
    }
  },
}
</script>
<style scoped>
.tabs_table_inner .v-slide-group__content{
  margin: 0 16px;
}
.item_tab{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
}
.active_tab{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}
  .v-list div
  {
  cursor:pointer;
  }
  .content_table{
    margin-top: 24px;
    border-radius: 8px;
  }
  .custom-button {
    position: absolute;
     right: 300px
  }
  .content{
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1000;
  }
  @media (max-width: 650px){
    .top{
      flex-direction: column;
    }
    h1{
      margin-bottom: 20px;
    }

  }
  @media (max-width: 400px){
    h1{
      margin-top: 15px;
    }
  }
</style>
