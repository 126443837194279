<template>
	<div class="check_policy">
		<v-checkbox @change="checkToggle" v-model="check_policy"></v-checkbox>
		<div class="check_policy_text">
			Я полностью соглашаюсь с условиями <a target="_blank" href="https://crmcalls.ru/oferta">Лицензии</a> и даю
			согласие на обработку своих персональных данных в соответствии с <a target="_blank"
				href="https://crmcalls.ru/politika">Политикой</a>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			check_policy: this.$store.getters['user/getPolicy']
		}
	},
	methods: {
		checkToggle() {
			let status = this.$store.getters['user/getPolicy']
			this.$store.commit('user/setPolicy', !status)
		}
	}
}
</script>
<style scoped>
.check_policy {
    display: flex;
}

.check_policy_text {
    text-align: left;
}
</style>
