<template>
  <div>
    <div class="table">
      <div v-if="!server">
        <v-checkbox v-if="page_type === 'base_main' || page_type === 'calls'"  class="new" v-model="selectAll" @change="selectAllItems" label="Выбрать все" color="#FEE600"></v-checkbox>
        <v-data-table
          v-model="selected"
          @input="enterSelect()"
          locale="ru-RU"
          checkbox-color="#FEE600"
          :loading="loading"
          :page.sync="page"
          :custom-sort="customSort"
          :headers="headers"
          :show-select="!hideselect"
          :items="data"
          :itemsPerPage="perPage"
          @page-count="pageCount = $event"
          hide-default-footer
          no-data-text="Нет данных"
          item-key="id"
          class="elevation-1"
          loading-text="Загружаем данные таблицы"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            <p>{{ getDateFormat(item.created_at) }}</p>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="status_wait" v-if="item.status == 'wait'">
              в процессе
            </div>
            <div class="status_end" v-if="item.status == '1'">завершен</div>
            <div class="status_stop" v-if="item.status == 'stop'">
              остановлен
            </div>
            <div class="status_uncall" v-if="item.status == '-1'">недозвон</div>
            <div class="status_success" v-if="item.status == 'success'">
              Успешно
            </div>
            <div class="status_wait" v-if="item.status == 'waiting'">
              Ждет проверки
            </div>
            <div class="status_stop" v-if="item.status == 'cancelled'">
              Отказано
            </div>
          </template>

          <template v-slot:[`item.user_status`]="{ item }">
            <div class="status_wait" v-if="item.user_status == 'waiting'">
              Ждет проверки
            </div>
            <div class="status_success" v-if="item.user_status == 'active'">
              Активен
            </div>
            <div class="status_stop" v-if="item.user_status == 'unactive'">
              Не активен
            </div>
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div v-if="item.operator == 'МТС'">
              qqq
              <Icon name="op_mts" />
            </div>
          </template>

          <template v-slot:[`item.status_pay`]="{ item }">
            <div class="status_wait" v-if="item.status_pay == 'waiting'">
              Ждет проверки
            </div>
            <div class="status_success" v-if="item.status_pay == 'success'">
              Успешно
            </div>
            <div class="status_stop" v-if="item.status_pay == 'cancelled'">
              Отказано
            </div>
          </template>

          <template v-slot:[`item.Pcosent_Convesion_Target`]="{ item }">
            <div>{{ item.Pcosent_Convesion_Target }} %</div>
          </template>
          <template v-slot:[`item.Pcosent_Convesion_Target1`]="{ item }">
            <div>{{ item.Pcosent_Convesion_Target1 }} %</div>
          </template>
          <template v-slot:[`header.Convesion_Target`]="{ header }">
            <v-tooltip top max-width="300" color="#2B2D33">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div class="forIc">
                    <Icon class="ic" name="question" />
                  </div>
                </span>
              </template>
              Успешным считается звонок, достигший любой цели в ходе сценария
            </v-tooltip>
            {{ header.text }}
          </template>

          <template v-slot:[`header.Pcosent_Convesion_Target`]="{ header }">
            <v-tooltip top max-width="300" color="#2B2D33">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div class="forIc">
                    <Icon class="ic" name="question" />
                  </div>
                </span>
              </template>
              Успешным считается звонок, достигший любой цели в ходе сценария
            </v-tooltip>
            {{ header.text }}
          </template>

          <template v-slot:[`item.target_limit`]="{ item }">
            <div class="target_container" v-if="item.target_on">
              <div class="target_des">{{ item.target }}/{{ item.limit }}</div>
              <div
                @click="$emit('clearTarget', item)"
                v-if="item.target_rate > 0"
                class="target"
              >
                <Icon name="delete" />
              </div>
            </div>
            <div v-else>---</div>
          </template>

          <template v-slot:[`item.target_price`]="{ item }">
            <div
              class="target_container"
              v-if="item.target_on && item.target > 0"
            >
              <div class="target_des">
                {{ (item.target_rate / item.target).toFixed(2) }} р.
              </div>
            </div>
            <div v-else>---</div>
          </template>

          <template v-slot:[`item.nums`]="{ item }">
            <div>{{ item.contacts }}/{{ item.call }}</div>
          </template>

          <template v-slot:[`item.url`]="{ item }">
            <div>
              https://gate.crmcalls.ru/api/web/u{{
                $store.getters["user/getName"].replace(/[^0-9]/g, "")
              }}/{{ item.guid }}
            </div>
          </template>

          <template v-slot:[`item.unique`]="{ item }">
            <div v-if="item.unique == 1">Да</div>
            <div v-else>Нет</div>
          </template>

          <template v-slot:[`item.date`]="{ item }">
            <div class="table_text">{{ getDateFormat(item.date) }}</div>
          </template>
          <template v-slot:[`item.sum`]="{ item }">
            <div class="table_text" :class="numClass(item.sum)">
              {{ item.sum }}
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div class="table_text">{{ item.phone }}</div>
          </template>

          <template v-slot:[`item.callname`]="{ item }">
            <div>
              <div class="auto" v-if="item.currentType == 'auto'">авто</div>
              {{ item.name }}
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu v-if="items.length > 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="props" v-bind="attrs" v-on="on">
                  <Icon name="props" />
                </div>
              </template>

              <v-list>
                <div
                  v-for="(menu_item, index) in items"
                  :key="index"
                  @click="$emit(menu_item.action, item)"
                >
                  <v-list-item
                    v-if="item.status == 'stop' || menu_item.action != 'play'"
                  >
                    <v-list-item-title :class="itemClass(menu_item.title)">
                      {{ itemText(menu_item, item) }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
            <div
              v-else
              :class="itemClass(items[0].title)"
              @click="$emit(items[0].action, item)"
            >
              {{ items[0].title }}
            </div>
          </template>
        </v-data-table>
        <v-pagination
          color="#E9EAEA"
          class="my-4"
          :total-visible="7"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
      <div v-if="server">
        <v-data-table
          v-model="selected"
          @input="enterSelect()"
          :loading="loading"
          :server-items-length="Number(pageCount_server)"
          :page.sync="server_page"
          :options.sync="options"
          checkbox-color="#FEE600"
          :show-select="!hideselect"
          locale="ru-RU"
          no-data-text="Нет данных"
          :headers="headers"
          :itemsPerPage="perPage"
          :items="data"
          hide-default-footer
          item-key="id"
          class="elevation-1"
          loading-text="Загружаем данные таблицы"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu v-if="items.length > 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="props" v-bind="attrs" v-on="on">
                  <Icon name="props" />
                </div>
              </template>

              <v-list>
                <div
                  v-for="(menu_item, index) in items"
                  :key="index"
                  @click="$emit(menu_item.action, item)"
                >
                  <v-list-item
                    v-if="item.status == 'stop' || menu_item.action != 'play'"
                  >
                    <v-list-item-title :class="itemClass(menu_item.title)">
                      {{ itemText(menu_item, item) }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
            <div
              v-else
              :class="itemClass(items[0].title)"
              @click="$emit(items[0].action, item)"
            >
              {{ items[0].title }}
            </div>
          </template>
          <template v-slot:[`item.n`]="{ item }">
            <v-tooltip top @click="copy(item.id)">
              <template v-slot:activator="{ on, attrs }">
                <div class="table_text" v-bind="attrs" v-on="on">
                  {{ item.n }}
                </div>
              </template>
              <span>{{ item.id }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <p>{{ getDateFormat(item.created_at) }}</p>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="status_wait" v-if="item.status == 'wait'">
              в процессе
            </div>
            <div class="status_end" v-if="item.status == '1'">завершен</div>
            <div class="status_stop" v-if="item.status == 'stop'">
              остановлен
            </div>
            <div class="status_uncall" v-if="item.status == '2'">недозвон</div>
            <div class="status_success" v-if="item.status == 'success'">
              Успешно
            </div>
            <div class="status_stop" v-if="item.status == '4'">
              Не доставлено
            </div>
            <div class="status_success" v-if="item.status == '10'">
              Доставлено
            </div>
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div class="operator_ico" v-if="item.operator == 'МТС'">
              <Icon name="op_mts" />
              МТС
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Билайн'">
              <Icon name="op_beeline" />
              Билайн
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Мегафон'">
              <Icon name="op_megafon" />
              Мегафон
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Tele2'">
              <Icon name="op_tele" />
              Tele2
            </div>
            <div
              class="operator_ico"
              v-else-if="item.operator == 'Тинькофф Мобайл'"
            >
              <Icon name="op_tinkoff" />
              Тинькофф Мобайл
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Yota'">
              <Icon name="op_yota" />
              Yota
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Ростелеком'">
              <Icon name="op_rst" />
              Ростелеком
            </div>
            <div class="operator_ico" v-else>
              <Icon name="op_other" />
              {{ item.operator ? item.operator : "Не определено" }}
            </div>
          </template>
          <template v-slot:[`item.record`]="{ item }">
            <div v-if="item.status != '-1'" class="record">
              <Player :item="item" />
            </div>
            <div v-else class="record status_uncall">Нет записи</div>
          </template>
          <template v-slot:[`item.text`]="{ item }">
            <div
              class="table_text"
              :class="{ status_uncall: textFormat(item.text) == 'Нет данных' }"
            >
              {{ textFormat(item.text) }}
            </div>
          </template>
          <template v-slot:[`item.phoneB`]="{ item }">
            <div class="table_text">
              {{
                "+" +
                item.phoneB.substr(0, 1) +
                " (" +
                item.phoneB.substr(1, 3) +
                ") " +
                item.phoneB.substr(4, 3) +
                "-" +
                item.phoneB.substr(7, 2) +
                "-" +
                item.phoneB.substr(9, 2)
              }}
            </div>
          </template>
          <template v-slot:[`item.phoneA`]="{ item }">
            <div class="table_text">
              {{
                "+" +
                item.phoneA.substr(0, 1) +
                " (" +
                item.phoneA.substr(1, 3) +
                ") " +
                item.phoneA.substr(4, 3) +
                "-" +
                item.phoneA.substr(7, 2) +
                "-" +
                item.phoneA.substr(9, 2)
              }}
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div class="table_text">
              {{
                "+" +
                item.phone.substr(0, 1) +
                " (" +
                item.phone.substr(1, 3) +
                ") " +
                item.phone.substr(4, 3) +
                "-" +
                item.phone.substr(7, 2) +
                "-" +
                item.phone.substr(9, 2)
              }}
            </div>
          </template>
        </v-data-table>
        <v-pagination
          color="#E9EAEA"
          class="my-4"
          :total-visible="7"
          v-model="server_page"
          :length="Math.ceil(pageCount_server / perPage)"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import ItemProps from '../Table/ItemProps.vue'
// import Checkbox from '../Table/CheckboxItem.vue'
import moment from "moment-timezone";
import Icon from "../SetIcon.vue";
import Player from "../AudioPlayer.vue";
export default {
  props: [
    "headers",
    "items",
    "hideselect",
    "data",
    "perPage",
    "callback_edit",
    "callback_stop",
    "callback_refresh",
    "callback_view",
    "callback_play",
    "callback_delete",
    "server",
    "pageCount_server",
    "loading",
    "callback_draw",
    "page_type",
  ],
  components: {
    // ItemProps,
    // Checkbox,
    Icon,
    Player,
  },
  data() {
    return {
      selectAll:'',
      page: 1,
      edit_popup: false,
      options: {},
      server_page: 1,
      pageCount: 2,
      selected: [],
      type: [
        {
          name: "Стандартный",
          value: "standart",
          tooltip:
            "Остановится после обзвона всех номера в выбранной базе номеров",
        },
        {
          name: "Автоматический",
          value: "auto",
          tooltip: "Звонок срабатывает при активации выбранного триггера",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.serverSort();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    let data = {
      hide: true,
      data: [],
      page_type: "none",
      cb_refresh: false,
      cb_clear: false,
    };
    this.$store.commit("notification/setFooterState", data);
  },
  
  methods: {
    selectAllItems() {
      if (this.selectAll) {
        this.selected = this.data.slice();
      } else {
        this.selected = [];
      }
    },
    getDateFormat(date) {
      var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formatDateList = [
        "DD.MM.YYYY HH:mm:ss",
        "YYYY-MM-DD HH:mm:ss",
        "DD.MM.YYYY",
      ]; // Список возможных форматов дат
      let formatDate; // Переменная для хранения результата получения даты
      let outFormat =
        date && String(date).length === 10
          ? "DD.MM.YYYY"
          : "DD.MM.YYYY HH:mm:ss";
      formatDateList.forEach((format) => {
        if (moment.utc(date, format).isValid() && !formatDate)
          formatDate = moment.utc(date, format);
      });
      if (!formatDate) formatDate = moment(date);

      date = formatDate.tz(tz).format(outFormat);
      return date;
    },
    clearSelected() {
      this.selected = [];
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        const noDataValue = "нет данных";

        if (a[index] === noDataValue && b[index] !== noDataValue) {
          return 1;
        } else if (a[index] !== noDataValue && b[index] === noDataValue) {
          return -1;
        } else if (a[index] === noDataValue && b[index] === noDataValue) {
          return 0;
        }

        if (index == "target_price") {
          let priceA = 0;
          let priceB = 0;
          if (a["target_on"] && a.target > 0)
            priceA = (a.target_rate / a.target).toFixed(2);
          if (b["target_on"] && b.target > 0)
            priceB = (b.target_rate / b.target).toFixed(2);

          if (isDesc == "false") {
            return Number(priceA) < Number(priceB) ? -1 : 1;
          } else {
            return Number(priceB) < Number(priceA) ? -1 : 1;
          }
        }
        if (index == "nums") {
          if (isDesc == "false") {
            return Number(a["contacts"]) < Number(b["contacts"]) ? -1 : 1;
          } else {
            return Number(b["contacts"]) < Number(a["contacts"]) ? -1 : 1;
          }
        }

        if (index == "target_limit") {
          if (!a["target_on"]) a["limit"] = 0;
          if (!b["target_on"]) b["limit"] = 0;
          if (isDesc == "false") {
            return Number(a["limit"]) < Number(b["limit"]) ? -1 : 1;
          } else {
            return Number(b["limit"]) < Number(a["limit"]) ? -1 : 1;
          }
        }
        if (index == "callname") {
          if (isDesc == "false") {
            return a["name"] < b["name"] ? -1 : 1;
          } else {
            return b["name"] < a["name"] ? -1 : 1;
          }
        }

        if (isDesc == "false") {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    textFormat(text) {
      if (!text || text == "NONE" || text == "undefined") return "Нет данных";
      return text;
    },
    async copy(text) {
      await navigator.clipboard.writeText(text);
    },
    calcMin(sec) {
      let seconds = Math.floor(sec % 60);
      let minutes = Math.floor(sec / 60);
      if (minutes.toString().length == 1) minutes = "0" + minutes;
      if (seconds.toString().length == 1) seconds = "0" + seconds;
      return `${minutes}:${seconds}`;
    },
    itemText(menu_item, item) {
      if (menu_item.action != "stopdelete") {
        return menu_item.title;
      } else {
        if (item.status == "wait") {
          return "Остановить";
        } else {
          return "Удалить";
        }
      }
    },
    itemClass(title) {
      if (
        title == "Остановить" ||
        title == "Удалить" ||
        title == "Удалить/Остановить"
      )
        return "delete_stop";
    },
    numClass(num) {
      if (num[0] == "+") return "plus";
      else return "minus";
    },

    enterSelect() {
      if (this.selected.length > 0) {
        
        let data = {
          hide: false,
          data: this.selected,
          page_type: this.page_type,
          cb_refresh: this.callback_refresh,
          cb_clear: this.clearSelected,
        };

        this.$store.commit("notification/setFooterState", data);
      } else {
        let data = {
          hide: true,
          data: [],
          page_type: "none",
          cb_refresh: false,
          cb_clear: false,
        };
        console.log(data);
        this.$store.commit("notification/setFooterState", data);
      }
    },
    serverSort() {
      const { sortBy, sortDesc, page } = this.options;
      this.callback_refresh({
        page: page,
        sortBy: sortBy,
        sortDesc: sortDesc,
      });
    },
    editItem(guid, action, item) {
      if (action == "draw") {
        this.callback_draw(guid, item);
      }
      if (action == "analytics") {
        this.$router.push("/analytics");
      }
      if (action == "play") {
        this.callback_play(guid);
      }
      if (action == "edit") {
        this.callback_edit(true, { id: guid, item: item });
      }
      if (action == "view") {
        this.callback_view(guid, item.name);
      }
      if (action == "deletelead") {
        this.$http.post("/base/deletelead", { id: guid }, (data) => {
          this.callback_refresh();
          return data;
        });
      }
      if (action == "stopdelete") {
        action = "delete";
        if (item.status == "wait") {
          action = "stop";
        }
      }
      if (action == "stop") {
        this.callback_stop(guid);
      }
      if (action == "delete") {
        this.callback_delete(guid);
      }
      if (action == "user_view") {
        this.callback_view(item.phone);
      }
      if (action == "pays_success") {
        this.callback_edit(item.phone, item.id_number, "success");
      }
      if (action == "pays_canselled") {
        this.callback_edit(item.phone, item.id_number, "cancelled");
      }
      if (action == "delete_black") {
        this.callback_edit(item.phone);
      }
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
  },
  beforeMount() {
    // this.data.forEach(element => {
    //   if(element) console.log(4, element)
    // });
  },
};
</script>
<style>
.forIc {
  display: inline-block;
  margin-bottom: 2px;
}

.ic {
  position: relative;
  top: 3px;
  display: inline-block;
}

.record {
  width: 100%;
  text-align: center;
}

.table_text {
  max-width: 300px;
  width: 100%;
}

.operator_ico {
  display: flex;
  align-items: center;
}

.operator_ico > .icon {
  margin-right: 5px;
}

.hidden {
  display: none;
}

.table_text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #2b2d33;
}

.num {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
}

.plus {
  color: #008e7d;
}

.delete_stop {
  color: #ee505a;
}

.delete_stop:hover {
  cursor: pointer;
}

.minus {
  color: #c74952;
}

.time {
  margin-right: 8px;
}

.input_wrap {
  width: 132px;
  height: 32px;
}

.props:hover {
  background: #e6f4fc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.hover_actions:hover {
  cursor: pointer;
}

.props {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.auto {
  display: inline-block;
  background: #e1e0fe;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 6px;
}

.status_wait {
  background: #90ee90;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_stop,
.status_uncall {
  text-align: center;
  background: #fee0e0;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_end,
.status_success {
  background: #cff1e9;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.delete {
  font-size: 15px;
  color: #ee505a;
  cursor: pointer;
}

.v-list div {
  cursor: pointer;
}

.table {
  background: #fff;
  border-radius: 8px;
  padding: 20px 16px 32px 16px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.v-application .elevation-1 {
  box-shadow: none !important;
}

.target_container {
  display: flex;
  align-items: flex-start;
  justify-content: left;
}

.target_des {
  display: inline-block;
}

.target {
  width: 15px;
  cursor: pointer;
  margin-left: 5px;
}
.new input[type="checkbox"].v-input--selection-control {
  border-color: #FEE600; /* Цвет рамки чекбокса */
}

.new input[type="checkbox"].v-input--selection-control:checked:not(:disabled) ~ .v-input--selection-placeholder::before {
  border-color: #FEE600; 
  background-color: #FEE600; 
}

.new{
  margin-left: 16px;
}
</style>
