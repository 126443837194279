<template>
  <div class="render">
	<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
    <div v-else class="content_table">
      <div class="top">
        <h1>Платежи</h1>
      </div>
      <Table
        :page_type="'users_payments'"
        :items="action_items"
        :loading="loading"
        :perPage="perPage"
        :server="false"
        :hideselect="true"
        :headers="table.headers"
        :data="table.data"
        :sortedBy="{ value: 'date', order: 'desc' }"
      />
  </div>
  </div>
</template>
<script>

import Table from '../../components/Table/DataTable.vue'
export default {
  components: {
    Table
    },
  data(){
    return {
      user_login:"",
      filters:{},
      loading:true,
      perPage:10,
      action_items: [
          {title: 'Подтвердить',action:'pays_success'},
          {title: 'Отменить',action:'pays_canselled'},
        ],
      table: {
        data: [],
        headers: [
          { text: 'Телефон', value: 'phone' },
          { text: '№ операции пользователя', value: 'id_number' },
          { text: 'Сумма', value: 'sum' },
          { text: 'Тип операции', value: 'type' },
          { text: 'Дата', value: 'date' },
          { text: 'Статус', value: 'status_pay' },
          // { text: 'Действия', value: 'actions', sortable: false }
        ]
      },

    }
  },
  mounted(){
    this.render()
  },
  methods: {
    render(){
      this.$http.post("/admin_payments/list",
      {},(result)=>{
        this.table.data = result.map(item => {
						const date = new Date(item.date)
						item.date = date.toISOString().slice(0, 10);
						return item
					})
          this.table.data.sort((a, b) => {
          return b.date.localeCompare(a.date)
        })
        this.loading = false
      })
    },
    // paySuccess(item){
    //   this.editPay(item.phone,item.id_number,"success")
    // },
    // payCanselled(item){
    //   this.editPay(item.phone,item.id_number,"cancelled")
    // },
    // editPay(login,number,type_operation){

    //   this.$http.post("/admin_payments/update",
    //   {
    //     login:login,
    //     number:number,
    //     type_operation:type_operation
    //   },()=>{
    //    this.render()
    //   })
    // },


  },
}
</script>
<style scoped>
p{
  text-align:left;
}
.v_email_updated_massage{
  text-align: left;
  color: green;
}
.v_err_updated_massage{
  text-align: left;
  color: #b01111;
}
.mb26{
  margin-bottom: 26px;
}
.top{
  margin-bottom: 24px;
}
.history_title{
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 8px;
}
.desc{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #55575C;
  text-align: left;
  margin-bottom: 16px;
}
.ok{
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}
.cashup{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  background: #FEE600;
  border-radius: 8px;
  padding: 13px;
  margin-top: 40px;
  cursor: pointer;
}
.history{
  margin: 8px auto 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
  position: relative;
  width: max-content;
}
.history:after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #00809C;
  position: absolute;
  bottom: -4px;
}
.save{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  padding: 9px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 16px;
  line-height: 110%;
  color: #2B2D33;
  max-width: max-content;
  cursor: pointer;
}
.top{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.inform{
  display: flex;
}
.left{
  background: #FFFFFF;
  padding: 24px;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 50%;
  height: max-content;
}
.content{
  display: flex;
}
.right{
  width: 50%;
  padding-left: 32px;
}
h1{
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
}
h2{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 24px;
}
.label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.balance{
  width: 40%;
}
.left .title{
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
}
.left .num{
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}
.alert{
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}
.left_text{
  display: flex;
  align-items: center;
}
.left_text .text{
  margin-left: 18px;
}
.mmm_data{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}
.vback{
  border: 1px solid #c3c4c6;
  padding: 10px;
  border-radius: 10px;
}
.vback:hover{
  cursor:pointer;
}
.ok .text{
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
</style>
