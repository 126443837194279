<template>
  <div class="render">
	<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
    <div v-else class="content_table">
      <div class="top">
        <h1>Словари</h1>
      </div>

    <v-tabs class="tabs_table_inner tabs_answer" v-model="tab">
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab class="item_tab">Слова</v-tab>
      <v-tab>Автоответчики</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="lexicotab">
        <div class="words_control">
            <v-text-field class="mr-5 addWord" v-model="new_word" placeholder="Слово" type="text" ></v-text-field>
            <div @click="addWord()" class="word_control_save">Добавить слово</div>
        </div>
        <div class="words_container">

          <div v-for="(word, word_key) in words" :key="word_key" class="word_block">
            <h2>{{word_key}}</h2>
            <div class="word_subwords">
              <div v-for="(obj_word,obj_key) in word"  :key="obj_key" class="subword_sec">
                  <v-text-field class="mr-5" v-model="obj_word[0]" placeholder="Слово" type="text" ></v-text-field>
                  <v-text-field class="mr-5" v-model="obj_word[1]"  placeholder="Вес" type="number" ></v-text-field>
                  <div @click="deleteSubword(word_key,obj_key)" class="subword_delete">
                    <Icon name="delete" />
                  </div>
              </div>
              <div @click="addSubword(word_key)" class="subword_sec_plus">
                <Icon name="plus" />
              </div>
            </div>
            <div class="word_control">
              <p v-show="accessSaveMassage[word_key]" style="transition-duration: 0.4s; color:green;margin-bottom: 0px;">Сохранено успешно</p>
              <div @click="serverDeleteWord(word_key)" class="word_control_delete">Удалить слово</div>
              <div @click="serverUpdateWord(word_key)" class="word_control_save">Сохранить</div>
            </div>
          </div>

        </div>
      </v-tab-item>
      <v-tab-item class="lexicotab">
        <div class="words_control">
            <div @click="saveAutoAnswer()" class="word_control_save">Сохранить</div>
            <div style="transition-duration: 0.4s; color:green;margin-bottom: 0px;" v-show="saveAnswer" class="ml-5">
              Сохранено
            </div>
        </div>
        <div class="autoanswer_elems p-10">
          <div v-for="(itm,idx) in autoAnswer"  :key="idx" class="subword_sec autonans_sub">
              <v-text-field class="mr-5" v-model="autoAnswer[idx]" placeholder="Фраза" type="text" ></v-text-field>
              <div @click="removeAutoAnswer(idx)"  class="subword_delete">
                <Icon name="delete" />
              </div>
          </div>
          <div @click="addAutoAnswer()"  class="subword_sec_plus">
            <Icon name="plus" />
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>


  </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Icon from '../../components/SetIcon.vue'
export default {
  components: {
    Icon
  },
  data(){
    return {
      accessSaveMassage:{},
      new_word:"",
      words:{},
      tab: null,
      autoAnswer:[],
      saveAnswer:false

    }
  },
  mounted(){

    this.render()
    this.renderAutoAnswer()
    console.log(this.accessSaveMassage)
  },
  methods: {
    saveAutoAnswer(){
      this.$http.post("/admin_lexicon/save_autoansewer",
      {
        arr:this.autoAnswer
      },(result)=>{
          if(result){
            this.saveAnswer = true
            this.renderAutoAnswer()
          }
      })
    },
    renderAutoAnswer(){
      this.$http.post("/admin_lexicon/list_autoansewer",
      {
      },(result)=>{
        if(result){
          this.autoAnswer = result
        }
      })
    },
    removeAutoAnswer(index){
      this.autoAnswer.splice(index, 1)
    },
    addAutoAnswer(){
      this.autoAnswer.push("")
    },
    render(){
      this.$http.post("/admin_lexicon/list",
      {},(result)=>{

          Object.keys(result).forEach((wrd)=>{
            Vue.set(this.accessSaveMassage, wrd, false);
          })
          this.words = result

      })
    },
    addSubword(word_key){
      this.words[word_key].push(["",0])
    },
    deleteSubword(word_key,obj_key){
      this.words[word_key].splice(obj_key, 1)
    },
    addWord(){
      Vue.set(this.words,this.new_word, [["",1]]);
    },
    serverUpdateWord(word){
      this.$http.post("/admin_lexicon/update",
      {
        word:word,
        value:this.words[word]
      },(result)=>{
        if (result) {

          Vue.set(this.accessSaveMassage, word, true);
        }
      })
    },
    serverDeleteWord(word){
      this.$http.post("/admin_lexicon/delete",
      {
        word:word,
      },(result)=>{
        if (result) {
            this.render()
        }
      })
    }
  },
}
</script>
<style scoped>
.lexicotab{
  padding:20px;
}
.tabs_answer{
      padding-bottom: 20px;
}
.autoanswer_elems{

}
.words_control{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.addWord{
    max-width: 249px;
}
.word_control{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.word_control_delete:hover{
  cursor:pointer;
  opacity: 1;
    transition-duration: 0.3s;
}
.word_control_delete{
  color: red;
  text-align: center;
  width: 200px;
  opacity: 0.7;
  transition-duration: 0.3s;
}
.word_control_save:hover{
    opacity: 1;
      transition-duration: 0.3s;
}
.word_control_save{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #fee600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2b2d33;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 200px;
  opacity: 0.8;
  transition-duration: 0.3s;
}
.subword_sec_plus{
  display: flex;
  align-items: center;
  justify-content: center;
}
.subword_sec_plus>.icon{
  background: #f4f4f4;
  padding: 15px;
  border-radius: 7px;
  opacity: 0.7;
  transition-duration: 0.3s;
}
.subword_sec_plus>.icon:hover{
  cursor: pointer;
      opacity: 1;
      transition-duration: 0.3s;
}
.subword_delete:hover{
  cursor:pointer;
  opacity: 1;
  transition-duration: 0.3s;
}
.subword_delete{
  display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    opacity: 0.7;
    transition-duration: 0.3s;
}
.subword_sec{
  display: flex;
  width: 24%;
  padding: 12px 12px 0px 12px;
  background: #f4f4f4;
  background: #f4f4f4;
  border-radius: 8px;
  justify-content: space-between;
}
.word_subwords{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
.word_block{
  background: #FFFFFF;
  padding: 24px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 6%), 0px 8px 24px rgb(0 0 0 / 10%);
  border-radius: 16px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-bottom: 10px;
}
div{
  text-align:left;
}
p{
  text-align:left;
}
.v_email_updated_massage{
  text-align: left;
  color: green;
}
.v_err_updated_massage{
  text-align: left;
  color: #b01111;
}
.mb26{
  margin-bottom: 26px;
}
.top{
  margin-bottom: 24px;
}
.history_title{
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 8px;
}
.desc{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #55575C;
  text-align: left;
  margin-bottom: 16px;
}
.ok{
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}
.cashup{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  background: #FEE600;
  border-radius: 8px;
  padding: 13px;
  margin-top: 40px;
  cursor: pointer;
}
.history{
  margin: 8px auto 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
  position: relative;
  width: max-content;
}
.history:after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #00809C;
  position: absolute;
  bottom: -4px;
}
.save{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  padding: 9px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 16px;
  line-height: 110%;
  color: #2B2D33;
  max-width: max-content;
  cursor: pointer;
}
.top{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.inform{
  display: flex;
}
.left{
  background: #FFFFFF;
  padding: 24px;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 50%;
  height: max-content;
}
.content{
  display: flex;
}
.right{
  width: 50%;
  padding-left: 32px;
}
h1{
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
}
h2{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 24px;
}
.label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.balance{
  width: 40%;
}
.left .title{
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
}
.left .num{
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}
.alert{
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}
.left_text{
  display: flex;
  align-items: center;
}
.left_text .text{
  margin-left: 18px;
}
.mmm_data{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}
.vback{
  border: 1px solid #c3c4c6;
  padding: 10px;
  border-radius: 10px;
}
.vback:hover{
  cursor:pointer;
}
.ok .text{
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
.autonans_sub{
  width: 99%;
  margin: 10px 0;
}
</style>
