<template>
  <div class="blacklist">
    <h3>В черный список 
      <span v-if="!data_local.forever"> на ({{data_local.days}}) дней</span>
      <span v-else>навсегда</span>
    </h3>
    <v-checkbox
      v-model="data_local.forever"
      label="Навсегда"
      color="404247"
      hide-details
    ></v-checkbox>
    <v-slider
      class="slider_this"
      v-if="!data_local.forever"
      max="60"
      min="1"
      track-fill-color="#404247"
      thumb-color="#FED500"
      track-color="#AAABAD"
      color="#404247"
      hide-details
      v-model="data_local.days"
      thumb-label="always"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value + 'д.' }}
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  props: ['data', 'edit_callback'],
  methods:{
    input(){
      this.edit_callback(this.data_local)
      return true
    }
  },
  watch: {
    data: function (val) { 
      this.data_local = val
    }
  },
  mounted() {
    this.data_local=this.data
  },
  data(){
    return {
      model:0,
      data_local:{
        days: 14,
        forever: false
      },
    }
  }
}
</script>

<style scoped>
.slider_this{
  margin-top: 50px;
}
.modal_header{
  display: flex;
  align-self: flex-end;
}
.blacklist{
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 554px;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0 ;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.modal_title{
  margin-top: 32px;
  margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
</style>