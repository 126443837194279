<template> 
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="add">
          <Icon name="plus"/>
          <span v-if="type=='create'">Добавить базу номеров</span>
          <span v-if="type=='add'">Добавить номера</span>
          <span v-if="type=='addGroup'">Добавить базу в группу</span>
        </div>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          <span v-if="type=='create'">Добавить новую базу номеров</span>
          <span v-if="type=='add'">Добавить номера</span>
          <span v-if="type=='addGroup'">Добавить базу в группу</span>
        </div>
        <div v-if="type=='create' && this.queue<1">
          <p class="modal_subtitle">Назовите базу номеров</p>
          <v-text-field v-model="basename" placeholder="Тестовая база номеров" :rules="rules"></v-text-field>
        </div>
        <div v-if="type=='create' && this.queue<1">
          <BaseParams :params="params" @updateParams="updateData"/>  
        </div>
        <p class="subtitle">Загрузите номера телефонов</p>
        
        <v-checkbox
    v-if="type=='create' && this.queue < 1"
    v-model="unique"
    color="#FEE600"
    label="Уникальные номера"
  ></v-checkbox>

  <v-checkbox
    v-if="type=='create' && this.queue < 1 && random === false"
    v-model="splitBase"
    color="#FEE600"
    label="Разбить базу на несколько частей"
  ></v-checkbox>

  <v-text-field
  v-if="type === 'create' && queue < 1 && splitBase && random === false"
  v-model.number="splitCount"
  label="Количество частей"
  placeholder="Введите количество частей"
  type="number"
></v-text-field>

<v-checkbox
    v-if="type=='create' && this.queue < 1"
    v-model="addToGroup"
    color="#FEE600"
    label="Добавить базу в группу"
  ></v-checkbox>

  <v-text-field
    v-if="addToGroup"
    v-model="groupName"
    label="Название группы"
    placeholder="Введите название группы"
  ></v-text-field>

  <v-select
  v-if="type ==='addGroup'"
  v-model="groupName"
  :items="groups"
  label="Выберите группу"
  placeholder="Выберите группу"
></v-select>
       
<v-tabs active-class="my_active_tab" background-color="fff" color="#FEE600" v-model="tab">
  <v-tabs-slider color="#FEE600"></v-tabs-slider>
  <v-tab v-if="type !== 'addGroup'"  class="my_tab_item" value="manual" @change="tabStatus">Ввести вручную</v-tab>
  <v-tab class="my_tab_item" value="file" @change="tabStatus" >Загрузить файл</v-tab>
</v-tabs>
<v-tabs-items v-model="tab">
  <v-tab-item key="manual" v-if="type !== 'addGroup'">
            <div class="textarea">
              <v-textarea
                class="my_textarea"
                name="input-7-1"
                rows="5"
                no-resize
                v-model="manual_phone"
                placeholder="Номера телефонов"
              ></v-textarea>
            </div>
            
            <div class="rules">
              Номера должны быть в формате 7(0000)000-00-00, +7(000)000-00-00 , 8(000)000-00-00 или 8(000)000-00-00 по одному номеру в строке
            </div>
          </v-tab-item>
          <v-tab-item key="file">
            <uploader-file
  @columns="getDataUpload"
  :name="basename"
  :unique="unique"
  :params="params"
  :is-save="isSave"
  :percent-load-column="percentLoadColumn"
  :clear-file="isClearFile"
  :splitBase="splitBase"
  :splitCount="splitCount"
  @clear-file="onClearFile"
/>
            <div v-if="this.phones" class="textarea">
              <div class="count">{{this.count}} номеров</div>  
              <v-textarea
                class="my_textarea"
                name="input-7-1"
                no-resize
                rows="5"
                v-model="this.phones"
                placeholder="Номера телефонов"
              ></v-textarea> 
              
              <div class="rules">
                Номера должны быть в формате 7(0000)000-00-00, +7(000)000-00-00 , 8(000)000-00-00 или 8(000)000-00-00 по одному номеру в строке
              </div>
            </div>
          </v-tab-item>    
        </v-tabs-items>
        <div v-if="lead_type=='black' && !this.foreverCheckbox" class="numDiv">
          <span v-if="lead_type=='black'">В чёрный список на ({{ this.params.day }}) дней</span>
          <input v-if="lead_type=='black' && !this.foreverCheckbox" class="input_num" v-model="params.day">
        </div>
        <label v-if="lead_type=='black'">
          <input type="checkbox" v-model="foreverCheckbox" @change="foreverCheck"> Навсегда
        </label>
        <div v-if="this.queue>0">Обработано {{this.complete}}/{{this.queue}}</div>
        <div v-if="this.queue<1" class="add add_modal" style="margin-top: 10px" @click="saveBase">
          <span v-if="type=='create'">Добавить базу</span>
          <span v-if="type=='add'">Добавить номера</span>
          <span v-if="type=='addGroup'">Добавить базу в группу</span>
        
        </div>
		<div class="rules">
            Добавляя базу номеров Вы подтверждаете факт получения Вами явного согласия от каждого абонента в загружаемой базе на получение рекламной информации в соответствии с ч. 1 и 2 ст. 18 Закона «О рекламе»
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'

import BaseParams from './Form/BaseParams.vue'
import UploaderFile from '../../FileUploader.vue'
export default {
  props: ['callback_refresh','type','guid','lead_type', 'groups'],
  components: {
    Icon, 
    BaseParams,
    'uploader-file': UploaderFile,
  },
  methods: {
    foreverCheck(){
      if (this.foreverCheckbox) {
      this.params.day = '1000000'
      } else{
        this.params.day = '10'
      }
    },
    tabStatus(){
      this.random = !this.random
    },
    /** 
    * Обработка события после оитки данных о файле
    * @function onClearFile
    */
    onClearFile(event){
      this.percentLoadColumn = 0
      this.isClearFile = event
      
    },  
    
    /** 
    * Получение данных о колонках
    * @function getDataUpload
    */
    getDataUpload (data){
      this.dataUpload = data
    },
   loadFile(obj,count)
   {
     this.phones=obj
     this.count=count
   },
   updateData(data)
   {
     this.params=data
   },
   partLoad(name,phones,i,id,type)
   {
     let part_phones=phones.slice(i,i+1000)
     this.$http.post("/base/add",
     {
       name:name,
       params:this.params,
       unique:this.unique,
       id:this.guid || id,
       type:type,
       lead_type:this.lead_type,
       phones:part_phones,
       splitBase: this.splitBase,
      splitCount: this.splitCount,
      groupName: this.groupName,
     },(data)=>{
       this.check_complete+=data.count
       id=data.id
       this.complete+=part_phones.length
       if (part_phones.length<1000)
       {
         this.complete=0
         this.queue=0
         this.manual_phone=''
         this.phones=false
         
        
         this.callback_refresh() 
         this.dialog=false  
         
         this.check_complete=0
         
       }
       else
       {
         this.partLoad(name,phones,i+1000,id,type)
       }
        
       
     })
   },
    async saveBase () {
      if(this.groupName === '' && this.type === 'addGroup') {
        alert('Выберите базу для добавления')
        return
      }
      if (this.type === 'addGroup') {
      this.addToSomeGroup = true;
    }
      if (this.splitBase && this.splitCount < 1) {
      alert("Введите количество частей для разбиения базы.");
      return;
    }

      const {$http} = this
      if (this.dataUpload) {
  let eventProgress;

  if (this.guid) this.dataUpload.guid = this.guid;
  this.isSave = true;
  this.dataUpload.splitBase = this.splitBase;
  this.dataUpload.splitCount = this.splitCount;
  this.dataUpload.addToGroup = this.addToGroup;
  this.dataUpload.groupName = this.groupName;

  if (this.addToSomeGroup) {
    eventProgress = $http.eventSource('upload/updateGroup', this.dataUpload);
    this.addToSomeGroup = false;
  } else {
    eventProgress = $http.eventSource('upload/load-columns', this.dataUpload);
  }

  eventProgress.addEventListener('message', (event) => {
    this.percentLoadColumn = +event.data;
    if (+event.data >= 99) {
      this.complete = 0;
      this.queue = 0;
      this.manual_phone = '';
      this.phones = false;
      this.callback_refresh();
      this.dialog = false;
      this.check_complete = 0;
      this.dataUpload = null;
      this.isClearFile = true;
      this.isSave = false;
      eventProgress.dispatchEvent(new Event('message'));
      window.location.reload()
    }
  });

  return;
}
      else {
        let phones = this.manual_phone
        if (this.tab == 1) {
          phones = this.phones
        }
        phones = phones.split(/\r\n|\r|\n/g);

        if (this.type == "create") {
          if (this.basename.length < 51 && this.basename.length > 0) {
            this.complete = 0
            this.queue = phones.length
            this.partLoad(
            this.basename,
            phones,
            0,
            this.splitBase,
            this.splitCount,
            this.addToGroup,
            this.groupName
          );
          }
        }
        else {
          this.complete = 0
          this.queue = phones.length
          this.partLoad(
          this.basename,
          phones,
          0,
          this.splitBase,
          this.splitCount,
          this.guid,
          this.lead_type,
          this.addToGroup,
          this.groupName
        );
        }
      }
    }
  },
  data(){
    return {
      foreverCheckbox:false,
      random: true,
      customGroupName: "",
      addToSomeGroup:false,
      addToGroup: false,
      groupName: "",
      splitBase: false,
      splitCount: 1,
      dialog: false,
      count:0,
      queue:0,
      complete:0,
      unique:0,
      check_complete:0,
      phones: false,
      manual_phone:"",
      basename: "",
      params:{
        autodelete:false,
        day:10
      },
      tab: 'manual',
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 50 || 'Max 50 символов'
      ],
      dataUpload: null, // Колонки для добавления
      isSave: false, // Признак нажатия кнопки "Добавить базу"
      percentLoadColumn: 0,  // Процентное значение загрузки колонок
      isClearFile: false // Признак очистки загруженного файла
    }
  }
}
</script>
<style scoped>


.count
{
width:100%;
text-align:left;
font-size: 13px;
font-weight: 600;
line-height: 24px;  
}
.my_textarea{
  padding-top: 16px;
}
.my_tab_item{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  text-transform: none;
}
.my_tab_item:hover{
  color: #2B2D33!important;
  background: none!important;
}
.my_active_tab{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33!important;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}
.add_modal{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.textarea{
  padding: 16px 0;
}
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  margin-top: 24px;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
 .add{
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .add .icon{
    margin-right: 11px;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }

  .input_num{
    height: 30px;
    background: #FFF;
    border: 1px solid #AAABAD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 12px;
    width: 100px;
    text-align: center;
    margin-top:5px
  }
  .numDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>