<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="490">
			<div @click="close" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<div class="modal_title" v-if="!guid">
					Новый обзвон
				</div>
				<div class="modal_title" v-if="guid">
					Редактировать обзвон
				</div>
				<div>
					<p class="modal_subtitle">Введите название</p>
					<v-text-field height="40" placeholder="Тестовый обзвон" v-model="data_local.name"
						:rules="rules"></v-text-field>
				</div>

				<div>
					<p class="modal_subtitle">Выберите базу контактов</p>
					<v-select
							class="mih40"
							height="40"
							hide-details
							item-color="#fee600"
							:items="bases"
							item-value="id"
							v-model="data_local.select_base"
							item-text="name"
							solo
					></v-select>
					<div class="add_new">
						<router-link :to="'/bases'">Добавить новую базу контактов</router-link>
					</div>
				</div>

				<div>
					<p class="modal_subtitle">Выберите сценарий звонка</p>
					<v-select
							height="40"
							class="mih40"
							hide-details
							@change="extractTargets"
							item-color="#fee600"
							:items="scenaries"
							item-value="id"
							v-model="data_local.select_scenario"
							item-text="name"
							solo
					></v-select>
					<div class="add_new">
						<router-link :to="'/scripts'">Добавить новый сценарий звонка</router-link>
					</div>
				</div>

<!-- 				<div>
					<p class="modal_subtitle">Выберите исходящий номер</p>
					<v-select
							height="40"
							class="mih40"
							hide-details
							item-color="#fee600"
							:items="numbers"
							item-value="clear"
							v-model="data_local.select_number"
							item-text="number"
							solo
					></v-select>
					<div class="add_new">
						<router-link :to="'/incoming_calls'">Добавить номер</router-link>
					</div>
				</div> -->

				<div class="select_type" v-if="!guid">
					<div class="titler">
						Выберите тип обзвона
					</div>
					<v-radio-group v-model="data_local.currentType">
						<v-tooltip top max-width="300" color="#2B2D33" v-for="n in type" :key="n.value">
							<template v-slot:activator="{ on, attrs }">
								<div class="tooltip">
									<v-radio
											:label="n.name"
											:value="n.value"
											color="#FEE600"
									>
									</v-radio>
									<span class="question" v-bind="attrs" v-on="on">
                    <Icon name="question"/>
                  </span>
								</div>
							</template>

							<span> {{ n.tooltip }}</span>
						</v-tooltip>
					</v-radio-group>
				</div>

				<div v-if="data_local.currentType == 'auto'" class="autos">
					<div class="heading">Тригер для срабатывания обзвона</div>
					<v-select
							class="mih40"
							height="40"
							item-color="#fee600"
							:items="triggers"
							item-value="id"
							v-model="data_local.select_trigger"
							item-text="name"
							solo
					></v-select>
				</div>

				<v-expansion-panels>
					<v-expansion-panel class="my_expand_panel">
						<v-expansion-panel-header>Расширенные настройки</v-expansion-panel-header>
						<v-expansion-panel-content>
							<!-- <div class="heading">Операторы</div>
							<v-combobox
									class="p2"
									v-model="data_local.operators_selected"
									:menu-props="{ top: true, offsetY: true }"
									:items="operators"
									multiple
									hide-details
									item-color="#484848"
							></v-combobox> -->
							<div class="heading">Регионы</div>
							<v-combobox
									class="p2"
									v-model="data_local.regions_selected"
									:menu-props="{ top: true, offsetY: true }"
									:items="regions"
									multiple
									hide-details
									item-color="#484848"
							></v-combobox>
							<div class="heading">Дата и время отложенного запуска</div>
							<div class="date_time">
								<div class="input_wrap">
									<v-menu
											v-model="data_local.menu1"
											:close-on-content-click="false"
											:nudge-right="40"
											transition="scale-transition"
											offset-y
											min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<div class="rel" v-bind="attrs" v-on="on">
												<v-text-field
														v-model="data_local.date"
														readonly
														height="32"
														hide-details
														class="input_datetime"
												/>
                        <div @click="clearDateTimeDefer('date')">
                          <Icon v-on="on" v-if="data_local.date" class="close-date-picker" name="close"/>
                        </div>
												<Icon  v-if="!data_local.date" class="abs" name="calendar"/>  
											</div>
                      
										</template>
										<v-date-picker
												v-model="data_local.date"
												@input="data_local.menu1 = false"
												locale="ru"
												color="#FEE600"
												event-color="#000"
                        class="m-w-200p"
												no-title
												first-day-of-week="1"
										></v-date-picker>
									</v-menu>
								</div>
								<div class="input_wrapper">
									<v-menu
											ref="menu"
											v-model="data_local.menu2"
											:close-on-content-click="false"
											:nudge-right="40"
											transition="scale-transition"
											offset-y
											max-width="290px"
											min-width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
                      <div class="rel">
                        <v-text-field
													v-model="data_local.time"
													readonly
													height="32"
													hide-details
													v-bind="attrs"
													v-on="on"
													class="input_datetime w-80p"
                          />
                        <div @click="clearDateTimeDefer('time')">
                          <Icon  v-if="data_local.time" class="close-date-picker" name="close"/>                        
                        </div>
                      </div>
										</template>
										<v-time-picker
												v-if="data_local.menu2"
												v-model="data_local.time"
												format="24hr"
												color="#FEE600"
												@click:minute="$refs.menu.save(date_local.time)"
										></v-time-picker>
                  
									</v-menu>
								</div>
							</div>

							<div class="row_wrapper">
								<div class="heading">Добавить действия с номерами которым не получилось дозвонится</div>
								<div class="plus" @click="addOptionsNoCall()">
									<Icon name="plus"/>
								</div>
							</div>
							<div v-for="(option, key) in data_local.options_no_call" :key="key">
								<div class="row_wrapper">
									<v-select
											class="mih40"
											height="40"
											hide-details
											item-color="#fee600"
											:items="list_action"
											item-value="id"
											v-model="option.id"
											item-text="name"
											solo
											placeholder="Выберите действие"
											@change="changeOption(key)"
									></v-select>
									<div class="minus mr-0 ml-2" @click="deleteOptionsNoCall(key)">
										<Icon name="minus"/>
									</div>
								</div>
								<div class="" v-if="option.id === list_action[1].id">
									<div class="heading">Укажите параметры интеграции</div>
									<v-text-field
											height="40"
											placeholder="id"
											v-model="option.options"
									></v-text-field>
								</div>
							</div>

							<div class="heading">
  Попытки дозвониться
  <v-tooltip top max-width="300" color="#2B2D33">
    <template v-slot:activator="{ on, attrs }">
      <span class="question" v-bind="attrs" v-on="on">
        <Icon name="question"/>
      </span>
    </template>
    <span>Предельное количество попыток дозвониться. Минимальное значение - 1, максимальное - 4.</span>
  </v-tooltip>
</div>

<div class="input_wrapper">
  <div class="minus" @click="decrementCallAttempts" :disabled="tryCallsDisplay <= 1">
    <Icon name="minus"/>
  </div>
  <v-text-field 
    class="mini_input"
    :value="tryCallsDisplay" 
    readonly
    hide-details
    ></v-text-field>
  <div class="plus" @click="incrementCallAttempts" :disabled="tryCallsDisplay >= 4">
    <Icon name="plus"/>
  </div>
</div>
							<div class="heading">Через сколько минут перезванивать</div>
							<div class="input_wrapper">
								<div class="minus" @click="data_local.delay_call--">
									<Icon name="minus"/>
								</div>
								<v-text-field height="32" class="mini_input" min="0"
									v-model="data_local.delay_call"></v-text-field>
								<div class="plus" @click="data_local.delay_call++">
									<Icon name="plus"/>
								</div>
							</div>
							<div class="heading">
								<v-checkbox
										label="Черный список по всем моим базам"
										color="#FEE600"
										v-model="data_local.all_blacklist"
								></v-checkbox>
							</div>
							<div class="heading">
								<v-checkbox
										label="Остановить по достижению цели"
										color="#FEE600"
										v-model="data_local.target_on"
								></v-checkbox>
							</div>
							<div v-if="data_local.target_on">
								<v-select
										class="mih40"
										height="40"
										v-model="data_local.target_name"
										:items="targets"
										item-color="#fee600"
										solo
								></v-select>

								<div class="input_wrapper">
									<div class="minus" @click="data_local.limit--">
										<Icon name="minus"/>
									</div>
									<v-text-field height="32" class="mini_input" min="0"
										v-model="data_local.limit"></v-text-field>
									<div class="plus" @click="data_local.limit++">
										<Icon name="plus"/>
									</div>
								</div>

							</div>

              <v-checkbox
										label="Использовать часовой пояс клиента"
										color="#FEE600"
										v-model="data_local.is_client_gtm"
								></v-checkbox>
							<div class="heading">Учитывать время клиента</div>

              <div v-if="!data_local.is_client_gtm">
                <v-select class="mih40" height="40" hide-details item-color="#fee600" :items="listTimezoneRu" item-value="gtm" v-model="data_local.client_gtm" item-text="name" solo/>  
              </div>
              
              

							<div>
								<v-range-slider
										v-model="data_local.range"
										:max="24"
										:min="0"
										hide-details
										track-fill-color="#404247"
										thumb-color="#FED500"
										track-color="#AAABAD"
										color="#404247"
										class="align-center"
								>
									<template v-slot:prepend>
										<v-text-field
										v-model="data_local.range[0]"
												class="mt-0 pt-0 mini_input"
												hide-details
												single-line
												type="number"
												style="width: 40px;height: 40px;"
												
										></v-text-field>
									</template>
									<template v-slot:append>
										<v-text-field
										v-model="data_local.range[1]"
												class="mt-0 pt-0 mini_input"
												hide-details
												single-line
												type="number"
												style="width: 40px;height: 40px; padding: 4px;"
												
										></v-text-field>
									</template>
								</v-range-slider>


							</div>

						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

				<div class="add btn_save" v-if="guid" @click="save(false)">Сохранить</div>
				<button class="add_sec" v-if="!guid" @click="save('wait')" :disable="disabledSave">Добавить и запустить</button>
				<div class="cancel cancel_btn" v-if="!guid" @click="save('stop')">Добавить без запуска</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'

export default {
	components: {
		Icon
	},
	props: ['name', 'callback_refresh', 'show_dialog', 'data', 'guid'],
	watch: {
		show_dialog: function (val) {
			this.dialog = val
			if (this.data) {
				this.data_local = JSON.parse(JSON.stringify(this.data))
        
        if(this.data.is_client_gtm === false) this.data_local.is_client_gtm = false
        else if(this.data.is_client_gtm && this.data_local.is_client_gtm === true) this.data_local.is_client_gtm = true
        else this.data_local.is_client_gtm = true
				this.extractTargets()
        
        
				// console.log(this.data_local.options_no_call, '++++++++++++++++++++++++++++++++++++++')
			} else {
				this.data_local = {
					name: null,
					menu1: false,
					range: [9, 18],
					target_on: false,
					all_blacklist: false,
					target_name: '',
					limit: 0,
					target: 0,
					menu2: false,
					operators_selected: [],
					regions_selected: [],
					select_base: null,
					try_calls: 0,
					time: null,
					date: null,
					delay_call: 10,
					select_trigger: "add",
					select_scenario: null,
					currentType: 'standart',
					options_no_call: [],
					select_number:null,
          is_client_gtm: true, // Признак использования часового пояса клиента
          client_gtm: this.getTimeZone()
				};
			}

		}
	},
	methods: {
		incrementCallAttempts() {
    if (this.tryCallsDisplay < 4) {
      this.data_local.try_calls++;
    }
  },
  decrementCallAttempts() {
    if (this.tryCallsDisplay > 1) {
      this.data_local.try_calls--;
    }
  },
    /** 
    * Очистка полей даты и времени отложенного запуска
    * @function clearDateTimeDefer
    * @param {String} typeInput - Тип поля date или time
    */
    clearDateTimeDefer(typeInput){
      alert(1)
      if(typeInput === 'date') this.data_local.date = null
      if(typeInput === 'time') this.data_local.time = null
    },
    
    /**
    * Получение временной зоны
    * Пример возврата для Московского времени: 3
    * @function getTimeZone
    * @return {Number} Временная зона
    */
    getTimeZone() {
      const offset = new Date().getTimezoneOffset(), o = Math.abs(offset)
      return +((offset < 0 ? '' : '-') + ('00' + Math.floor(o / 60)).slice(-1))
    },
		extractTargets() {
			this.targets = []
			this.scenaries.forEach((item) => {
				if (item.id == this.data_local.select_scenario) {
					if (item.data) {
						let data = JSON.parse(item.data)
						for (let key in data) {
							if (data[key].type == "target") {
								this.targets.push(data[key].data.name)
							}
						}
					}
				}
			})
		},
		close(render = false, errored = false, error_message = '') {
			this.dialog = false

			this.data_local = {};
			this.callback_refresh(render, errored, error_message)
		},
		save(status = false) {
			this.disabledSave = true
			if (this.data_local.name && this.data_local.select_base && this.data_local.select_scenario) {
				if (status) {
					this.data_local.status = status
				}

				this.data_local.contacts = 0
				this.data_local.call = 0
				delete this.data_local['created_at']
				this.data_local.try_calls = this.tryCallsDisplay - 1;
				this.$http.post("/call/add",
					{
						id: this.guid,
						data: this.data_local
					}, (data) => {
						this.close(true, data.errored, data.error_message)
					})
					
					this.close(true)
					
			}
		},
		listDicts() {
			let _this = this
			this.bases.length = 0
			this.$http.post("/base/dicts",
				{}, (data) => {
					_this.operators = data.operators
					_this.regions = data.regions
				})
		},
		listScripts() {
			let _this = this
			this.bases.length = 0
			this.$http.post("/script/list",
				{}, (data) => {
          if(data)
					data.forEach((item) => {
						_this.scenaries.push({id: item.guid, name: item.name, data: item.data})

					})
					_this.extractTargets()
				})
		},
		listBases() {
			let _this = this
			this.bases.length = 0
			this.$http.post("/base/shortlist",
				{}, (data) => {
          if(data)
					data.forEach((item) => {

						_this.bases.push({id: item.guid, name: item.name})

					})

				})
		},
		listNumbers() {
			let _this = this
			this.$http.post("/numbers/get_user_numbers",
				{}, (data) => {
					console.log(data)
					let numberValueReduced = phone => {
							return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
						}
					this.numbers.push({clear: null, number: 'Мой случайный номер'})
          if(data && data.data && data.data.length)
					data.data.forEach((item) => {
						_this.numbers.push({clear: item.phone, number: numberValueReduced(item.phone)})
					})

				})
		},
		addOptionsNoCall(){
			// console.log(this.data_local.options_no_call)
			// console.log('=======================')
			if (this.data_local.options_no_call.length === 0
				|| this.data_local.options_no_call[this.data_local.options_no_call.length - 1].name_option !== ''
			){
				this.data_local.options_no_call.push({
					name_option: 'Выберите действие',
					options: ''
				})
			}

		},
		deleteOptionsNoCall(id){
			this.data_local.options_no_call.splice(id, 1)
		},
		changeOption(key){
      if(this.data_local.options_no_call[ key ].id !== 1){
        delete this.data_local.options_no_call[ key ].options
        delete this.data_local.options_no_call[ key ].name_option  
      }
			
		},
	},
	computed: {
  tryCallsDisplay() {
    return this.data_local.try_calls + 1;
  }
},
	mounted() {
		this.listBases()
		this.listScripts()
		this.listDicts()
		this.listNumbers()
	},
	data() {
		return {
			disabledSave:false,
			operators_selected: null,
			regions_selected: null,
			targets: [],
			operators: [],
			regions: [],
			data_local: {
				try_calls: 0,
			},
			hidedate: true,
			hidetime: true,
			timeStep: null,
			datepicker: null,
			dialog: false,
			tab: 'manual',
			rules: [
				value => !!value || 'Обязательное поле',
				value => (value || '').length <= 50 || 'Max 50 символов',
				value => !/[\t/\\|]/.test(value) || 'В названии не должно присутствовать символов "/", "\\", "|", или табуляции ↹'
				],
			bases: [],
			numbers:[],
			scenaries: [],
			triggers: [{id: 'add', name: 'Попадание номера в базу контактов'},],
			type: [
				{
					name: 'Стандартный',
					value: 'standart',
					tooltip: 'Остановится после обзвона всех номера в выбранной базе номеров'
				},
				{name: 'Автоматический', value: 'auto', tooltip: 'Звонок срабатывает при активации выбранного триггера'}
			],
			list_action: [
        {id: 0, name: 'Переместить в другую базу' },
        { id: 1, name: 'Интеграция с amoCRM' },
			],
      /** Список часовых поясов  */
      listTimezoneRu: [
        { name: 'Калининград +02.00', gtm: 2},
        { name: 'Москва +03.00', gtm: 3},
        { name: 'Самара +04.00', gtm: 4},
        { name: 'Екатеринбург +05.00', gtm: 5},
        { name: 'Омск +06.00', gtm: 6},
        { name: 'Красноярск +07.00', gtm: 7},
        { name: 'Иркутск +08.00', gtm: 8},
        { name: 'Якутск +09.00', gtm: 9},
        { name: 'Владивосток +10.00', gtm: 10},
        { name: 'Магадан +11.00', gtm: 11},
        { name: 'Камчатка +12.00', gtm: 12},
      ]
		}
	}
}
</script>
<style scoped>
.close-date-picker{
  position: absolute; 
  top: 9px; 
  right: 9px
}

* {
    user-select: none;
}

.my_expand_panel {
    padding: 0;
}

.my_expand_panel:before {
    box-shadow: none;
    /*border-radius: none;*/
}

.my_expand_panel .v-expansion-panel-content {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.my_expand_panel .v-expansion-panel-header v-expansion-panel-header--active {
    padding: 0;
    min-height: initial;
}

.input_datetime {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #2B2D33;
    width: 200px;
}

.datepicker {
    position: absolute;
}

.date_time {
    display: flex;
}

.input_wrap {
    margin-right: 6px;
}

.input_wrapper {
    max-width: 65px;
}

.rel {
    position: relative;
}

.abs {
    position: absolute;
    top: 9px;
    right: 9px;
}

.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: left;
    margin-bottom: 6px;
    margin-top: 16px;
    color: #404247;
}

.input_wrapper {
    display: flex;
    align-items: center;
}

.mini_input {
    min-width: 63px;
    height: 32px;
    text-align: center;
    padding: 7px 12px;
}

.plus {
    border: 1px solid #FEE600;
    border-radius: 8px;
    background: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
    padding: 8px;
}

.minus {
    margin-right: 6px;
    border: 1px solid #FEE600;
    border-radius: 8px;
    background: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    padding: 16px 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.select_type .titler {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    color: #404247;
    text-align: left;
    margin-bottom: 13px;
    margin-top: 16px;
}

.question {
    cursor: pointer;
}

.tooltip {
    display: flex;
    align-items: baseline;
}

.tooltip .icon {
    margin-left: 8px;
}

.add_new {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #00809C;
    width: max-content;
    margin-bottom: 16px;
    margin-top: 6px;
}

.add_new a {
    text-decoration: none;
}

.add_new:after {
    background: #66C9D7;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}

.rules {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: #AAABAD;
    text-align: left;
    margin-bottom: 24px;
}

.add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    justify-content: center;
}

.add_sec {
	width: 100%;
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    justify-content: center;
}

.add .icon {
    margin-right: 11px;
}

.add_modal {
    width: 100%;
    padding: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 110%;
    color: #2B2D33;
}

.textarea {
    padding: 16px 0;
}

.subtitle {
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    color: #404247;
}

.wrapper {
    padding: 32px;
}

.modal_subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    margin-bottom: 6px;
    text-align: left;
    color: #404247;
    margin-bottom: 6px;
}

.modal_title {
    padding: 0 !important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2B2D33;
}

.row_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.m-w-200p{
  min-width: 80px;
}

.w-80p{
  width: 80px
}
</style>
