<template>
	<div>
		<div class="top">
			<div v-if="activated === 'unactive'" class="alert">
				<div class="left_text">
					<Icon name="attention"/>
					<div class="text">Активируйте аккаунт</div>
				</div>
				<div @click="openCompanyEdit" class="mmm_data">Заполнить данные</div>
			</div>
			<div v-if="activated === 'waiting'" class="alert">
				<div class="left_text">
					<Icon name="attention"/>
					<div class="text">Данные отправлены. Ожидайте подтверждения</div>
				</div>
			</div>
			<div v-if="activated === 'active'" class="ok">
				<div class="left_text">
					<Icon name="green_ok"/>
					<div class="text">Аккаунт идентифицирован</div>
				</div>
			</div>
		</div>
		<CompanyEdit :dialogstate="showCompanyEdit" :dialogclose="closeCompanyEdit" :setbar="setTopBar"/>
	</div>
</template>

<script>
import CompanyEdit from './CompanyEdit.vue'
import Icon from '@/components/SetIcon.vue'

export default {
	name: "AccountActivation",
	components: {
		CompanyEdit, Icon
	},
	data(){
		return {
			showCompanyEdit:false,
			activated: this.$store.getters['user/getUserFields'].user_status,
		}
	},
	methods: {
		setTopBar(newStatus){
			this.activated = newStatus;
		},
		openCompanyEdit(){
			this.showCompanyEdit = true
		},
		closeCompanyEdit(){
			this.showCompanyEdit = false
		},
	}
}
</script>

<style scoped>

.top{
  margin-bottom: 24px;
}
.ok{
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}

.top{
  display: flex;
  flex-direction: column;
}

.left .title{
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
}
.left .num{
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}
.alert{
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}
.left_text{
  display: flex;
  align-items: center;
}
.left_text .text{
  margin-left: 18px;
}
.mmm_data{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}
.ok .text{
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
@media(max-width: 820px){
  .alert{
    flex-direction: column;
  }
  .mmm_data{
    margin-top: 15px;
  }
}


</style>