<template>
  <div class="http">
    <h3>Тип запроса</h3>
    <v-select label="Тип запроса" height="40" hide-details item-color="#fee600" :items="['GET', 'POST']" v-model="typeRequest" solo @change="setTypeRequest" />
    <br>
    <template v-if="typeRequest == 'GET'">
      <h3>Postback url</h3>
      <v-textarea class="my_textarea" @input="input" name="input-7-1" v-model="data_local.link" auto-grow
        placeholder="http://kakayato/link?subid={s}" />
    </template>
    <template v-if="typeRequest == 'POST'">
      <h3>URL для POST-запроса</h3>
      <v-text-field height="40" v-model="data_local.postURL" placeholder="http://sitename" hide-details="auto" />
      <br>
      <h3>Параметры запроса в формате JSON</h3>
      <v-textarea  @input="input" v-model="data_local.params" placeholder='{"phone": propsName, "callName": propsCallName, "record": propsRecord, "target": propsTarget}' />
    </template>
    <div class="paste" @click="paste('phone')">Вставить номер клиента</div>
    <div class="paste" @click="paste('callname')">Вставить название обзвона</div>
    <div class="paste" @click="paste('target')">Вставить название цели</div>
    <div class="paste" @click="paste('answer')">Вставить текст ответа</div>
  </div>
</template>

<script>
export default {
  props: [ 'data', 'edit_callback' ],
  methods: {
    paste (type) {
      this.data_local.link += "{" + type + "}"
    },
    input () {
      this.edit_callback(this.data_local)
      return false
    },
    setTypeRequest(value){
      this.data_local.typeRequest = value
    }
  },
  watch: {
    data: function (val) {
      this.data_local = val
    }
  },
  mounted()  {
    this.data_local = this.data
    if(this.typeRequest === 'POST'){
      if (this.data.postURL === null || this.data.postURL === undefined) this.data_local.postURL = 'https://'
      if (this.data.params === null || this.data.params === undefined) this.data_local.params = '{\n\tphoneNumber:\n }'  
    }
    if (this.data.typeRequest === null || this.data.typeRequest === undefined) this.typeRequest = 'GET'
    else this.typeRequest = this.data.typeRequest
  },
  
  data () {
    return {
      model: 0,
      typeRequest: '',
      data_local: {
        link: '',
        typeRequest: 'GET',
        postURL: '',
        params: '{\n}'
      },
    }
  }
}
</script>

<style scoped>
.modal_header {
  display: flex;
  align-self: flex-end;
}

.http {
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}

.modal_body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 554px;
}

.save {
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}

.paste {
  padding: 9px 20px 10px;
  background: #FFFFFF;
  display: inline-block;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}

.modal_title {
  margin-top: 32px;
  margin-bottom: 32px;
}

h3 {
  margin-bottom: 12px;
  text-align: left;
}

.close {
  margin-right: 52px;
  margin-top: 20px;
}

@media (max-width: 650px) {
  .http {
    width: auto;
  }
}
</style>
