<template>
    <div class="flex">
      <input class="login_input" type="text" v-model="nickname" @change="cb" placeholder="Введите Ваше имя" />
    </div>
  </template>
  
  <script>
  export default {
    props: ['callback'],
    data() {
      return {
        nickname: ''
      };
    },
    methods: {
      cb() {
       
        this.callback(this.nickname)
      }
    }
  };
  </script>
  
  <style scoped>
  .login_input{
    height: 48px;
    background: #FFF;
    border: 1px solid #AAABAD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 12px;
  }
  .danger
  {
    border:1px solid red;
  }
  .flex{ 
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
  }
  span{ 
    font-size: 13px;
    color: #EE505A;
    margin: 4px 0px;
    text-align: left;
  }
</style>
  