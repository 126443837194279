<template>
  <div class="telegram">
    <div v-if="tabs.length<1">
      Нет интеграций!
    </div>
    <div v-if="tabs.length>0">
    <v-toolbar flat>
      <template v-slot:extension>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab active-class="active_tab" v-for="item,i in tabs" :key="i" >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    
    <v-tabs-items v-model="tab">
        <v-tab-item v-for="item,i in tabs" :key="i" >
          <v-card v-for="tab_item,j in tab_items" :key="j" flat>
            <div class="item_tab" v-if="item == tab_item.vs">
              <h3>Текст сообщения</h3>
              <v-textarea 
                class="my_textarea" 
                name="input-7-1" 
                @input="input" 
                v-model="data_local.text"
                auto-grow 
                placeholder="Вы успешно записаны к психологу на 13 число этого месяца"
              ></v-textarea>
              <div class="paste paste-p" @click="paste('phone')">Вставить номер клиента</div>
              <div class="paste paste-p" @click="paste('record')">Вставить ссылку на запись</div>
              <div class="paste paste-p" @click="paste('target')">Вставить цели</div>
              <div style="display: flex;"> 
                <div style="width:50%; margin-top: 4px;">
                <v-text-field
                    height="40"
                    v-model="fieldName"
                    placeholder="Укажите наименование поля"
                    hide-details="auto"
                  />  
                </div>
                
                <button class="paste" style="padding: 9px" @click="paste(fieldName, true)">Добавить наименование поля</button>
              </div>
            </div>
          </v-card>
        </v-tab-item>
    </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data','edit_callback'],
  methods:{
    paste(tag, clear)
    {
      if(tag){
        this.data_local.text = this.data_local.text + "{" + tag + "}"
        if(clear) this.fieldName = null  
      }
      
    },
    input()
    {
        this.save()
    },
	save(){
		let id=this.tab_items[this.tab].guid
        this.data_local.guid=id
        this.edit_callback(this.data_local)
	}
  },
  watch: {
    tab: function (val) {
      this.data_local.guid=this.tab_items[val].id
      this.save()
    },
    data: function (val) { 
      this.data_local=val
      this.tab_items.forEach((item,i)=>{
        if (item.guid==this.data_local.guid)
        {
          this.tab=i
        }
      })
    }
  },
  mounted()
  {
    this.data_local=this.data
    this.tab_items.length=0
    this.tabs.length=0
    this.$http.post("/integrations/list",
    {
    type:"Telegram"
    },(data)=>{
      if (!this.data_local.guid && data.length>0)
      {
        this.data_local.guid=data[0].guid
        this.edit_callback(this.data_local)
      }
      data.forEach((item,i)=>{
        this.tabs.push(item.name)
        if (item.guid==this.data_local.guid)
        {
          this.tab=i
        }
        
        this.tab_items.push({
          vs:item.name,
          guid:item.guid
        })
        
      })
      
    })
    // this.data_local=this.data
  },
  data(){
    return {
      model:0,
      tab: null,
      tabs: [],
      tab_items: [
        
      ],
      data_local:{
        id:'',
        text:'',
        guid:''
      },
      fieldName: null
    }
  }
}
</script>

<style scoped>
.active_tab{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}
.modal_header{
  display: flex;
  align-self: flex-end;
}
.item_tab{
  margin: 32px auto 0 auto;
  max-width: 800px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}

.paste-p{
  padding: 9px 20px 10px;
}
.paste{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 0;
  cursor: pointer;
  margin: 7px;
  max-width: 300px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  display: inline-block;
  color: #2B2D33;
}
.modal_title{
margin-top: 32px;
margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
</style>