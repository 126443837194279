<template>
  <div
    class="smart-captcha"
    data-sitekey="ysc1_gvEbLG4PR2Di2wp2Q1K61QBjH9taWnrmEtBLSDg1e0d12fba"
    data-callback="getToken"
    ></div>
</template>

<script>
export default {
  props: [ 'callback' ],
  data() {
    return {
      token: null
    }
  },
  created(){
    window.getToken = (token) => {
      sessionStorage.setItem('captchaToken', token)
      this.token = token
    }
  },
  mounted () {
    let script = document.createElement('script')
    script.async = true
    script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js'
    document.head.appendChild(script)
    
  },

  watch: {
    token(){
      if(this.token) this.callback(this.token)
    }
  }
}
</script>