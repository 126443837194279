<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="add">
        delete
      </v-btn>
      </template>

      <div @click="dialog = false" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <Icon name="error"/>
        <div class="modal_title">
          После удаление сценарий нельзя будет восстановить
        </div>
        <div class="text">
          Вы уверены, что хотите удалить сценарий <br/> <span class="sname">«{{base_name}}»</span>?
        </div>

        <div class="btn_save add_modal">Удалить сценарий</div>
        <div class="my_btn" @click="dialog = false">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  props: ['base_name'],
  components: {
    Icon
  },
  data(){
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.text{
  margin-bottom: 16px;
}
.sname{
  color: grey;
}
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}

 .btn_save{
    border: none;
    width: 100%;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    font-style: normal;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: 19px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }
</style>