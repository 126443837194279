<template>
	<div class="forwarding">
		<h3>Блок на который будет переадресован</h3>
		<v-overflow-btn
			class="my-2"
			@input="input"
			:items="Object.values(allBlocks)"
			label="Выберите блок переадресации"
			item-value="text"
			v-model="nickForwarding"
		></v-overflow-btn>
		<div class="heading">Максимальное количество переадресаций</div>
              <div class="input_wrapper">
                <div class="minus" @click="minus"><Icon name="minus" /></div>
                <v-text-field height="32" class="mini_input" min="0" v-model="data_local.forwarding.repetitions"></v-text-field>
                <div class="plus" @click="plus" ><Icon name="plus" /></div>
              </div>
	</div>
</template>

<script>
import Icon from '@/components/SetIcon'
export default {
	name: "MyForwarding",
	components: {Icon},
	props: ['data', 'edit_callback', 'allBlocks'],
	data() {
		return {
			data_local: {
				forwarding: {
					//forwardingNickname: '',
					repetitions: 3,
					forwardingId: ''
				}
			},
			nickForwarding: ''
		}
	},
	methods: {
		input(value) {
			for (let key in this.allBlocks){
				if(this.allBlocks[key] === value){
					this.data_local.forwarding.forwardingId = key
					this.nickForwarding = this.allBlocks[key]
					console.log('Ид блока для переадресации = ' + key)
				}
			}
			this.edit_callback(this.data_local)
			return true
		},
		minus(){
			if(this.data_local.forwarding.repetitions > 1){
				this.data_local.forwarding.repetitions--
			}
		},
		plus(){
			if(this.data_local.forwarding.repetitions < 5){
				this.data_local.forwarding.repetitions++
			}
		}
	},
	watch: {
		data: function (val) {
			this.data_local = val
			this.nickForwarding = this.allBlocks[val.forwarding.forwardingId]
		}
	},
	mounted() {
		this.data_local = this.data
		this.nickForwarding = this.allBlocks[this.data.forwarding.forwardingId]
	}
}
</script>

<style scoped>
.forwarding {
	max-width: 600px;
	margin: 0 auto;
	width: 600px;
}

.heading{
  font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 120%;
text-align: left;
margin-bottom: 6px;
margin-top: 16px;
color: #404247;
}

.input_wrapper{
  max-width: 65px;
}

.input_wrapper{
  display: flex;
  align-items: center;
}

.plus{
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  cursor: pointer;
  padding: 8px;
}
.minus{
  margin-right: 6px;
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 16px 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mini_input{
  min-width: 33px;
  height: 32px;
  text-align: center;
  padding: 7px 12px;
}

@media (max-width: 650px) {
	.forwarding {
		width: 100%;
	}
}
</style>