import { render, staticRenderFns } from "./IntegrationAmoCRM.vue?vue&type=template&id=548879b8&scoped=true"
import script from "./IntegrationAmoCRM.vue?vue&type=script&lang=js"
export * from "./IntegrationAmoCRM.vue?vue&type=script&lang=js"
import style0 from "./IntegrationAmoCRM.vue?vue&type=style&index=0&id=548879b8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548879b8",
  null
  
)

export default component.exports