<template>
  <div class="call_center">
    <v-tabs  v-model="data_local.type" centered>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab active-class="active_tab">Номер</v-tab>
      <v-tab active-class="active_tab">SIP</v-tab>
    </v-tabs>
    <v-tabs-items v-model="data_local.type">
      <v-tab-item>
        <h3>Номер куда перевести</h3>
        <v-textarea
          class="my_textarea" 
          @input="input"  
          name="input-7-1"
          v-model="data_local.phone"
          auto-grow
          placeholder="+79000000000"
        ></v-textarea>
      </v-tab-item>
      <v-tab-item>
        Настроек пока нет
      </v-tab-item>  
    </v-tabs-items>
    <h3>До соединения</h3>
    <v-select
      class="selector"
      height="40"
      :items="items"
      @change="input" 
      item-value="id"
      item-text="name"
      item-color="#fee600"
      v-model="data_local.music"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: ['data','edit_callback'],
  methods:{
    input(){
      this.edit_callback(this.data_local)
      return true
    }
  },
  watch: {
    data: function (val) { 
      this.data_local=val
    }
  },
  mounted(){
    this.data_local=this.data
  },
  data(){
    return {
      items: [
        {id:"music",name:"Музыка"},
        {id:"rings",name:"Гудки"},
      ],
      model:0,
      data_local:{
        phone:'',
        music:'music'
      },
    }
  }
}
</script>

<style scoped>

.modal_header{
  display: flex;
  align-self: flex-end;
}
.call_center{
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 554px;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0 ;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.modal_title{
  margin-top: 32px;
  margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
.v-tab
{
  font-size: 17px;
  color: #2B2D33;
  text-align: left;
  padding-left:5px;
  font-style: normal;
  text-transform: none !important;
  padding-right: 20px;
  line-height: 20px;
}
@media (max-width: 650px){
  .call_center{
    max-width: 100%;
  }
}
</style>