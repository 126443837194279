<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500">
			<div @click="dialogClose()" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper">
				<slot></slot>

<!--				<div class="my_btn" @click="dialogClose()">Отменить</div>-->
				<div class="my_btn" @click="dialogClose()">Закрыть</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '@/components/SetIcon.vue'

export default {
	name: "FrameModalPopup",
	components: {
		Icon
	},
	props: ['dialogShow'],
	mounted() {
	},
	data() {
		return {
			dialog: this.dialogShow,
		}
	},

	methods: {
		dialogClose() {
			this.dialog = false
			this.$emit('close')
		},
	},
	watch: {
		dialogShow(newVal){
			this.dialog = newVal
		}
	},
	computed: {

	},
}
</script>
<style scoped>

.modal_minute_buy {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal_minute_buy__title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	color: #404247;
}

.balance_add {
	border-bottom: 1px solid #00809C;
	color: #00809C;
	cursor: pointer;
}

.message_no_buy {
	margin-bottom: 12px;
	height: 25px;
}

.message_no_buy__color {
	color: #ff5353;
}

.minute_count {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 800;
	font-size: 17px;
	color: #404247;
}

.minute_count > div {
	width: 162px;
	height: 63px;
	background: #F5F5F5;
	border-radius: 10px;
	display: block;
	font-size: 28px;
	padding: 9px;
	margin-right: 10px;
}

.modal_minute_buy__info {
	border: 1px solid #F5F5F5;
	border-radius: 10px;
	padding: 18px;
	margin: 20px 0 35px 0;
	font-size: 18px;
}

.modal_minute_buy__left {
	text-align: left;
}

.modal_minute_buy__right {
	text-align: right;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: #F5F5F5;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background:  #FEE600;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.wrapper > div {
	width: 100%;
}

.my_btn {
	text-transform: none;
	border: none;
	background: none;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
}

.add_modal {
	border: none;
	width: 100%;
	padding: 12px 20px 12px 22px;
	background: #FEE600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	justify-content: center;
	color: #2B2D33;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-style: normal;
}

.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}


</style>
