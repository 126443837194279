<template>
  <div class="sidebar">
    <div v-if="!isAdmin" class="menu">
      <router-link to="/bases">
        <Icon name="bases" :isActive="true" /> Базы номеров
      </router-link>
      <router-link to="/scripts">
        <Icon name="scripts" /> Скрипты звонков
      </router-link>
      <router-link to="/calls">
        <Icon name="calls" /> Ваши обзвоны
      </router-link>
      <!-- <router-link  to="/incoming_calls"><Icon name="your_numbers"/> Ваши номера</router-link> -->
      <router-link to="/report">
        <Icon name="analytics" /> Аналитика
      </router-link>
      <router-link to="/analytics">
        <Icon name="detail" /> Детализация
      </router-link>
      <router-link to="/integrations">
        <Icon name="integrations" /> Интеграции
      </router-link>
    </div>
    <div v-if="isAdmin" class="menu">
      <router-link v-if="isAdmin" to="/users">
        <Icon name="help" />Пользователи
      </router-link>
      <router-link v-if="isAdmin" to="/users_payments">
        <Icon name="help" />Платежи
      </router-link>
      <router-link v-if="isAdmin" to="/words">
        <Icon name="help" />Словари
      </router-link>
      <router-link v-if="isAdmin" to="/blacklist">
        <Icon name="help" />Черный список
      </router-link>
      <router-link v-if="isAdmin" to="/extra_services">
        <Icon name="help" />Доп. Услуги
      </router-link>
      <router-link v-if="isAdmin" to="/tariff_minutes">
        <Icon name="help" />Тарифы
      </router-link>
      <router-link v-if="isAdmin" to="/notifications">
        <Icon name="help" />Уведомления
      </router-link>
      <router-link v-if="isAdmin" to="/monitoring_calls">
        <Icon name="help" />Мониторинг обзвонов
      </router-link>
      <router-link v-if="isAdmin" to="/statistics">
        <Icon name="help" />Статистика
      </router-link>
    </div>
    <div class="bottom">
      <div class="exit" @click="this.logout()">
        <Icon name="exit" />
        <div>Выйти</div>
      </div>
      <div class="account left" @click="cabinet()">
        <Icon name="user" />
        <div class="name">{{$store.getters['user/getName']}}</div>
      </div>
      <div class="balance left">
        <div class="colu">
          <Icon name="wallet" />
          <div class="text_bal">Баланс:</div>
          <div class="sum">{{this.$store.getters['user/getBalance']}} ₽</div>
        </div>
        <Balance :location="'left'" />
      </div>
      <a target="_blank" href="https://crmcalls.ru/documents" class="email">Документация</a>
      <a target="_blank" href="https://crmcalls.ru/blog/algorithm-for-working-with-crmcalls" class="email">Как запустить обзвон?</a>
      <div class="text">Техническая поддержка:</div>
      <a href="tel:+79686362909" class="phone">
        <Icon name="phone" /> +7 (495) 032-00-09
      </a>
      <a href="mailto:help@crmcalls.ru" class="email">
        <Icon name="mail" /> help@crmcalls.ru
      </a>
      <div class="work_mode">
        <div>Пн-Чт 10:00 – 18:00</div>
        <div>Пт 10:00 – 17:00</div>
        <div>Сб-Вс: Выходные</div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from './SetIcon.vue'
import Balance from './Modal/Cabinet/BalanceCashUp.vue'
export default {
  data(){
    return {
      isAdmin:this.$store.getters['user/getUserFields'].is_admin
    }
  },
  components: {
    Icon, Balance
  },
  methods: {
    logout: function(){
      this.$store.commit('user/logoutUser')
    },
    cabinet(){
      this.$router.push('/')
    },
  }
}
</script>
<style scoped>
.exit{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.exit .icon{
  margin-right: 10px;
}
.balance .colu{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.account{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.account .name{
  margin-left: 10px;
}
.text_bal{
  margin-left: 10px;
  margin-right: 5px;
}
.sidebar{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  height: 100%;
}
.menu{
  margin: 2px 0 0 0;
  padding: 32px 0 0 0;
}
.menu a{
  cursor: pointer;
  list-style: none;
  padding: 12px 12px 12px 16px;
  vertical-align: middle;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  color: #404247;
  text-decoration: none;
}
.menu a.router-link-exact-active{
  background: #F4F4F4
}
.menu a.router-link-exact-active{
  color: #808185;
}
.menu a:hover{
  background: #F4F4F4;
}
.menu a .icon{
  margin-right: 8px;
}
.bottom{
  max-width: 216px;
  margin: 16px;
  display: flex;
  flex-direction: column;
}
.bottom a{
  color: #00809C;
}
.bottom .text{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #808185;
  margin-bottom: 12px;
}
.phone, .email{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.phone .icon{
  margin-right: 8px;
}
.email .icon{
  margin-right: 6px;
}
.bottom .work_mode{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  color: #808185;
}
</style>
