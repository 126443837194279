<template>
  <div class="top_header">
    <div class="left">
      <v-app-bar-nav-icon v-on:click="$emit('drawer')"></v-app-bar-nav-icon>
      <v-img class="logo" src="../assets/img/CRMСalls.svg" alt="logo"></v-img>
    </div>
    <div class="right">
      <div class="balance top">
        <div class="text">Минуты:</div>
        <div class="sum mr-4">{{this.$store.getters['user/getMinutes']}} мин.</div>
        <Icon name="wallet"/>
        <div class="text">Баланс:</div>
        <div class="sum">{{this.$store.getters['user/getBalance']}} ₽</div>
        <div class="replenishment" :class="'top'" @click="openBalanceAdd">Пополнить</div>
        <Balance :dialogstate="showBalanceAdd" :dialogclose="closeBalanceAdd" />
      </div>
      <div class="notification" :class="{'active': notifications_active}" @click="openNotificationsDialog">
  <v-menu min-width="200" max-width="1250" left nudge-left="10">
    <template v-slot:activator="{ on, attrs }">
      <div @click="openNotificationsDialog" v-bind="attrs" v-on="on">
        <Icon name="bell" />
      </div>
    </template>
    <!-- <v-list v-if="notifications_window">
      <v-list-item class="notif_item" v-for="(item, index) in notifications" :key="index">
        <Icon :name="item.icon" />
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list> -->
  </v-menu>
</div>
<v-dialog v-model="notificationsDialog" max-width="700">
    <v-card class="notification-card">
      <div>
        <v-card-title class="notification-title" style="border-bottom: 1px solid silver;">Уведомления</v-card-title>  
      </div>
      <v-card-text>
        <div style="min-height: 60px">
        <v-list>
          <v-list-item v-for="(item, index) in notifications" :key="index">
            <Icon :name="item.icon" />
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>  
        </div>
        
      </v-card-text>
      <div style="border-top: 1px solid silver;">
      <v-card-actions style="padding: 12px;">
        <v-btn @click="closeNotificationsDialog" color="#fee600" style="margin-top: 4px; margin-left: 18px;">ОК</v-btn>
      </v-card-actions>  
      </div>
      
    </v-card>
  </v-dialog>

      <div class="account top">
        <v-menu
          v-model="showMenu"
          bottom
          z-index=9999
          v-bind:balance="'url'"
          :offset-y="true"
          style="max-width: 600px;"
        >
          <template v-slot:activator="{ on, attrs}">
            <div class="login_box" v-bind="attrs" v-on="on">
              <Icon name="user" />
              <div class="name">{{$store.getters['user/getName']}}</div>
              <Icon name="dropdown"/>
            </div>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in profile_menu" :key="index"  @click="routerUser(item.namePath)">
              <Icon :name="item.icon" />
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <v-spacer/>
          </v-list>
        </v-menu>
        <Alert v-if="!alert.hidden" @click="alert.hidden = true" :obj="alert" :hidden="false" />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from './SetIcon.vue'
import Balance from './Modal/Cabinet/BalanceAdd.vue'
import Alert from '../components/custom/CustomAlert.vue'
export default {
  components: {
    Icon, Balance, Alert
  },
  emits: ['drawer'],
  methods:{
    openNotificationsDialog() {
  let self = this;
  this.$http.post("/cabinet/get_notifications", {}, (result) => {
    console.log(result.specificUser)
    self.notifications = result.specificUser.map((item) => {
      return { title: item, icon: 'inform' };
    });
    self.notifications_active = result.specificUser.length > 0;
    self.notifications_window = result.specificUser.length > 0;
  });
  this.notificationsDialog = true;
},

  closeNotificationsDialog() {
    this.$http.post("/cabinet/delete_notifications", {}, () => {
  });
    this.notificationsDialog = false;
  },
    openBalanceAdd(){
      this.showBalanceAdd = true;
    },
    closeBalanceAdd(){
      this.showBalanceAdd = false
    },
    router(path){
      this.$router.push(path)
    },
	routerUser(namePath){
		if(namePath === 'exit'){
			this.$store.commit('user/logoutUser')
		} else {
			this.$router.push({ name: namePath })
		}
	},
  },
  data(){
    return {
      showBalanceAdd: false,
      showSuccessBalance: false,
      notificationsDialog: false,
      alert: {
        // inform, alert, green_ok, attention, blue_inform
        type: 'alert',
        heading: 'Neutral notification',
        text: 'Subtitle text or explanation goes here',
        hidden: false
      },
      showMenu:false,
      balance: -100,
      profile: '+7 111 111-11-11',
      profile_menu: [
        {title: 'Личный кабинет', icon: 'user', namePath: 'cabinet'},
        {title: 'Настройки аккаунта',  icon: 'user', namePath: 'user_settings'},
        {title: 'Выход', icon: 'exit', namePath: 'exit' }
      ],
      notifications: [
      ],
      notifications_active:false,
      notifications_window:false
    }
  },
//   watch: {
//   '$store.state.count_notifications'(newVal) {
//     if (newVal > 0) {
//       this.notifications_window = true;
//       this.openNotificationsDialog();
//     }
//   }
// },
  mounted() {
  let _this = this;

  setInterval(() => {
    let self = _this;
    this.$http.post("/cabinet/get_user_state", {}, result => {
      self.notifications_active = result.count_notifications > 0;

      if (result.count_notifications > 0) {
        self.notifications_window = true;
          this.openNotificationsDialog();
      }

      if (result.balance) {
        self.$store.commit('user/setBalance', result.balance);
      }

      if (result.minutes) {
        self.$store.commit('user/setMinutes', result.minutes);
      }
    });
  }, 5000);
},
}
</script>
<style scoped>
.v-sheet div
{
  cursor:pointer;
}
.logo{
  width: 128px;
  height: 23px;
  margin-left: 30px;
  cursor: none;
}
.left{
  display: flex;
  align-items: center;
}
.notif_item{
  padding: 8px 15px;
}
.v-list-item__title{
  text-align: left!important;
  margin-left: 11px;
}
.login_box
{
display:flex;
align-items:center;
}
.top_header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px 12px 20px;
  position: relative;
}
.right{
  display: flex;
}
.balance{
  display: flex;
  align-items: center;
}
.dropdown{
  background: url('../assets/img/dropdown.svg');
  width: 12px;
  margin-left: 7px;
  cursor: pointer;
  margin: 8px;
  height: 8px;
}
.notifications_unactive:after{
  display:none !important;
}
.text{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
  margin-left: 7px;
}
.sum{
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  align-items: center;
  text-align: center;
  color: #2B2D33;
  margin-left: 4px;
}

.notification{
  margin-right: 32px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.notification.active::after{
  position: absolute;
  top: 7px;
  border-radius: 50%;
  right: 0;
  width: 8px;
  height: 8px;
  content: '';
  display: block;
  background: #EE505A;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}
.account{
  display: flex;
  align-items: center;
}
.account .icon{
  margin: 8px;
}
.replenishment{
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  padding: 7px 17px;
  align-items: center;
  text-align: center;
  color: #2B2D33;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 35px;
  cursor: pointer;
}

.notification-card {
  /* background-color: green; */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.notification-title {
  color: #2B2D33;
}

.notification-card .v-btn {
  color: #2B2D33;
}

.notification-card .v-btn:hover {
  color: #2B2D33;
}

.notification-card .v-btn:active {
  color: #2B2D33;
}

.notification-card .v-btn--active {
  color: #2B2D33;
}
</style>
