import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3040/api/' : 'https://dev.crmcalls.ru/api/' 
//'https://dev.crmcalls.ru/api/'
// ''http://localhost:3040/api/' 

class Http {
  constructor (store) {
    this.store = store
    this.axios = axios.create({
      baseURL
    })
  }
  test () {
    alert("test")
  }
  post (url, data, cb) {
    if (localStorage.token) {
      data.token = localStorage.token
    }

    this.axios.post(url, data)
      .then((res) => {
        cb(res.data)
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          cb("error")
        }
      })
  }

  /** 
  * @function upload
  * @param {String} url - Адрес для запроса
  * @param {Object} formData - Объект хранения файлов
  * @param {Object} data - Параметры запроса
  */
  async upload (url, formData, data) {
    if (localStorage.token)
      data.headers.token = localStorage.token   // Добавление токена в заголовки
    return await this.axios.post(url, formData, data)
  }

  /** 
  * Отправка GET запроса
  * @function get
  * @param {String} url - Адрес запроса
  * @param {Object} params - Параметры запроса
  */
  async get (url, params) {
    let token
    if (localStorage.token)
      token = localStorage.token
    params = params ? JSON.stringify(params) : '' // Установка токена
    url = `${ baseURL }${ url }?token=${ token }`
    if (params) url += `&params=${ params }`
    return await this.axios.get(url)
  }

  /** 
  * Создание экземпляра отслеживания sse
  * @function eventSource
  * @param {String} url - Ссылка на запрос
  */
  eventSource (url, params) {
    let token
    if (localStorage.token)
      token = localStorage.token
    params = params ? JSON.stringify(params) : '' // Установка токена
    url = `${ baseURL }${ url }?token=${ token }`
    if(params) url += `&params=${ params }`
    return new EventSource(url)
  }
  

  /** 
  * Отправка GET запроса
  * @function download
  * @param {String} url - Адрес запроса
  * @param {Object} params - Параметры запроса
  */
  async download (url, params) {
    const getData = async () => {
      return new Promise((resolve) => {
        fetch(url).then(res => res.blob())
          .then(blob => {
            const file = window.URL.createObjectURL(blob)
            window.location.assign(file)
            resolve(true)
          })    
      })
    }
    let token
    if (localStorage.token)
      token = localStorage.token
    params = params ? JSON.stringify(params) : '' // Установка токена
    url = `${ baseURL }${ url }?token=${ token }`
    if (params) url += `&params=${ params }`
    return await getData()
  }

}

export const http = Http
