<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500">
			<div @click="dialogClose()" class="close">
				<Icon name="close"/>
			</div>
			<v-card class="wrapper" v-if="complete">
				<div class="modal_title">
					В течении минуты тариф будет активирован!
				</div>
<!--				<div class="input_wrapper">-->
<!--					В течении минуты тариф будет активирован!-->
<!--				</div>-->
			</v-card>
			<v-card class="wrapper" v-if="!complete">

				<div class="modal_minute_buy mb-10 mt-1">
					<div class="modal_minute_buy__title">
						Купить:
					</div>
					<div class="minute_count">
<!--						<input v-model="slider"> мин-->
						<div>{{ numberWithSpaces(selectedMinutes) }}</div> мин.
					</div>
				</div>
				<div class="input_wrapper">
					<input type="number" v-model="selectedMinutes" placeholder="Введите количество минут" v-on:input="limitInput">
</div>
				<!-- <input type="range" :min="0" :max="this.tariffs.length - 1" v-model="slider" class="slider"> -->
				<div class="modal_minute_buy modal_minute_buy__info">
					<div class="modal_minute_buy__left">
						Стоимость:
						<div><span class="modal_minute_buy__title">{{ blabla.pricePerMinute}}</span> ₽ / мин.</div>
					</div>
					<div class="modal_minute_buy__right">
						Со счета будет списано:
						<div class="modal_minute_buy__title">{{ totalCost }} ₽</div>
					</div>
				</div>
				<div
  v-if="selectedMinutes < 1 || selectedMinutes > 9999999"
  style="font-size: 14px; color: #555; margin-top: 5px;"
>
  Пожалуйста, введите сумму от 1 до 10 000 000
</div>
				<div class="message_no_buy" >
					<div class="message_no_buy__color" :style="{'display': buyError ? 'block' : 'none' }" >Недостаточно средств на счету!
						<span @click="addBalance()" class="balance_add"> Пополнить</span>
					</div>
				</div>


				<button class="my_btn add_modal" @click="sendForm()" :disabled="selectedMinutes < 1 || selectedMinutes >= 10000000">
  Купить
</button>
				<div class="my_btn" @click="dialogClose()">Отменить</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Icon from '../../SetIcon.vue'

export default {
	name: "BuyMinutes",
	components: {
		Icon
	},
	props: ['dialogShow'],
	mounted() {
    this.$http.post("/tariffs_minutes/list_tariffs", {}, (result) => {
        if (result) {
            this.tariffs = result.map(tariff => {
                return {
                    minAmount: tariff.minAmount,
                    maxAmount: tariff.maxAmount,
                    pricePerMinute: tariff.pricePerMinute
                };
				
            });
        }
    });
},
	data() {
		return {
			dialog: this.dialogShow,
			complete: false,
			//valError: false,
			tariffs: [
			],
			limit: 10,
			selectedMinutes: 0,
			numberTariff: 0,
			customAmount: 0,
			blabla: 0,
			foundTariff: null,
			balanceUser: this.$store.getters['user/getBalance']
		}
	},

	methods: {
		limitInput: function() {
      if (this.selectedMinutes.length > 8) {
        this.selectedMinutes = this.selectedMinutes.slice(0, 8);
      }
    },

		dialogClose() {
			this.$emit('close')
			setTimeout(() => this.complete = false , 500)
		},
		sendForm(){
			this.$http.post("/cabinet/add_tarif_transaction",
				{
					tarif_id:this.slider,
					minutesAmount: this.selectedMinutes,
					totalCost: this.totalCost
				}, (result) => {

					if (result) {
						this.complete = true
					}else{
						//НЕ ХВАТАЕТ БАБЛИШКА
					}
				})
		},
		addBalance(){
			//this.complete = true
			this.dialogClose()
			this.$emit('addBalance')
		},
		numberWithSpaces(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		}
	},
	watch: {
		selectedMinutes(newVal) {
// 			if (this.selectedMinutes < 1 || this.selectedMinutes > 9999999) {
// 				this.$notify.error('Введите число от 1 до 9999999');
//     return;
//   }
			if (newVal >= 0) {
        this.foundTariff = this.tariffs.find(tariff => newVal >= tariff.minAmount && newVal <= tariff.maxAmount);
        if (this.foundTariff) {
			
            this.totalCost = newVal * this.foundTariff.pricePerMinute;
        } else {
           this.totalCost = 0;
        }
    } else {
        this.totalCost = 0;
    }
		},
		foundTariff(newVal) {
		this.blabla = newVal;
		},
		dialogShow(nowValue) {
			this.dialog = nowValue
		},

	},
	computed: {
		buyError(){
			if(+this.balanceUser > 0){
				let numBalance = Number(this.balanceUser.split(' ').join(''))
				return numBalance < (this.selectedMinutes * this.tariffs[this.slider].pricePerMinute);
			} else {
				return true
			}
		}
	},
}
</script>
<style scoped>
.modal_minute_buy {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal_minute_buy__title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	color: #404247;
}

.balance_add {
	border-bottom: 1px solid #00809C;
	color: #00809C;
	cursor: pointer;
}

.message_no_buy {
	margin-bottom: 12px;
	height: 25px;
}

.message_no_buy__color {
	color: #ff5353;
}

.minute_count {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 800;
	font-size: 17px;
	color: #404247;
	word-wrap: break-word;
	
}

.minute_count > div {
	width: 162px;
	height: 63px;
	background: #F5F5F5;
	border-radius: 10px;
	display: block;
	font-size: 28px;
	padding: 9px;
	margin-right: 10px;
}

.modal_minute_buy__info {
	border: 1px solid #F5F5F5;
	border-radius: 10px;
	padding: 18px;
	margin: 20px 0 35px 0;
	font-size: 18px;
}

.modal_minute_buy__left {
	max-width: 150px;
  word-wrap: break-word;
	text-align: left;
}

.modal_minute_buy__right {
	max-width: 150px;
  word-wrap: break-word;
	text-align: right;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: #F5F5F5;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background:  #FEE600;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.wrapper > div {
	width: 100%;
}

.my_btn {
	text-transform: none;
	border: none;
	background: none;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
}

.add_modal {
	border: none;
	width: 100%;
	padding: 12px 20px 12px 22px;
	background: #FEE600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	justify-content: center;
	color: #2B2D33;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-style: normal;
}

.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}

.input-wrapper {
  margin-bottom: 15px; /* Добавить отступ между элементами */
}

input[type="number"] {
  padding: 10px; /* Больший внутренний отступ для улучшения внешнего вида */
  width: 100%; /* Ширина поля ввода равна 100% от родительского элемента*/
  border: 1px solid #ccc; /* Добавить рамку для поля ввода */
  border-radius: 5px; /* Добавить закругление углов для поля ввода */
  box-sizing: border-box; /* Учесть границу и отступ при задании ширины поля ввода */
}
</style>
