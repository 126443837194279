<template>
  <div>
    <div v-if="!file" class="file-uploader" @click="selectFile">
      <div class="upload-container" @dragover.prevent @drop="handleDrop">
        <div>
          <input ref="selectInput" type="file" name="file" style="display: none;" @change="changeInput" multiple
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv" />
          <label>Выберите файл или перетащите его сюда</label>
        </div>
      </div>
    </div>
    <div v-if="file && !header.status && percent < 100">
      <label for="progress-bar">Статус загрузки файла : {{ percent }}%</label>
      <progress id="progress-bar" :value="percent" max="100" />
    </div>
    <div v-if="file && !header.status && percent === 100" class="w-100">
      <h4>Получение колонок файла</h4>
      <div class="progress-wrap progress">
        <div class="preloader progress">
          <div class="gradient progress" />
        </div>
      </div>
    </div>
    <div class="p-2" v-if="file && header.status && percent === 100 && indexColumnPhone === null">
      <p>Выберите поле, в котором указаны номера телефонов</p>
      <v-select height="40" hide-details item-color="#fee600" :items="header.columns" item-value="index" v-model="indexColumnPhone" item-text="value" solo />
    </div>
    <div v-if="file && header.status && percent === 100 && indexColumnPhone !== null  && !isSave" class="p-2">
      <h4>Выберите колонки для загрузки</h4>
      <div class="list-columns">
        <div v-for="col in header.columns" :key="col.index" class="item-list-columns">
          <input type="checkbox" v-model="col.isSelect" @change="onCustomColumns">
          <span class="p-2">{{ col.value }}</span>
        </div>
      </div>
    </div>
    <div v-if="isSave">
      <label for="progress-bar">Статус загрузки данных : {{ percentLoadColumn }}%</label>
      <progress id="progress-bar" :value="percentLoadColumn" max="100" />
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  props: {
    /** Адрес для загрузки файла  */
    url: {
      type: String,
      default: null
    },
    splitBase: {
    type: Boolean,
    default: false
  },
  splitCount: {
    type: Number,
    default: 1
  },
    isUnique: {
      type: Boolean,
      default: false
    }, // Признак указывает, что будут добавляться только уникальные номер
    name: { // Имя обзвона
      type: String,
      default: null
    },
    unique: { // Признак указывающий на загрузку только уникальных номеров
      type: [ Number, Boolean ],
      default: 0
    },
    params: { // Параметры обзвона
      type: Object,
      default: null
    },
    isSave: { // Признак нажатия кнопки "Добавить в базу"
      type: Boolean,
      default: false
    },
    percentLoadColumn: { // Процент статуса загрузки колонок
      type: Number,
      default: 0
    },
    clearFile: { // Очистка данных файла
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      file: null, // Выбранный файл
      percent: 0, // Процент загрузки
      header: {
        status: false, // Статус загрузки файла
        columns: [] // Список колонок  
      },
      indexColumnPhone: null, // Значение колонки номера телефона
      showCustomColumns: false, // Отображение блока добавления других колонок
      data: { // Колонки для добавления
        columns: [], // Дополнительные колонки
        phone: {}, // Данные колонки с номером телефона 
        fileParams: {}, // Данные о файле на сервере
        rowCount: 0, // Количество строк
        name: null // Имя обзвона
      }
    }
  },


  methods: {
    /** 
    ** Формирование параметров запроса
    * @function getParamsRequest
    */

    async handleDrop(event) {
    event.preventDefault();
    this.file = event.dataTransfer.files[0];
    // this.file = event.target.files && event.target.files.length ? event.target.files[ 0 ] : null // Выбор файла
      const formData = new FormData() // Данные для передачи на сервер
      formData.append('file', this.file) // Добавление данных о файле
      if (this.file) {
        const { onUploadProgress, $http } = this
        const options = {
          name: this.name,
          splitBase: this.splitBase,
          splitCount: this.splitCount,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress // Процесс отображения загрузки
        }
        const res = await $http.upload("/upload/file", formData, options)
        if (res && res.data && res.data.headers) {
          const { headers, fileParams } = res.data
          this.data.fileParams = fileParams // Установка свойств о данных файла
          this.data.rowCount = headers.rowCount // Установка количества строк
          if (headers.status)
            this.header.status = true  // Установка статуса получения колонок
          if (headers.columns.length)
            this.header.columns = res.data.headers.columns // Установка значения колонок
          else alert('Не найдены данные в файле')
        }
      }
  },

    getParamsRequest () {
      const { params, unique, name } = this
      this.data.name = name // Присвоение имени базы номеров
      this.data.unique = unique // Признак уникальных номеров 
      this.data.params = params // Параметры обзвона
    },

    /**
    ** Отправка данных о колонках, при клике на чекбокс
    * @function onCustomColumns
    */
    onCustomColumns () {
      this.getParamsRequest() // Получение параметров запроса
      this.data.columns = this.header.columns.filter(el => el.isSelect) // Получение кастомных колонок для передачи на другую форму
      this.$emit('columns', this.data) // Отправка события с данными колонки номера телефона
    },

    /** 
    ** Отображение блока добавления дополнительных колонок
    * @function showBlockCustomColumns
    */
    showBlockCustomColumns () {
      this.showCustomColumns = true
    },

    /**
    ** Преобразование файла в формат Base64
    * @function getBase64
    * @param {Function} callback - Функция обработки преобразованного файла
    */
    getBase64 (callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(this.file)
    },

    /** 
    ** Получение статуса загрузки
    * @function uploadProgress
    * @param {Object} event - Объект события
    */
    onUploadProgress (event) {
      this.percent = Math.round((100 * event.loaded) / event.total) || 0
    },

    /**
    ** Событие срабатывает при выборе файла
    * @function changeInput
    * @param {Object} event - Объект события
    */
    async changeInput (event) {
      this.file = event.target.files && event.target.files.length ? event.target.files[ 0 ] : null // Выбор файла
      const formData = new FormData() // Данные для передачи на сервер
      formData.append('file', this.file) // Добавление данных о файле
      if (this.file) {
        const { onUploadProgress, $http } = this
        const options = {
          name: this.name,
          splitBase: this.splitBase,
          splitCount: this.splitCount,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress // Процесс отображения загрузки
        }
        const res = await $http.upload("/upload/file", formData, options)
        if (res && res.data && res.data.headers) {
          const { headers, fileParams } = res.data
          this.data.fileParams = fileParams // Установка свойств о данных файла
          this.data.rowCount = headers.rowCount // Установка количества строк
          if (headers.status)
            this.header.status = true  // Установка статуса получения колонок
          if (headers.columns.length)
            this.header.columns = res.data.headers.columns // Установка значения колонок
          else alert('Не найдены данные в файле')
        }
      }
    },

    /** 
    ** Выбор файла
    * @function selectFile
    */
    selectFile () {
      this.$refs.selectInput.click() // Открытие окна выбора файла 
    }
  },

  watch: {
    /** 
    ** Отслеживание изменений в заголовке 
    * @function handler
    */
/*     header: {
      handler (newVal) {
        if (newVal.status && newVal.columns.length === 1)
          this.indexColumnPhone = 0

      },
      deep: true
    }, */

    /** 
    ** Отслеживание признака очистки файла
    * @function clearFile
    * @parm {Boolean} newVal - Новое значение  
    */
    clearFile (newVal) {
      if (newVal) {
        this.file = null // Выбранный файл
        this.percent = 0 // Процент загрузки
        this.header = {
          status: false, // Статус загрузки файла
          columns: [] // Список колонок  
        }
        this.indexColumnPhone = null // Значение колонки номера телефона
        this.showCustomColumns = false // Отображение блока добавления других колонок
        this.data = { // Колонки для добавления
          columns: [], // Дополнительные колонки
          phone: {}, // Данные колонки с номером телефона 
          fileParams: {}, // Данные о файле на сервере
          rowCount: 0, // Количество строк
          name: null // Имя обзвона
        }
        this.$emit('clear-file', false)
      }
    },

    /** 
    ** Отслеживание изменения индекса элемента в выпадающем поле выбора колонки номера телефона
    * @function indexColumnPhone
    */
    indexColumnPhone: function () {
      this.getParamsRequest() // Получение параметров запроса
      const { indexColumnPhone } = this // Значение индекса выделенного элемента
      const index = this.header.columns.findIndex(el => el.index === indexColumnPhone) // Получение индекса элемента в массиве
      if (index >= 0) {
        if (this.header.columns.length > 1) {
          this.data.phone = this.header.columns.splice(indexColumnPhone, 1)[ 0 ] // Удаление из списка колонок  
        }
        else if (this.header.columns.length === 1)
          this.data.phone = this.header.columns.slice(indexColumnPhone, 1)[ 0 ] // Удаление из списка колонок 
      }
      if (this.data.phone.value) this.$emit('columns', this.data) // Отправка события с данными колонки номера телефона
    },

  }
}
</script>

<style scoped>
.p-2 {
  padding: 2%;
}

.w-100 {
  width: 100%
}

.file-uploader {
  display: flex;
  padding: 1em;
  width: 100%;
  justify-content: center;
}

.upload-container:hover {
  background: #e8eff1;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  outline: 2px dashed #5d5d5d;
  outline-offset: -12px;
  background: #e0f2f7;
  font-family: "Segoe UI";
  color: #1f3c44;
  cursor: pointer;
}

.upload-container img {
  width: 40%;
  margin-bottom: 20px;
  user-select: none;
}

.upload-container label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.upload-container div {
  position: relative;
  z-index: 10;
}

.upload-container input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;
}

.upload-container label.focus {
  outline: 1px solid #0078d7;
  outline: -webkit-focus-ring-color auto 5px;
}

.upload-container.dragover {
  background-color: #fafafa;
  outline-offset: -17px;
}

.submit {
  display: none;
}

progress {
  border-radius: 7px;
  width: 100%;
  height: 22px;
}

progress::-webkit-progress-bar {
  background-color: white;
  border-radius: 7px;
  border: 2px solid silver;
}

progress::-webkit-progress-value {
  background-color: #fee600 !important;
}

.progress {
  height: 25px;
}

.progress-wrap {
  width: 100%;
  background: white;
  margin-top: 2%;
  margin-bottom: 2%;
  overflow: hidden;
  position: relative;
  border: solid 1px black;
  border-radius: 4px;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  background-color: #fee600;
  border-radius: 4px;
  overflow: hidden;
  animation: preloader-move 2000ms linear infinite;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-size: 32px 32px;
  animation: preloader-motion 500ms linear infinite;
}

@keyframes preloader-motion {
  0% {
    transform: none;
    transform: none;
  }

  100% {
    transform: translate(-32px, 0);
    transform: translate(-32px, 0);
  }
}

@keyframes preloader-move {
  0% {
    left: 0;
    left: 0
  }

  100% {
    left: 100%;
    left: 100%
  }
}

.list-columns {
  border: 1px solid silver;
  width: 100%;
  height: 250px;
  max-height: 250px;
  overflow-y: auto
}

.item-list-columns {
  padding: 4px;
  width: 100%;
  display: flex;
  justify-items: start;
  border-bottom: 1px solid silver
}
</style>