<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="560" >
      <div @click="close" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper" v-if="call">
        <div class="modal_title" v-if="name">
          Параметры: <span class="namer">{{name}}</span>
        </div>

        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Статус:</div>
          <div class="value">{{rusStatus(call.status)}}</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Очередь:</div>
          <div class="value">{{call.queue}} номеров</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Сценарий обзвона: </div>
          <div class="linker">{{call.scenarium}}</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Группа контактов: </div>
          <div class="linker">{{call.group}}</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Количество номеров:</div>
          <div class="value">{{call.nums}} номеров</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Количество дозвонов:</div>
          <div class="value">{{call.calls_success}} состоявшихся разговоров</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Процент дозвонов:</div>
          <div class="value">{{call.percent}}%</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Время общения:</div>
          <div class="value">{{call.time}} минут</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Стоимость:</div>
          <div class="value">{{call.cost}}₽</div>
        </div>
        <v-divider></v-divider>
        <div class="rower">
          <div class="heading">Аналитика: </div>
          <div class="linker"><router-link :to="'/analytics#'+this.guid">Смотреть подробную статистику</router-link></div>
        </div>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['show_dialog','callback_refresh','name','guid'],
  watch: {
    show_dialog: function (val) {
      this.dialog=val
      this.renderData()
    }
  },
  methods: {
    rusStatus(status)
    {
      let result="В процессе"
      switch (status) {
  case "wait":
    result="В процессе"
    break;
  case "success":
    result="Завершен"
    break;
  case "stop":
    result="Остановлен"
    break;
    }
    return result
    },
    renderData()
    {
      if (this.guid)
      { 
        this.$http.post("/call/get",
        {
          id:this.guid,
        },(data)=>{
        this.call=data
        })
      }
    
    },
    close(render=false)
    {
      this.dialog=false
      this.call=false
      this.callback_refresh(render)
    },
  },
  data(){
    return {
      call:false,
      dialog: false
    }
  }
}
</script>
<style scoped>
.rower{
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
}

.modal_title{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #808185;
  margin-bottom: 24px;
}
.namer{
  color: #2B2D33;
  font-weight: 800;
}
.value{
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  text-align: left;
}
.linker{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  line-height: 120%;
  color: #00809C;
  text-align: left;
}
.wrapper{
  padding: 32px;
}
.heading{
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  color: #808185;
  margin-right: 8px;
  text-align: left;
}
@media (max-width: 600px){
  .rower{
    flex-direction: column;
  }
}
</style>