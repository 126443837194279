<template>
	<div class="wraper">
		<h3>Входящий вебхук</h3>
		<v-overflow-btn
				class="my-3 "
				@input="type_integration"
				:items="arr_type"
				label="Вид входящего вебхука"
				height="50"
				item-value="type"
				item-text="name"
				v-model="data_local.bitrix.type"
				hide-details
		></v-overflow-btn>
		<div v-if="data_local.bitrix.type">
			<div class="subtitle">Rest api</div>
			<v-text-field
					hide-details
					height="50"
					v-model="data_local.bitrix.token"
					@input="input"
					placeholder="url"
			></v-text-field>
		</div>

		<div v-if="data_local.bitrix.type === arr_type[0].type || data_local.bitrix.type === arr_type[1].type">
			<div class="subtitle mt-3">Параметры</div>
			<div v-for="(option, key) of data_local.bitrix.options" :key="key" class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="option.readonly"
						hide-details
						height="40"
						v-model="option.option"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-text-field
							:readonly="option.readonly"
							class="ml-5 "
							hide-details
							height="40"
							v-model="option.value"
							@input="input"
							placeholder="value"
					></v-text-field>
					<div v-if="!option.required" class="delete_icon" @click="deleteOption(key)">
						<Icon name="delete"/>
					</div>
				</div>
			</div>
		</div>

		<div class="add_pram" v-if="data_local.bitrix.type === arr_type[0].type || data_local.bitrix.type === arr_type[1].type">
			<v-overflow-btn
					class="ma-0 pa-0 block_input_param__options mr-4"
					:items="optionsFilter"
					label="Параметр"
					height="40"
					item-value="text"
					v-model="addOption"
					hide-details
			></v-overflow-btn>
			<Icon name="blue_plus"/>
			<div class="add_pram__btn" @click="add_option()">Добавить параметр</div>
		</div>

	</div>
</template>

<script>
import Icon from "@/components/SetIcon";
export default {
	name: "IntegrationBitrix24",
	props: ['data', 'edit_callback'],
	components: { Icon },
	data() {
		return {
			data_local: {
				bitrix: {
					type: '',
					token: '',
					options: [],
				}
			},
			arr_type: [
				{name: 'Создание карточки', type: 'leadAdd'},
				{name: 'Перемещение карточки', type: 'leadMove'},
			],
			arr_options: {
				'Phone': {option: 'PHONE', value: '{{ phone }}', readonly: true, required: false},
				'Title': {option: 'TITLE', value: '', readonly: false, required: false},
				'Stage ID': {option: 'STAGE_ID', value: '', readonly: false, required: false},
				'Lead ID': {option: 'LEAD_ID', value: '', readonly: false, required: false},
				'Свое поле': {option: '', value: '', readonly: false, required: false},
			},
			addOption: '',
		}
	},
	computed: {
		optionsFilter(){
			let userOption = []
			for (let option of this.data_local.bitrix.options){
				userOption.push(option.option)
			}
			let allOptions = Object.keys(this.arr_options)

			if (userOption.length) {
				for (let use of userOption) {
					let idx = allOptions.findIndex((el) => {
						if (el !== 'Свое поле') {
							// console.log('совпадение', el)
							return el.toLowerCase() === use.toLowerCase()
						} else {
							// console.log('Найдено Свое поле')
							return false
						}
					})

					if (idx > -1) {
						allOptions.splice(idx, 1)
					}
				}
			}
			return allOptions
		}
	},
	methods: {
		input() {
			this.edit_callback(this.data_local)
			return true
		},
		type_integration(){
			switch(this.data_local.bitrix.type){
				case 'leadAdd':
					this.data_local.bitrix.options = [
						{option: 'PHONE', value: '{{ phone }}', readonly: true, required: true},
						{option: 'TITLE', value: '', readonly: false, required: true}
					]
					break;

				case 'leadMove':
					this.data_local.bitrix.options = [
					{ option: 'LEAD_ID', value: '{{ lead_id }}', readonly: true, required: true, skip: true }
					]
					break;

				default:
					this.data_local.bitrix.options = []
					break;
			}
		},
		add_option(){
			if (this.addOption){
				this.data_local.bitrix.options.push({...this.arr_options[this.addOption]})
				this.addOption = ''
			}
		},
		deleteOption(key){
			this.data_local.bitrix.options.splice(key, 1)
		}
	},
	watch: {
		data: function (val) {
			this.data_local = val
		}
	},
	mounted() {
		this.data_local = this.data
	}
}
</script>

<style scoped>
.wraper {
    max-width: 600px;
    margin: 0 auto;
    width: 600px;
}

.add_pram {
	display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
	margin-bottom: 40px;
}

.add_pram__btn {
	color: #00809C;
    margin-left: 10px;
}

.delete_icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    border: 1px solid #FEE600;
    border-radius: 8px;
    display: flex;
}

.block_input_param {
	display: flex;
    margin-bottom: 15px;
}

.block_input_param__value {
    width: 61%;
	display: flex;
}
.subtitle {
	text-align: left;
    padding-left: 8px;
}

@media (max-width: 650px) {
    .wraper {
        width: 100%;
    }
}
</style>
