<template>
  <span style="font-size: 13px; color: gray ">
  {{ text }}
  </span>
</template>

<script>
export default {
  props: [ 'text' ]
}
</script>

<style>
</style>