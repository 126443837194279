<template>
  <div class="target">
    <h3>Название цели</h3>
<!--    <v-textarea-->
<!--      class="my_textarea" -->
<!--      @input="input" -->
<!--      name="input-7-1"-->
<!--      v-model="data_local.name"-->
<!--      auto-grow-->
<!--      placeholder=""-->
<!--    ></v-textarea>-->
	<v-text-field
		class="my_textarea"
		@input="input"
		name="input-7-1"
		v-model="data_local.name"
		placeholder="">
	</v-text-field>
  </div>
</template>

<script>
export default {
  props: ['data','edit_callback'],
  methods:{
    input()
    {
        this.edit_callback(this.data_local)
        return true
    }
  },
  watch: {
    data: function (val) { 
      this.data_local=val
    }
  },
  mounted()
  {
    this.data_local=this.data
  },
  data(){
    return {
      model:0,
      data_local:{
        name:''
      },
    }
  }
}
</script>

<style scoped>
.target
{
width: 100%;  
}
.modal_header{
display: flex;
align-self: flex-end;
}
.target{
  max-width: 600px;
  margin: 0 auto;
  width: 600px;
}
.modal_body{
display: flex;
justify-content: center;
flex-direction: column;
width: 554px;
}
.save{
  padding: 10px 20px 12px;
background: #FEE600;
border-radius: 8px;
margin: 16px 0 0 0 ;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 12px 0 0 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 110%;
color: #2B2D33;
}
.modal_title{
margin-top: 32px;
margin-bottom: 32px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
@media (max-width: 650px){
  .target{
    width: 100%;
  }
}
</style>
