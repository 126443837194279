<template>
  <div class="render">
    <v-breadcrumbs class="bc" v-if="!if_main" :items="breadcrumbs" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          @click="returnMain('index')"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="top">
      <h1>{{current_integration.title}}</h1>
      <create-parent v-if="!if_main && current_integration.title != 'AmoCRM'" :show_dialog="this.edit_popup" :data="this.edit_data" :callback_refresh="renderData" :type="this.current_integration.title"/>
    </div>
    <div class="text" v-if="!if_main">
      <p v-for="(item, i) in current_integration.paragraph" :key="i" v-html="item"></p>
    </div>
    <div v-if="if_main" class="content_table">
      <Card v-for="(item, i) in cards" :key="i" :item="item" :title="i" :func="item.click || changeInnerUrl" />
    </div>
    <div >
      <Table @edit="editDataEmit" @delete="deleteData" :page_type="'integration'" class="table_integrations" :callback_delete="deleteData" :callback_edit="editData" :items="action_items"  :server="false" :perPage="10"  :callback_refresh="renderData" :loading="loading" v-if="!if_main" :data="current_integration.data" :headers="current_integration.headers" />
    </div>

    <div v-if="current_integration.title == 'AmoCRM'">
      <br>
    <h2 class="ta_left_v">Добавить соединение</h2>
      <br>
    <v-text-field v-model="amo_data.name" label="Название интеграции"></v-text-field>
    <br>
    <v-text-field v-model="amo_data.amourl" label="URL вашей amoCRM"></v-text-field>
    <br>
    <v-text-field v-model="amo_data.client_id" label="ID интеграции"></v-text-field>
    <br>
    <v-text-field v-model="amo_data.client_secret" label="Секретный ключ"></v-text-field>
    <br>
    <v-text-field v-model="amo_data.redirect_uri" label="URL редиректа указаный при создании интеграции"></v-text-field>
    <br>
    <v-textarea v-model="amo_data.code" label="Код авторизации"></v-textarea>
    <br>
    <v-text-field v-model="amo_data.long_term_token" label="Долгосрочный токен"></v-text-field>
    <br>
    <v-btn
      depressed
      color="#FEE600"
      @click="registr_amo()"
    >Добавить соединение</v-btn>

    <br>
    <div v-if="amo_succes.visible" class="">
      {{amo_succes.massage}} <br>
      {{amo_succes.token}}
    </div>
    </div>
    <div class="bottom" v-if="if_main">
      Если у вас есть особый запрос пишите на почту:
      <a href="mailto:help@crmcalls.ru">help@crmcalls.ru</a>.
      Возможно, мы сделаем интеграцию индивидуально для вас.
    </div>
  </div>
</template>
<script>
import Card from '../components/Integrations/CardIntegration.vue'
import Table from '../components/Table/DataTable.vue'
import CreateParent from '../components/Modal/Integrations/CreateParent.vue'

export default {
  components: {
    Card, Table,  CreateParent
  },
  data(){
    return {
      testtest:{},
      amo_list:{},
      amo_succes:{
        visible:false,
        massage:"",
        token:""
      },
      amo_data:{
        name:"",
        amourl:"",
        client_id:"",
        client_secret:"",
        code:"",
        redirect_uri:"",
        long_term_token: "",
        
      },
      if_main: true,
      loading:true,
      edit_popup:false,
      integration_name:false,
      edit_data:{},
      current_integration: {},
      action_items: [
          {title: 'Редактировать',action:'edit'},
          {title: 'Удалить',action:'delete'},
        ],
      default: {
        title: 'Интеграции',
        paragraph :[],
        data: [],
        headers: [],
      },
      cards: {
        'Webhook': {
          edit_popup:false,
          in_dev: false,
          title: 'Webhook',
          text: 'Запускайте звонки из других сервисов автоматически',
          icon: 'webhook',
          paragraph: [
            'Легкий способ запуска звонков в Конструкторе из вашего сервиса. Получите ссылку, указав сценарий и какие параметры вы будете передавать.',
            'Так, например, можно интегрировать роботов с вашей CRM: когда вы переместите карточку клиента в воронке, отправится webhook с нужными данными для совершения звонка.'
          ],
          data: [
          ],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Тип запроса', value: 'method' },
            { text: 'URL', value: 'url' },
            { text: 'Действия', value: 'actions', sortable: false }
          ],
        },
        // 'Tilda': {
        //   edit_popup:false,
        //   in_dev: true,
        //   title: 'Tilda',
        //   text: 'Интегрируйте робота с сайтом и автоматически запускайте звонки',
        //   icon: 'tilda',
        //   paragraph: [
        //     'Легкий способ запустить звонок прямо из форм сайта Tilda. Получите ссылку в особом формате Tilda, указав сценарий и какие параметры мы будем от вас получать. Обращайтесь к Конструктору по ссылке, подставляя значения параметров для совершения звонков клиентам.',
        //     '<a>Как настроить webhook с формы вашего сайта на Tilda</a>'
        //   ],
        //   button: {
        //     text: 'Создать интеграцию с tilda'
        //   },
        //   data: [
        //   ],
        //   headers: [
        //     { text: '№', align: 'start', value: 'id' },
        //     { text: 'Название', value: 'name' },
        //     { text: 'Тильда ID', value: 'tilda_id' },
        //     { text: 'Действия', value: 'actions', sortable: false }
        //   ],
        // },
        'Битрикс24': {
          edit_popup:false,
          in_dev: true,
          title: 'Битрикс24',
          text: 'Интегрируйте робота с сайтом и автоматически запускайте звонки',
          icon: 'bitrix',
          paragraph: [
            'Легкий способ запустить звонок прямо из форм сайта Tilda. Получите ссылку в особом формате Tilda, указав сценарий и какие параметры мы будем от вас получать. Обращайтесь к Конструктору по ссылке, подставляя значения параметров для совершения звонков клиентам.',
            '<a>Как настроить webhook с формы вашего сайта на Tilda</a>'
          ],
          button: {
            text: 'Создать интеграцию с BITRIX'
          },
          data: [],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Тильда ID', value: 'tilda_id' },
            { text: 'Действия', value: 'actions', sortable: false }
          ],
        },
        'Telegram': {
          edit_popup:false,
          in_dev: false,
          title: 'Telegram',
          text: 'Пересылайте информацию о звонках в личные сообщения или чаты telegram',
          icon: 'telegram',
          paragraph: [
            'Подключите бота и получайте информацию от робота во время звонка в личные или групповые чаты. Что и когда отправлять настраивается внутри сценария с помощью блока «Отправить сообщение в Telegram»'
          ],
          button: {
            text: 'Создать интеграцию telegram'
          },
          data: [
          ],
          headers: [
            { text: '№', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
            { text: 'Telegram id', value: 'tg_id' },
            { text: 'Действия', value: 'actions', sortable: false }
          ],
        },
        'AmoCRM': {
          edit_popup:false,
          in_dev: false,
          title: 'AmoCRM',
          text: 'Вебхук для AmoCRM и перемещение лида по воронке',
          icon: 'amocrm',
          paragraph: [
            'Интеграция c AmoCRM. Заполните форму и подключитесь. ',
            'Веб-хук для AmoCRM такого вида: https://gate.crmcalls.ru/api/web/amoWebHook/{id базы}/{номер юзера}/{id интеграции} ',
          ],
          button: {
            text: 'Создать интеграцию telegram'
          },
          data: [
          ],
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
          ],
        },
         'RESTAPI': {
          edit_popup: false,
          in_dev: false,
          title: 'RESTAPI',
          text: 'Интеграция по REST API для получения данных',
          icon: 'restapi',
          paragraph: [
            'Интеграция REST API ',
          ],
          button: {
            text: 'Создать интеграцию REST API'
          },
          data: [
          ],
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Название', value: 'name' },
          ],
          click: () => {
            window.open('/RESTapi.pdf');
          }
        } 
      },
      breadcrumbs: [
        {
          text: 'Интеграции',
          disabled: false,
          href: 'integrations',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'integrations',
        },
      ],
    }
  },
  methods: {
    getAmoIntegrationsList(){
      this.$http.post("/integrations/get_amo_integration_list",
          {},(res)=>{
            if(res != false){
              this.current_integration.data = res;
              this.amo_list = res;
            }else{
              this.amo_list = {};
            }

          })
    },
    registr_amo(){
      console.log(this.amo_data)

      this.$http.post("/integrations/registr_amo",
      this.amo_data,(res)=>{
            if(res != false){
              this.amo_succes.visible = true
              this.amo_succes.massage = "Соединение прошло успешно. Ваш токен:"
              this.amo_succes.token = res
              this.getAmoIntegrationsList();
            }else{
              this.amo_succes.visible = true
              this.amo_succes.massage = "Что-то пошло не так"
            }

      })

    },
    editDataEmit(item){
      this.editData(true,{id:item.guid,item:item})
    },
    editData(visible,data)
    {
      this.edit_data=data
      this.edit_popup=visible
    },
    deleteData(item)
    {
      this.$http.post("/integrations/delete",
      {
      type:this.integration_name,
      id:item.guid
      },()=>{
        this.renderData()
      })
    },
    renderData(norender=false)
    {
      this.edit_popup=false
      if (!norender)
      {
      this.loading=true
      if (!this.if_main)
      {
        if(this.integration_name == "AmoCRM"){
          this.getAmoIntegrationsList();
          this.loading=false
        }else{
          this.$http.post("/integrations/list",
              {
                type:this.integration_name
              },(data)=>{
                this.loading=false
                this.current_integration.data=data
              })
        }


      }
       }
    },
    returnMain(page)
    {
      if (page=="index")
      {
        this.if_main=true
    //    this.renderBases()
      }
    },
    changeInnerUrl(item){
      console.log(item)

      if(!item.in_dev){
        this.integration_name=item.title
        this.current_integration = this.cards[item.title]
        this.breadcrumbs[1].text = item.title
        this.if_main = false
        this.renderData()
      }

    },
    reset(){
      this.current_integration = this.default
    }
  },
  mounted(){
    this.getAmoIntegrationsList();


    //123
    // this.$http.post("/integrations/get_amo_statuses",
    //     {
    //       inegration_id:"34ff266c-b58b-44a5-9c6f-bd2d9e66d2fd"
    //     },(res)=>{
    //       this.testtest = res;
    //     })
  },
  beforeMount(){
    this.current_integration = this.default
  }
}
</script>
<style scoped>
.ta_left_v{
  text-align: left;
}
.top{
  margin-top: 24px;
  margin-bottom: 8px;
}
.list{
  padding-left: 0;
}
.content_table{
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(370px,1fr));
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.bc{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
.bc li a{
  color: #404247!important;
}
.text{
  text-align: left;
}
.table_integrations{

}
@media (max-width: 812px){
  .top{
    justify-content: center;
    flex-direction: column;
  }
  h1{
    margin-bottom: 15px;
  }
  .v-application .my-12{
    margin-top: 15px!important;
    margin-bottom: 15px!important;
  }
}
@media(max-width: 400px){
  .text{
    padding: 10px;
  }
  .content_table{
    padding: 10px;
  }
}
</style>
