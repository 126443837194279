<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >

      <div @click="close" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <Icon name="error"/>
        <div class="modal_title">
          {{title}}
        </div>

        <div class="my_btn add_modal" @click="action">{{btn.text}}</div>
        <div class="my_btn" @click="close">Отменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['title', 'btn','show_dialog','callback_refresh','show_dialog_error_message'],
  methods: {
    action()
    {
      this.$router.push('/')
    },
    close()
    {
      this.dialog=false
      this.callback_refresh(false,false)
    },
  },
  watch: {
    show_dialog: function (val) {
      this.dialog=val
    }
  },
  data(){
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}

 .add_modal{
    border: none;
    width: 100%;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: center;
    font-style: normal;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: 19px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }
</style>