<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <div @click="dialogclose" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <Icon name="ok"/>
        <div class="modal_title">
          Ожидайте подтверждения
        </div>

        <v-btn class="my_btn add_modal">Создать обзвон</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['title', 'btn','dialogstate','dialogclose'],
  data(){
    return {
      dialog: this.dialogstate
    }
  },
  watch:{
    dialogstate(nowValue){
      this.dialog = nowValue
    }
  },
}
</script>
<style scoped>
.wrapper{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}

.add_modal{
  border: none;
  width: 100%;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-style: normal;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 19px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>