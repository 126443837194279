<template>
    <v-card class="item_card mx-auto mb-2" max-width="374" @click="func(item)">
    <Icon :name="item.icon" />
    <div v-if="item.in_dev" class="dev">В разработке</div>
    <div class="title">{{title}}</div>
    <div class="text">{{item.text}}</div>
  </v-card>
</template>
<script>
import Icon from '../SetIcon.vue'
export default {
  components: {
    Icon
  },
  props: ['item', 'title', 'func'],
}
</script>
<style scoped>
.dev{
  margin-left: auto;
  width: max-content;
  padding: 6px 12px;
  background: #feda20;
  font-weight: 600;
  border-radius: 8px;
  position: absolute;
  right: 12px;
  top: 12px;
}
.item_card{
  padding: 16px;
  text-align: left;
  cursor:pointer;
}
.icon{
  margin-bottom: 12px;
}
.title{
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 120%;
  color: #404247;
}
.text{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: #55575C;
}
</style>