<template>
  <div class="form_container">
    <div class="form">
      <v-toolbar flat>
        <template v-slot:extension>
          <v-tabs v-model="data_local.type" centered>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab active-class="active_tab">
              Распознавание
            </v-tab>
            <v-tab active-class="active_tab">
              DTMF
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="data_local.type">
          <v-tab-item>
            <div class="answer_item">
              <v-expansion-panels>
                <v-expansion-panel v-for="(itemP, i) in data_local.box" :key="i">
                  <div class="panel_container">
                    <div class="panel_head">
                      <v-expansion-panel-header>
                        <template v-slot:actions>
                          <v-icon class="expand_icon" v-bind:class="{ system: itemP.items=='system' }">
                            $expand
                          </v-icon>
                        </template>
                        <div class="answer_key"  v-bind:class="{ system: itemP.items=='system' }">{{itemP.word}}</div>
                      </v-expansion-panel-header>
                    </div>
      
                    <div class="delete_icon" @click="delete_keygen(data_local.box, i, $event)">
                      <Icon name="delete" />
                    </div>
                  </div>
                  <v-expansion-panel-content  v-if="itemP.items!='system'">
                    <div class="item_tab">
                      <div class="answers">
                        <div v-for="(item, j) in itemP.items" :key="j" class="item">
							<input
								@blur="blurWord(i,j)"
								@input="input"
								v-model="item.text"
								placeholder="Ответ"
								:style="{width: label_length(item) + 30 + 'px',
									color: validWord(item.text) ? '#000000' : '#ea1b1b'}"
								class="word_edit"
								:ref="`idInput${j}-${i}`"
								v-on:keyup.enter="add_word(itemP.items, i)"
							/>
							<div class="delete" @click="delete_word(itemP.items, j)">
                            <Icon name="close"/>
                          </div>
                        </div>
                        <div class="item add_item" @click="add_word(itemP.items, i)">
                          <Icon name="plus"/>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              
              
            </div>
            <div class="add">
              <div class="keygen_add" v-if="save_active">
                <v-text-field v-model="save_model" hide-details placeholder="Ключевое слово"></v-text-field>
              </div>
              <div class="btn_adder">
                <Icon name="blue_plus"/>
                <div class="text" v-if="save_active" @click="save_add">Сохранить</div>
              </div>
              <div class="text" v-if="!save_active" @click="save_activator">Добавить ответ</div>
            </div>
          </v-tab-item>  
          <v-tab-item>
            <div class="title">
              Ввод с телефона от 0 до 9
            </div>
          </v-tab-item>  
      </v-tabs-items>
  
<div class="seconds_wait">
<p>Ожидать ответа клиента секунд</p>
<v-slider
  max="60"
  min="1"
  track-fill-color="#404247"
  thumb-color="#FED500"
  track-color="#AAABAD"
  color="#404247"
  hide-details
  v-model="data_local.seconds"
  thumb-label="always"
></v-slider>
</div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../SetIcon.vue'
export default {
  props: ['data','edit_callback'],
  components: {
    Icon
  },
  methods:{
	validWord(textVal) {

		// тут мы разворачиваем все группы слов в один плоский масив, вынимаем с них текст,
		// фильтруем по слову которое проверяем проверяем на дубли, если совпадений не равно 1 тогда фолс
		return this.data_local.box.reduce((rslt, current) => Array.isArray(current.items)?[...rslt, ...current.items]:rslt, [])
				.map(item => item.text)
				.filter(w => w === textVal)
				.length === 1

		// for (let group in this.data_local.box) {
		// 	if (this.data_local.box[group].items !== 'system'){
		// 		for (let item in this.data_local.box[group].items) {
		// 			if (groupVal != group || itemVal != item){
		// 				if (this.data_local.box[group].items[item].text.toLowerCase() == textVal.toLowerCase()) {
		// 					return false
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	},
	blurWord(group, item){
		if (this.data_local.box[group].items[item]){
			let newWord = this.data_local.box[group].items[item].text.trim()
			if(newWord.length){
				this.data_local.box[group].items[item].text = newWord
			} else this.data_local.box[group].items.splice(item,1);
		}
	},
    label_length(item)
    {
      let length=10*item.text.length
      if (length<50)
      {
      length=50
      }
      return length + 20
    },
    input()
    {
        this.edit_callback(this.data_local)
        return true
    },
    save_add()
    {
      if (this.save_model.length>0)
      {
        this.save_active=false
        this.data_local.box.push({
          word:this.save_model,
          items:[{"text":this.save_model}]
        });
        this.save_model=''
        this.input()
      }
    },
    save_activator()
    {
      this.save_active=true
    },
    delete_keygen(array,j,event)
    {
      event.preventDefault()
      array.splice(j, 1);
      this.input()
      return false;
    },
    delete_word(array,j)
    {
    array.splice(j, 1);
    },
    add_word(array, numFor)
    {
		//console.log(array.length)
		if(array.length === 0 || array[array.length - 1].text){
			array.push({text:""})
			setTimeout(() => {
				for(let i of [1,1]){
					this.$refs[`idInput${array.length - i}-${numFor}`][0].focus();
				}
			}, 10)
		}
    },
    word_editor(item)
    {
      item.word_edit=true
      this.edit_item=item.text
    }
  },
  watch: {
    data: function (val) {
      this.data_local=val
    },
  },
  mounted()
  {
    this.data_local=this.data
  },
  data(){
    return {
      model:0,
      slider:0,
      word_edit:false,
      tester:true,
      save_model:'',
      save_active:false,
      edit_item:'',
      answers: [],
      template_answers: [
        'Нет', 'Да', 'Повторите', 'Отстаньте', 'Не хочу', 'Не нужно', 'Не интересует', 'Продолжайте'
      ],
    tab: null,
    data_local:[],
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      tabs: ['Выбрать готовые', 'Сделать самостоятельно'],
      tab_items: [
        { title: 'Текст реплики робота', vs: 'Создать реплику'},
        { title: 'Текст реплики робота', vs: 'Загрузить аудиозапись'}
      ],
    }
  }
}
</script>

<style scoped>
.title{
  margin-top: 20px;
}
.seconds_wait
{
  margin-top: 25px;
  text-align: left;
}
.seconds_wait p
{
  margin-bottom: 40px;
}
.keygen_add
{
  margin-right: 30px;
  width: 100%;
}
.panel_container
{
  display: flex;
}
.panel_container
{
  display: flex;
}
.panel_head
{
  width:100%;
}
.v-expansion-panel::before
{
box-shadow: none!important;
background: none!important;
}
.expand_icon
{
position: absolute;
top:calc(50% - 20px);
right:10px;
}
.expand_icon.system
{
display: none;
}
.v-expansion-panel
{
  background: none!important;
}
.v-expansion-panel-header
{
background: none;
padding: 0px;
padding-bottom: 15px;
}
.word_edit
{
  text-align: center;
	border: 1px solid #cccccc;
	background: #F4F4F4;
	border-radius: 24px;
	padding: 9px 20px;
}
.word_edit:hover
{

}
.btn_adder{
  display: flex;
  align-items: center;
}
.word_edit:focus
{
	border: 1px solid #cccccc;
	background: none;
	border-radius: 5px;
}

.word_edit:hover
{
	border: 1px solid #8d8d8d;
	background: #ffffff;
}

.answers
{
text-align: left;
line-height: 24px;
display: flex;
align-items: center;
flex-wrap: wrap;
}
.answers .word_span
{
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right: 20px;
}
.answers .item.add_item
{
  background: #FEE600;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.item{
  /*padding: 9px 20px;*/
}
.item:hover{
	background: none;
}
.answers .item
{
display:inline-block;
position:relative;

margin-right: 7px;
margin-top: 5px;
margin-bottom: 5px;

}

.add_item{
	background: #F4F4F4;
	border-radius: 24px;
}
.answers .item .delete
{
  display:none;
}

.answers  .item:hover .delete
{
  position: absolute;
  top:-2px;
  display:block;
  right: -2px;
}
.v-toolbar__content
{
height: 15px; /* 15px */
}
.v-tab
{
  font-size: 17px;
  color: #2B2D33;
  text-align: left;
  padding-left:5px;
  font-style: normal;
  text-transform: none !important;
  padding-right: 20px;
  line-height: 20px;
}
.answer_lib
{
background: #FFFFFF;
box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
border-radius: 6px;
min-height:168px;
padding-left: 12px;
padding-top: 16px;
padding-right: 12px;
padding-bottom: 12px;
margin-top: 10px;
}
.answer_key
{
width: 100%;
height: 48px;
cursor:pointer;
background: #FFFFFF;
border: 1px solid #AAABAD;
padding-left: 12px;
text-align: left;
color: #2B2D33;
font-size: 17px;
line-height: 48px;
border-radius: 8px;
}
.answer_key.system
{
  background: #f5f5f5;
}
.answer_item
{
  display: flex;
  width: 100%;
  margin-top: 15px;
}
.delete_icon
{
  width: 48px;
  height: 48px;
    cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  border: 1px solid #FEE600;
  border-radius: 8px;
  display: flex;
}
.form
{
display: inline-block;
/*margin-top:32px;*/
max-width: 600px;
width: 100%;
}
.form_container
{
  text-align: center;
  width: 100%;
}
.subtitler{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #55575C;
}
.add{
  cursor: pointer;
  display: flex;
  color: #00809C;
  align-items: center;
}
.add .icon{
  margin-right: 10px;
}
.btn_unlock{
  font-style: normal;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  margin-bottom: 19px;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
}
.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 0 0!important;
}
.my_input{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  position: relative;
}

.my_input:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}
.my_input .icon{
  position: absolute;
  right: 7px;
  top: 17px;
}
.subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.modal_header{
  display: flex;
  align-self: flex-end;
}
.item_tab{
  margin: 0;
  max-width: 600px;
}
.modal_body{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.save{
  padding: 10px 20px 12px;
  background: #FEE600;
  border-radius: 8px;
  margin: 16px 0 0 0;
}
.paste{
  padding: 9px 20px 10px;
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  max-width: 235px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.modal_title{
margin-top: 32px;
margin-bottom: 8px;
}
h3{
  margin-bottom: 12px;
  text-align: left;
}
.close{
  margin-right: 52px;
  margin-top: 20px;
}
</style>
