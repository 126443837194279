<template> 
  <div class="text-center">
    <v-dialog v-model="dialog" width="510" >
      <div @click="edit_callback(false)" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        <div class="modal_title">
          Изменить базу номеров
        </div>
        
        <div>
          <p class="modal_subtitle">Назовите базу номеров</p>
          <v-text-field v-model="basename" placeholder="Тестовая база номеров" :rules="rules"></v-text-field>
        </div>
        <div>
        <p class="modal_subtitle">Группа</p>
        <v-select v-model="selectedGroup" :items="groupsWithNone" label="Выберите группу"></v-select>
      </div>
        <div>
        <BaseParams :params="params" @updateParams="updateData"/>
        </div>
        <div class="add add_modal" @click="save">Изменить</div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Icon from '../../SetIcon.vue'
import BaseParams from './Form/BaseParams.vue'
export default {
  props: ['show_dialog','edit_callback','edit_base', 'groups'],
  components: {
    Icon,
    BaseParams
  },
  data(){
    return {
      groupsWithNone: [],
      selectedGroup: null,
      basename:'',
      id:false,
      dialog: false,
      params:{},
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 50 || 'Max 50 символов'
      ],
    }
  },
  methods:{
    updateData(data)
    {
      this.params=data
    },
    save() {
  if (this.basename.length < 51 && this.basename.length > 0) {
    const requestData = {
      id: this.id,
      params: this.params,
      name: this.basename,
    };
      requestData.groupName = this.selectedGroup;


    this.$http.post("/base/edit", requestData, (data) => {
      if (data) {
        this.selectedGroup = null;
        this.edit_callback(false, false, true);
      }
    });
  }
}
  },
  watch: {
    groups: function (val) {
    // Добавляем элемент "Отсутствует" в начало списка групп
    this.groupsWithNone = [{ text: '---Отсутствует---', value: '---Отсутствует---' }, ...val];
  },
    show_dialog: function (val) {
      this.dialog=val
      if (val)
      {
        this.id=this.edit_base.id
        this.params=this.edit_base.item.params
        this.basename=this.edit_base.item.name    
      }

    },
  }
}
</script>
<style scoped>
.count
{
width:100%;
text-align:left;
font-size: 13px;
font-weight: 600;
line-height: 24px;  
}
.my_textarea{
  padding-top: 16px;
}
.my_tab_item{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  text-transform: none;
}
.my_tab_item:hover{
  color: #2B2D33!important;
  background: none!important;
}
.my_active_tab{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33!important;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}
.add_modal{
  width: 100%;
  padding: 9px;
  margin-top:15px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
}
.textarea{
  padding: 16px 0;
}
.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  margin-top: 24px;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
 .add{
    border: none;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add .icon{
    margin-right: 11px;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2B2D33;
  }
</style>