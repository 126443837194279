<template>
  <div class="text-center">
    <v-dialog v-model="dialog"
    persistent
    fullscreen
    scrollable >

      <div @click="close_cb" class="close">
        <Icon name="close"/>
      </div>
      <v-card class="wrapper">
        
        
        <div class="modal_title">
          Тестовый звонок
        </div>
        <div class="modal_body">
        <div class="error_label" v-if="error">Нет денег на балансе</div>
        <div class="subtitle">Номер телефона</div>
        <div class="my_input"> 
          <v-text-field :placeholder="this.$store.getters['user/getName']" disabled filled></v-text-field>
          <Icon name="lock" />
        </div>
        <div class="incall" v-if="incall">Идет звонок...</div>
<!--        <div class="after" v-if="!incall">Стоимость 1 минуты разговора 40 ₽. У вас на балансе {{this.$store.getters['user/getBalance']}} ₽. </div>-->
        <div class="more" v-if="!incall">Разблокировать больше номеров</div>
        <div class="my_btn btn_save add_modal" v-if="!incall" @click="call">Начать тестовый звонок</div>
        <div class="my_btn" v-if="!incall" @click="close_cb">Отменить</div>
        <div class="my_btn" v-if="incall && !block" @click="repeat_cb">Повторить</div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template> 
<script>
import Icon from '../../SetIcon.vue'
export default {
  props: ['show_dialog','close','data'],
  components: {
    Icon
  },
  data(){
    return {
      dialog: false,
      incall:false,
      block:true,
      error:false,
    }
  },
  methods:{
    call()
    {
      this.incall=true
      this.block=true
      this.$http.post("/script/test_call",
      {
        data:this.data 
      },(data)=>{
        if (data=="error")
        {
          this.error=true
          this.incall=false
        }
        else
        {
        let _this=this
        setTimeout(function(){
          _this.block=false
        },20000);  
        }
        
      })
      
    },
    repeat_cb()
    {
      this.incall=false
      this.error=false
    },
    close_cb()
    {
      this.dialog=false
      this.incall=false
      this.error=false
      this.close()
    }
  },
  watch: {
    show_dialog: function (val) {
      this.dialog=val
    },
  },
}
</script>
<style scoped>
.error_label
{
  color:red;
}
.incall
{
margin: 20px; 
font-weight: 500; 
}
.modal_body
{
width: 500px;  
display: block;
}
.my_input{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
  position: relative;
}
.my_input .icon{
  position: absolute;
  right: 7px;
  top: 17px;
}
.more{
  margin-top: 24px;
  margin-bottom: 27px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  text-align: left;
  cursor: pointer;
  position: relative;
  width: max-content;
}
.more:after{
  display: block;
  content: '';
  height: 1px;
  bottom: -2px;
  width: 100%;
  background: #66C9D7;
  position: absolute;
}
.subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.after{
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #808185;
  text-align: left;
}
.text{
  margin-bottom: 16px;
}
.sname{
  color: grey;
}
.wrapper{
  padding: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my_btn{
  text-transform: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #00809C;
  cursor: pointer;
  justify-content: center;
}

 .add_modal{
    border: none;
    width: 100%;
    padding: 12px 20px 12px 22px;
    background: #FEE600!important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2B2D33;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-style: normal;
  }
  .modal_title{
    padding: 0!important;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: 19px;
    margin-bottom: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2B2D33;
  }
  @media (max-width: 600px){
    .modal_body{
      width: auto;
      padding: 15px;
    }
  }
</style>