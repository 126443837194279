<template>
	<div class="render">
		<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
		<div v-else class="content_table">
			<div class="top">
				<h1>Тарифы минут</h1>
			</div>

			<div class="words_control">
				<div @click="addTariff()" class="word_control_save">Добавить тариф</div>
				<div @click="saveTariffs()" class="word_control_save ml-6">Сохранить</div>
				<p class="save_message" v-if="save_message">Сохранено!</p>
			</div>
			<div class="words_container">
				<v-simple-table>
					<template v-slot:default>
						<thead>
						<tr>
							<th class="text-left">
								№
							</th>
							<th class="text-left">
								Минуты от
							</th>
							<th class="text-left">
								Минуты до
							</th>
							<th class="text-left">
								Стоимость 1 мин
							</th>
							<th class="text-left">

							</th>
						</tr>
						</thead>
						<tbody>
						<tr
							v-for="(item, kay) of tariffs"
							:key="kay"
						>
							<td>
								{{ kay + 1 }}.
							</td>
							<td>
								<v-text-field
									class="input_table"
									v-model="item.minAmount"
									hide-details="auto"
									type="number"
									required
								></v-text-field>
							</td>
							<td>
								<v-text-field
									class="input_table"
									v-model="item.maxAmount"
									hide-details="auto"
									type="number"
									required
								></v-text-field>
							</td>
							<td>
								<v-text-field
									class="input_table"
									v-model="item.pricePerMinute"
									hide-details="auto"
									type="number"
									required
								></v-text-field>
							</td>
							<td @click="deleteTariff(kay)"> <div class="deleteBtn">Удалить</div></td>
						</tr>
						</tbody>
					</template>
				</v-simple-table>
			</div>
		</div>
	</div>
</template>
<script>
//import Vue from 'vue'
//import Icon from '../../components/SetIcon.vue'
export default {
	name: "TariffsAdminView",
	components: {
		//Icon,
	},
	data() {
		return {
			tariffs: [

			],
			save_message: false
		}
	},
	mounted() {
		this.renderTariffs()
	},
	methods: {
		saveTariffs() {
			this.$http.post("/tariffs_minutes/save_tariff",
				{
					arrTariffs: this.tariffs.sort((a, b) => a.minAmount - b.minAmount).filter((tariff) => tariff.minAmount && tariff.maxAmount && tariff.pricePerMinute)
				}, (result) => {
					if (result) {
						//this.saveAnswer = true
						this.saveMessage()
						this.renderTariffs()
					}
				})
		},
		renderTariffs() {
			this.$http.post("/tariffs_minutes/list_tariffs",
				{}, (result) => {
					if (result) {
						this.tariffs = result
					}
				})
		},
		addTariff() {
			this.tariffs.push({
				minAmount: null,
				maxAmount: null,
				pricePerMinute: null
			})
		},
		deleteTariff(key) {
			this.tariffs.splice(key, 1)
			this.saveTariff()
		},
		saveMessage(){
			this.save_message = true
			setTimeout(() => {
				this.save_message = false
			}, 3000)
		}
	},
}
</script>
<style scoped>
.deleteBtn {
	color: #ff7878;
}
.deleteBtn:hover {
	cursor: pointer;
	color: red;
}

.save_message {
	height: 41px;
	margin: 0 0 0 35px;
	color: #1caf1c;
	padding: 8px;
}

.v-text-field > .v-input__control .v-input__slot {
	border: none;
	background: none;
}

.services_card .v-text-field .v-input__prepend-inner,
.services_card .v-text-field .v-input__append-inner {
	margin: 0;
	width: 20%;
}

.services_card .v-text-field > .v-input__control > .v-input__slot {
	height: 44px;
}

.words_container {
	margin-top: 25px;
}

.words_control {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.word_control_save:hover {
	opacity: 1;
	transition-duration: 0.3s;
}

.word_control_save {
	border: none;
	padding: 12px 20px 12px 22px;
	background: #fee600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	color: #2b2d33;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 200px;
	opacity: 0.8;
	transition-duration: 0.3s;
}

.subword_sec_plus > .icon {
	background: #f4f4f4;
	padding: 15px;
	border-radius: 7px;
	opacity: 0.7;
	transition-duration: 0.3s;
}

.subword_sec_plus > .icon:hover {
	cursor: pointer;
	opacity: 1;
	transition-duration: 0.3s;
}

div {
	text-align: left;
}

p {
	text-align: left;
}

.top {
	margin-bottom: 24px;
}

.top {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

h1 {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
}

h2 {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 24px;
}

.left .title {
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 120%;
	color: #55575C;
	text-align: left;
}

.left .num {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.left_text .text {
	margin-left: 18px;
}

.ok .text {
	color: #008E7D;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
}

</style>
