import { render, staticRenderFns } from "./IntegrationBitrix24.vue?vue&type=template&id=6db6f3b1&scoped=true"
import script from "./IntegrationBitrix24.vue?vue&type=script&lang=js"
export * from "./IntegrationBitrix24.vue?vue&type=script&lang=js"
import style0 from "./IntegrationBitrix24.vue?vue&type=style&index=0&id=6db6f3b1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db6f3b1",
  null
  
)

export default component.exports