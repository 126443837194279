<template>
	<div>
		<div class="download_file" v-bind:class="{ isload: load }">

			<div v-if="!isload">
				<div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
					<div class="dropZone-info" @drag="onChange">
						<img class="load_img_passport" src="../assets/img/png/KFZ5F-XLLYk.jpg">
						<span class="fa fa-cloud-upload dropZone-title"></span>
						<div class="dropZone-title">Перетащите фото сюда или</div>
						<div class="dropZone-info-inner">
							<div class="attach">
								<Icon name="attach"/>&nbsp;Загрузите файлы
							</div>
							<div>{{'jpeg, img'}}</div>
						</div>
					</div>
					<input type="file" :accept="formats" @change="onChange">
				</div>
			</div>
	<!--		https://storage.yandexcloud.net/download/80695504d7121b561da6547cf582f107.jpg-->
			<div v-else class="dropZone-uploaded">
				<div class="dropZone-uploaded-info">
	<!--				<span class="dropZone-title">Загружен</span>-->
					<div class="record">
	<!--					<AudioPlayer :item="{record:data.download, id: 'download'}"/>-->
						<img :src="photoLink" class="img_photo_passport_person" alt="img">
					</div>

				</div>
			</div>
	<!--		<div class="dropzone_limits">-->
	<!--			{{ description }}-->
	<!--		</div>-->
		</div>
		<button v-if="isload" type="button" class="clear" @click="removeFile">Удалить файл</button>
		<div class="valid_value">
			{{ valid && !isload ? 'Обязательно к заполнению!' : '' }}
		</div>
	</div>
</template>
<script>
//import AudioPlayer from '@/components/AudioPlayer.vue'
import Icon from './SetIcon.vue'
// import { XlsxRead, XlsxTable, XlsxSheets } from "../../node_modules/vue-xlsx/dist/vue-xlsx.es"
export default {
	name: "PhotoLoad",
	props: ['types', 'description', 'isload', 'callback_file', 'formats', 'url', 'data', 'photoLink', 'valid'],
	components: {
		Icon,
		//AudioPlayer,
		// XlsxRead,
		// XlsxTable,
		// XlsxSheets
	},
	data() {
		return {
			file: '',
			load: false,
			dragging: false,
			selectedSheet: null
		}
	},
	watch: {
		isload: function (val) {
			if (val) {
				this.dragging = true
				this.file = {}
			} else {
				this.dragging = false
				this.file = false
			}
		},
		// data: function (){
		// 	console.log(this.data.download)
		// }
	},
	methods: {
		onChange(e) {
			var files = e.target.files || e.dataTransfer.files;

			if (!files.length) {
				this.dragging = false;
				return;
			}
			this.createFile(files[0]);
		},
		createFile(file) {
			console.log(61, file)
			let _this = this

			if (file.size > 5000000) {
				alert('please check file size no over 5 MB.')
				this.dragging = false;
				return;
			}

			this.file = file;
			console.log(this.file)
			this.load = true;
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				_this.$http.post(_this.url,
					{
						file: reader.result
					}, (data) => {
						if (data == "break") {
							alert('Формат файла не верный');
							_this.load = false
							this.dragging = false
							_this.callback_file(false)
						} else {
							_this.load = false
							_this.callback_file(data)
						}

					})

			};
			this.dragging = false;
		},
		removeFile() {
			this.file = false;
			this.callback_file(false)
		}
	},
	computed: {
		// extension() {
		// 	return (this.file) ? this.file.name.split('.').pop() : '';
		// }
	}
}
</script>
<style scoped>

.img_photo_passport_person {
	width: 96%;
    height: 181px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: 5px;
}
.isload {
	pointer-events: none;
	opacity: 0.5;
}

.attach {
	color: #00809C;
}

.load_img_passport {
	width: 128px;
	height: 108px;
}

.dropzone_limits {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 130%;
	text-align: left;
	margin-bottom: 24px;
	color: #AAABAD;
}

.valid_value {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 130%;
	text-align: center;
	width: 100%;
	margin-bottom: 24px;
	color: #ff3737;
}

.uploadedFile-info {
	display: flex;
}

.file_icon {
	background: #BFE9DF;
	border-radius: 4px;
	margin-right: 8px;
	width: 40px;
	height: 40px;
	position: relative;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #55575C;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.download_file {
	display: flex;
	flex-direction: column;

}

.dropZone {
	margin: 16px auto 8px auto;
	height: 200px;
	position: relative;
	/*background: #F8F8F8;*/
	border: 1px dashed #D5D5D6;
	box-sizing: border-box;
	border-radius: 8px;
}

.dropZone:hover {
	border: 2px solid #2e94c4;
}

.dropZone-info {
	color: #A8A8A8;
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	text-align: center;
}

.dropZone-info-inner div:first-child {
	display: flex;
	color: #00809C;
	align-items: center;
	justify-content: center;
}

.dropZone-title {
	color: #787878;
}

.dropZone input {
	position: absolute;
	cursor: pointer;
	top: 0px;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.dropZone-upload-limit-info {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.dropZone-over {
	background: #5C5C5C;
	opacity: 0.8;
}

.dropZone-uploaded {
	width: 100%;
	height: 200px;
	position: relative;
	border: 2px dashed #eee;
}

.dropZone-uploaded-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #A8A8A8;
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	text-align: center;
}

/*.audio_player{*/
/*	display: flex;*/
/*	width: 90%;*/
/*	align-items: center;*/
/*}*/

.record {
	width: 100%;
}

.record .player {
	display: flex;
	align-items: center;
}

.record .player .time {
	width: 150px;
}

.removeFile {
	width: 200px;
}

.removeFile:hover {
	color: red;
}

.clear {
	cursor: pointer;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	text-decoration-line: underline;
	color: #E34747;
	padding: 6px 12px;
	margin-top: 10px;
}
</style>