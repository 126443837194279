<template>
    <div v-if="visible" class="alert" :class="'custom_' + obj.type">
      <!--  TYPES: inform, alert, green_ok, attention, blue_inform -->
      <div class="rower">
        <Icon :name="obj.type" />
        <div class="heading">{{obj.heading}}</div>
      </div>
      <div class="text">{{obj.text}}</div>
    </div>
</template>
<script>
import Icon from '../SetIcon.vue'
export default {
  props: ['obj','visible'],
  components: {
    Icon
  }
}
</script>
<style scoped>
.rower{
  display: flex;
  margin-bottom: 8px;
}
.alert{
  padding: 18px;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 60px;
  display: flex;
  flex-direction: column;
  max-width: 340px;
}
.heading{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
}
.text{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  padding-left: 35px;
  text-align: left;
}
.alert .icon{
  margin-right: 18px;
}
.custom_alert{
  background: #FDEEEE;
  color: #C74952;
}
.custom_green_ok{
  background: #E8F7F4;
  color: #008E7D;
}

.custom_attention{
  background: #FEF3EC;
  color: #CB662F;
}

.custom_inform{
  background: #F4F4F4;
  color: #2B2D33;
}

.custom_blue_inform{
  background: #E6F6F8;
  color: #00809C;
}
</style>