<template>
  <div class="render">
    <div class="top">
      <h1>Скрипты звонков</h1>
      <CreateScript :show_dialog="popup" :callback_refresh="renderData" :data="scenario.data" :id="scenario.id" :scenario_name="scenario.data.name"/>
    </div>
    <div class="content_table">
      <Table @copy="copyScenario"
			@delete="deleteScenario"
			@draw="drawScenario"
			@edit="editScenarioEmit"
			:page_type="'scripts'"
			:callback_edit="editScenario"
			:callback_delete="deleteScenario"
			:items="action_items"
			:callback_draw="drawScenario"
			:callback_refresh="renderData"
			:data="data"
			:loading="loading"
			:headers="headers"
		/>
    </div>
    <div v-if="im_tester">
    <DeleteScenarium base_name="Тестовая пользовательская база номеров" />
    <TestCall />
     </div>
     <ScenarioDraw :lib="lib" :callback_refresh="renderData" :show_dialog="edit_scenario" :id="scenario.id" :data="scenario.data" :close="closeScenario"/>
  </div>
</template>
<script>
import Table from '../components/Table/DataTable.vue'
import CreateScript from '../components/Modal/Scenaries/CreateScript.vue'
import ScenarioDraw from '../components/Modal/Scenaries/ScenarioDraw.vue'
import DeleteScenarium from '../components/Modal/Scenaries/DeleteScenarium.vue'
import TestCall from '../components/Modal/Scenaries/TestCall.vue'

export default {
  components: {
    Table, CreateScript, DeleteScenarium, TestCall,ScenarioDraw
  },
  beforeMount(){
    this.$http.post("/script/lib", {
    }, data => {
    this.lib=data
    })
    this.renderData()
  },
  methods: {
    editScenarioEmit(item){
        this.editScenario(true,{id:item.guid,item:item})
    },
    editScenario(popup, item){        // где-то тут начитается присвоение даты
      this.scenario.id = item.id
      this.scenario.data = item.item
      this.popup = popup
    },
    deleteScenario(item) {
      let guid = item.guid
      this.$http.post("/script/delete", {
        id: guid
      }, () => {
        this.renderData()
      })
    },
	copyScenario(item) {
      let guid = item.guid
      this.$http.post("/script/copy", {
        id: guid
      }, () => {
        this.renderData()
      })
    },
    drawScenario(item) {
      let guid = item.guid
      if (guid && item) {
        this.scenario.id = guid
        this.scenario.data = item
        this.edit_scenario = true
      }
    },
    closeScenario() {
      this.scenario.id = false
      this.edit_scenario = false
    },
    renderData(render = true) {
      this.scenario={id: false, data: {name:''}}
      this.popup = false
      if (render == true) {
        this.loading = true
        this.$http.post("/script/list", {}, data => {
          this.loading = false
          this.data = data
        })
      }
    }
  },
   data(){
    return {
      edit_scenario: false,
      popup: false,
      loading: true,
      lib:[],
      scenario: {
        id: 0,
        data: {}
      },
      im_tester: false,
      data: [],
      action_items: [
        {title: 'Изменить',action:'draw'},
        {title: 'Переименовать',action:'edit'},
        {title: 'Копировать',action:'copy'},
        {title: 'Удалить',action:'delete'},
      ],
      headers: [
        { text: '№', align: 'start', value: 'id' },
        { text: 'Название', value: 'name' },
        { text: 'Дата создания', value: 'created_at'},
        { text: 'Действия', value: 'actions', sortable: false }
      ],
    }
  }
}
</script>
<style scoped>
.top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
@media (max-width: 650px){
  .top{
    flex-direction: column;
  }
  h1{
    margin-bottom: 20px;
  }
}
@media (max-width: 400px){
  h1{
    margin-top: 15px;
  }
}
</style>
