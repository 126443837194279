<template>
<v-form ref="form">
  <div class="modal_title">
    Новая интеграция telegram
  </div>
  <div>
    <p class="modal_subtitle">Введите название интеграции</p>
    <v-text-field v-model="data.name" @input="input" :error-count="errors" placeholder="Ленд продажи цветов" :rules="rules"></v-text-field>
  </div>
  <div>
    <p class="modal_subtitle">Telegram Id</p>
    <v-text-field v-model="data.tg_id" @input="input" :error-count="errors" placeholder="546345324" :rules="rules"></v-text-field>
  </div>
  <p class="subtitle">Как настроить?</p>
  <ul class="list">
    <li>Создать чат в Telegram</li>
    <li>В чат добавить бота @CrmCallBot</li>
    <li>В чат добавить бота @myidbot</li>
    <li>Прописать в чат команду: /getgroupid@myidbot</li>
    <li>Зайти в меню “Интеграции”, выбрать Telegram, нажать “Создать интеграцию Telegram”</li>
    <li>В поле “Telegram id” вставить полученный от бота id со знаком минус. В нашем случае это “-832948510”</li>
  </ul>
</v-form>
</template>
<script>
export default {
  props: ['update','pre_data'],
  data(){
    return {
      errors:0,
      rules: [
        value => !!value || 'Обязательное поле',
        value => (value || '').length <= 1000 || 'Max 1000 символов'
      ],
      data:{
        name:"",
        tg_id:"",
      }
    }
  },
  watch:{
    pre_data:function()
    {
      this.render()
    }
  },
  mounted()
  {
    this.render()
  },
  methods: {
    render()
    {
      if (this.pre_data)
      {
        this.data=JSON.parse(JSON.stringify(this.pre_data))
      }
      else
      {
        this.data={
          name:"",
          tg_id:"",
        }
      }
    },
  input()
  {
    if (this.$refs.form.validate())
    {
      this.update(this.data)
    }
    else
    {
      this.update(false)
    }
  }
  }
  
}
</script>
<style scoped>
.list{
  list-style: none;
  text-align: left;
  margin-bottom: 24px;
}
.list li{
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #55575C;
  margin-bottom: 12px;
  margin-left: 16px;
}
.list li:before{
  content: '';
  background: #55575C;
  display: block;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  left: -7px;
  top: 7px;
}
.rules{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #AAABAD;
  text-align: left;
  margin-bottom: 24px;
}

.textarea{
  padding: 16px 0;
}

.subtitle{
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  color: #404247;
}
.wrapper{
  padding: 32px;
}
.modal_subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 6px;
  text-align: left;
  color: #404247;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
}
.add .icon{
  margin-right: 11px;
}
.modal_title{
  padding: 0!important;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B2D33;
}
</style>