<template>
  <div>
  <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PDQ7QC5X"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
    
  <v-app v-if="this.$store.getters['user/isLogin']!='wait'">
    <v-app-bar color="#fff" v-if="headHidden">
      <Header @drawer="changeDrawer" />
    </v-app-bar>
    <div class="body_container">
    <v-navigation-drawer v-model="drawer" absolute v-if="headHidden" color="#fff" width="216" app>
      <LeftBar/>
    </v-navigation-drawer>
    <v-main color="#e5e5e5" app>
      <v-container fluid >
        <router-view v-if="this.$store.getters['user/isLogin']"></router-view>
        <Login v-else />
      </v-container>
    </v-main>
    </div>
    <v-footer v-if="!this.$store.getters['notification/isFooterHide']" fixed color="#fff" height="52" class="custom_footer">
      <Footer :footer="footerHidden"/>
    </v-footer>
  </v-app>
  </div>
</template>

<script>
// test commit 234234234
import Header from './components/TopHeader.vue'
import Login from './views/LoginForm'
import LeftBar from './components/LeftSidebar.vue'
import Footer from './components/BottomFooter.vue'
export default {
  components: {
    Header,
    LeftBar,
    Footer,
    Login
  },
  data(){
    return {
      drawer: true,
    }
  },
  
  created(){
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PDQ7QC5X')
  },
  
  beforeMount() {
    if (localStorage.token)
    {
      this.$http.post("/auth/token",
      {
      token:localStorage.token
      },
      (data)=>{
        if (data)
        {
        this.$store.commit('user/loginUser', data)
        }
        else
        {
        this.$store.commit('user/logoutUser')
        }

      })
    }
    else
    {
      this.$store.commit('user/logoutUser')
    }
  },
  computed: {
    footerHidden: function () {
      return this.$store.getters['user/isLogin']
    },
    headHidden: function () {
      return this.$store.getters['user/isLogin']
    }
  },
  methods: {
    changeDrawer(){
      this.drawer = !this.drawer
    },
  }
};
</script>
<style>
.v-list-item:hover{
  background: #f4f4f4;
}
.my-4 ul li button{
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
color: #2B2D33!important;
box-shadow: none;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
  background: #f4f4f4!important;
}
 .my_toolbar > .theme--light.v-tabs > .v-tabs-bar{
    background: none!important;
  }

  .v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab{
    margin-left: 0!important;
  }
  .v-tabs{
    border-bottom: 0!important;
  }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-input--selection-controls{
  margin-top: 0!important;
  padding-top: 0!important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.v-input__control .v-input__slot{
  border: none!important;
}
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 120%;
color: #2B2D33;
}
.container{
  padding: 0!important;
}
.v-toolbar__content, .v-toolbar__extension{
  flex-direction: column;
}
.v-dialog__content{
  z-index: 10000!important;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined){
  z-index: 1000;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1)!important;
}
.body_container{
  position: relative;
  height: 100%;
}
.cancel_btn{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  background: none;
  border: none;
  margin-top: 16px;
  cursor: pointer;
  text-transform: none;
}
.btn_save{
  width: 100%;
  padding: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  color: #2B2D33;
  text-transform: none;
  cursor: pointer;
  margin-bottom: 16px;
}
.v-application ul, .v-application ol{
  padding-left: 0;
}
.render{
  padding: 32px;
}
h1{
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404247;
}
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-input__slot{
  margin-bottom: 0!important;
}
.v-dialog{
  position: relative;
}
.cancel_modal{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
}
.close{
  position: absolute;
  z-index: 1000;
  right: 7px;
  top: 7px;
  width: 14px!important;
  cursor: pointer;
}
.textarea{
  padding: 16px 0 0 0!important;
}
.v-text-field{
  padding: 0!important;
  margin: 0!important;
}
.v-text-field input{
  padding: 0!important;
}
.v-tabs-slider-wrapper{
  height: 4px!important;
  border-radius: 4px;
}
.v-tabs{
  border-bottom: 1px solid #D5D5D6;
}
.custom_footer{
  z-index: 1000!important;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1)!important;
}
.v-input__slot:after{
  width: 0;
}
.flex{
  display: flex;
}
body{
  background: #f4f4f4;
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f4f4f4;
}
@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Bold.eot');
  src: local('Manrope Bold'), local('Manrope-Bold'),
    url('./assets/fonts/Manrope/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Bold.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Bold.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Semibold.eot');
  src: local('Manrope Semibold'), local('Manrope-Semibold'),
    url('./assets/fonts/Manrope/Manrope-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Semibold.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Semibold.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Regular.eot');
  src: local('Manrope Regular'), local('Manrope-Regular'),
    url('./assets/fonts/Manrope/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Regular.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Regular.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Light.eot');
  src: local('Manrope Light'), local('Manrope-Light'),
    url('./assets/fonts/Manrope/Manrope-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Light.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Light.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Thin.eot');
  src: local('Manrope Thin'), local('Manrope-Thin'),
    url('./assets/fonts/Manrope/Manrope-Thin.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Thin.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Thin.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-Medium.eot');
  src: local('Manrope Medium'), local('Manrope-Medium'),
    url('./assets/fonts/Manrope/Manrope-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-Medium.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-Medium.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope/Manrope-ExtraBold.eot');
  src: local('Manrope ExtraBold'), local('Manrope-ExtraBold'),
    url('./assets/fonts/Manrope/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Manrope/Manrope-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Manrope/Manrope-ExtraBold.woff') format('woff'),
    url('./assets/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
.v-text-field > .v-input__control > .v-input__slot{
  border: 1px solid #AAABAD!important;
  box-sizing: border-box!important;
  border-radius: 6px!important;
  background: #FFFFFF!important;
  padding: 11px!important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #808185;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
}
.v-expansion-panel-header .v-expansion-panel-header--active{
  background: red;
}
.v-application .primary{
  background-color: #fff!important;
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle{
  white-space: inherit!important;
}
.v-tab{
  text-transform: none!important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
  box-shadow: none !important;
}
.input_custom .v-input__control .v-input__slot:after{
  display: none;
}
.input_custom > .v-input__control > .v-input__slot{
  padding: 9px!important;
}
.v-input__icon{
  height: 20px;
}
.v-select__selection--comma{
  margin: 0;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  display: none;
}
.v-expansion-panel:not(:first-child)::after{
  display: none;
}
.v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel{
  margin-top: 0!important;
}
.v-expansion-panel-content__wrap{
  padding: 0!important;
}
.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: initial !important;
}
.my_input.locked .v-input__control .v-input__slot{
  background: #F4F4F4;
}
.theme--light.v-data-table tbody tr.v-data-table__selected{
  background: #E6F6F8!important;
}
.v-input--is-focused .v-input__slot{
  box-shadow: 0px 0px 0px 1px #00A5BC;
  border-radius: 4px;
}
.mini_input .v-input__control .v-input__slot{
  text-align: center;
  padding: 7px!important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}
.mini_input .v-input__control .v-input__slot input{
  text-align: center;
}
.v-expansion-panel-content__wrap{
  padding: 0 12px 16px 12px!important;
}
.my_expand_panel .v-expansion-panel-header{
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
}
.mih40 .v-input__control .v-input__slot{
  min-height: 40px;
}
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 40px;
}
.p2 .v-input__control .v-input__slot{
  padding: 2px!important;
}
.p2 .v-input__control .v-input__slot .v-select__slot .v-input__append-inner{
  margin-top: 10px;
}
.v-tabs-items{
  border-radius: 8px;
}
@media (max-width: 960px){
  .v-toolbar__content{
    padding: 0!important;
  }
  .balance.top .text{
    display: none;
  }
  .balance.top{
    margin-right: 30px;
  }
  .account.top .name{
    display: none;
  }
  .account.top .icon:last-child{
    display: none;
  }
  .replenishment.top{
    display: none;
  }
}
@media (min-width: 960px){
  .replenishment.left{
    display: none;
  }
}
@media (min-width: 500px){
  .bottom .exit{
    display: none;
  }
  .bottom .balance .colu{
    display: none;
  }
  .bottom .account{
    display: none;
  }
  .replenishment.left{
    margin-bottom: 50px;
  }
}
@media (max-width: 500px){
  .right .balance{
    display: none;
  }
  .right .account{
    display: none;
  }
  .notification{
    margin-right: 0!important;
  }
  .replenishment.left{
    margin-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 650px){
  .render{
    padding: 10px;
  }
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev{
    display: none!important;
  }
  .v-slide-group__content{
    justify-content: center;
  }
}
@media (max-width: 400px){
  .render{
    padding: 0;
  }
  .v-application ul{
    padding-left: 10px;
    padding-bottom: 0;
  }
}
</style>
