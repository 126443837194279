<template>
  <div class="render">
	<AccountActivation/>
<!--    <v-breadcrumbs class="bc" v-if="!history_hide" :items="breadcrumbs" large>-->
<!--      <template v-slot:item="{ item }">-->
<!--        <v-breadcrumbs-item-->
<!--          @click="returnMain(item.click)"-->
<!--          :disabled="item.disabled"-->
<!--        >-->
<!--          {{ item.text }}-->
<!--        </v-breadcrumbs-item>-->
<!--      </template>-->
<!--    </v-breadcrumbs>-->
    <div v-if="history_hide" class="content">
      <div class="right">
        <h1>Настройки аккаунта</h1>

        <div class="input_wrapper">
          <div class="label">Номер телефона</div>
          <v-text-field :placeholder="$store.getters['user/getName']" type="phone" disabled="disabled"></v-text-field>
        </div>

        <h2>Смена пароля</h2>
        <div class="password_wrapper">
          <div class="input_wrapper">
            <div class="label">Введите текущий пароль</div>
            <v-text-field v-model="password" placeholder="********" :type="type"></v-text-field>
            
          </div>
          <div class="input_wrapper">
            <div class="label">Введите новый пароль</div>
            <v-text-field  v-model="new_password" placeholder="********" :type="type"></v-text-field>
          </div>
          <div @click="changeType" :class="'btn_change ' + status"></div>
        </div>
        <div v-if="update_password_status == 2" class="v_email_updated_massage">Пароль успешно изменен</div>
        <div v-if="update_password_status == 1" class="v_err_updated_massage">Текущий пароль введен не верно</div>
        <div v-if="update_password_status == 3" class="v_err_updated_massage">Новый пароль слишком короткий, минимум 7 символов</div>
        <div @click="editPassword()" class="save">Сохранить</div>

        <h2>Уведомления</h2>
        <div class="input_wrappe">
          <div class="label">Email для уведомлений</div>
          <v-text-field v-model="email" placeholder="Pochta@mail.ru" type="email"></v-text-field>
          <div v-if="email_upadted" class="v_email_updated_massage">Email успешно изменен</div>
        </div>
        <div @click="editEmail()" class="save mb26">Сохранить</div>
        
        <h2>Доступ к openAPI</h2>
          <div class="w-full">
            <div class="flex flex-row ">
              <div class="">Ключ для доступа к API</div>  
            </div>
            <div class="flex flex-row items-center">
            <v-text-field v-model="keyApi"  />
              <div @click="generateKeyApi()" class="save ml-2 mb-4 pt-3 ">Сгенерировать</div>    
            </div>
          </div>
          <div @click="saveKeyApi" class="save mb26">Сохранить</div>

        <v-checkbox
          v-model="call_end_notification"
          color="#FEE600"
          @click="editNotification()"
          label="Уведомления о начале и конце обзвона"
        ></v-checkbox>
        <v-checkbox
          v-model="low_balance_notification"
          append-icon
          @click="editNotification()"
          color="#FEE600"
          label="Уведомления о низком балансе"
        ></v-checkbox>

      </div>
    </div>
    <div v-if="!history_hide" class="content_table">
      <div class="history_title">История баланса</div>
      <div class="desc">Здесь отображены все денежные операции вашего аккаунта</div>
      <Table :headers="headers" :data="data" :hideselect="true" />
    </div>
    <!-- <CompanyData /> -->
<!--    <SuccessBalance :dialogstate="showSuccessBalance" :dialogclose="closeSuccessBalance" />-->
<!--    <Balance :dialogstate="showBalanceAdd" :dialogclose="closeBalanceAdd" :opensucces="openSuccessBalance" :updatetable="getBalanceTable"/>-->
<!--    <CompanyEdit :dialogstate="showCompanyEdit" :dialogclose="closeCompanyEdit" :setbar="setTopBar"/>-->
  </div>
</template>
<script>
// import SuccessBalance from '../components/Modal/Cabinet/BalanceSuccess.vue'
// import CompanyData from '../components/Modal/Cabinet/CompanyData.vue'
//import CompanyEdit from '../components/Modal/Cabinet/CompanyEdit.vue'
// import Balance from '../components/Modal/Cabinet/BalanceAdd.vue'
//import Icon from '../components/SetIcon.vue'
import Table from '../components/Table/DataTable.vue'
import AccountActivation from '@/components/Modal/Cabinet/AccountActivation.vue'
export default {
  components: {
    // SuccessBalance,
	// Icon,
	Table,
	//CompanyEdit,
	// Balance,
	AccountActivation
  },
  data(){
    return {
      history_hide: true,
      // activated: this.$store.getters['user/getUserFields'].user_status,
      email:this.$store.getters['user/getEmail'],
      balance:this.$store.getters['user/getUserFields'].balance,
      email_upadted:false,
      password:"",
      status: 'hide',
      type: 'password',
      new_password:"",
      update_password_status:0,
      call_end_notification:this.$store.getters['user/getUserFields'].call_end_notification,
      low_balance_notification:this.$store.getters['user/getUserFields'].low_balance_notification,
      headers: [
        { text: '№', align: 'start', value: 'id' },
        { text: 'Тип операции', value: 'type' },
        { text: 'Сумма', value: 'sum' },
        { text: 'Дата обзвона', value: 'date' },
        { text: 'Статус', value: 'status' }
      ],
      data: this.$store.getters['user/getUserFields'].payments,
      // breadcrumbs: [
      //   {
      //     text: 'Личный кабинет',
      //     disabled: false,
      //     click:'index'
      //   },
      //   {
      //     text: 'История баланса',
      //     disabled: true,
      //     click: false,
      //   },
      // ],
      // showCompanyEdit:false,
      //showBalanceAdd:false,
      showSuccessBalance:false,
      keyApi: this.$store.getters[ 'user/getKeyApi' ] // Ключ для доступа к api
    }
  },
  methods: {
    // getBalanceTable(){
    //   this.data = this.$store.getters['user/getUserFields'].payments
	//
    // },
    // openSuccessBalance(){
    //   this.showSuccessBalance = true;
    // },
    // closeSuccessBalance(){
    //   this.showSuccessBalance = false
    // },
    // openBalanceAdd(){
    //   this.showBalanceAdd = true;
    // },
    // closeBalanceAdd(){
    //   this.showBalanceAdd = false
    // },
    // returnMain(page){
    //   if (page=="index"){
    //     this.history_hide = true
    //   }
    // },
    
    saveKeyApi(){
      this.$http.post("/cabinet/update_open_api_key",
        {
          keyApi: this.keyApi
        }, (result) => {
          if (result == true) {
            this.email_upadted = true;
            this.$store.commit('user/setEmail', { email: this.email })
          }

        })
    },
    
    /** 
    * Генерация ключа для api 
    * @function generateKeyApi
    */
    generateKeyApi(){
        this.keyApi = ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[ 0 ] & 15 >> c / 4).toString(16)) 
    },
    editEmail(){
      this.$http.post("/cabinet/update_email",
      {
        email:this.email
      },(result)=>{
        if(result == true){
          this.email_upadted = true;
          this.$store.commit('user/setEmail', {email:this.email})
        }

      })
    },
    editNotification(){
      this.$http.post("/cabinet/update_notofication",
      {
        low_balance_notification:this.low_balance_notification,
        call_end_notification:this.call_end_notification
      },(result)=>{
        if(result == true){
          console.log("ok")
          this.$store.commit('user/setNotifications', {
            low_balance_notification:this.low_balance_notification,
            call_end_notification:this.call_end_notification
          })

        }

      })
    },
	editPassword() {
		if (this.new_password.length > 6) {
			this.$http.post("/cabinet/update_password",
			{
					password: this.password,
					new_password: this.new_password
				}, (result) => {
					if (result == true) {
						this.update_password_status = 2;
					} else {
						this.update_password_status = 1;
					}
				})
		} else {
			this.update_password_status = 3;
		}

	},
  changeType(){
      if(this.type == 'password') {
        this.type = 'text'
        this.status = 'visible'
      }
      else {
        this.type = 'password'
        this.status = 'hide'
      }
    },

  },
}
</script>
<style scoped>
.password_wrapper{
  position: relative;
}
.btn_change{
  position: absolute;
  right: 10px;
  top: 38px;
  width: 21px;
  height: 12px;
}
.hide{
  background: url('../assets/img/Eye.svg');
}
.visible{
  background: url('../assets/img/NoEye.svg');
  height: 21px;
  top: 34.5px;
}
.v_email_updated_massage{
  text-align: left;
  color: green;
}
.v_err_updated_massage{
  text-align: left;
  color: #b01111;
}
.mb26{
  margin-bottom: 26px;
}
.top{
  margin-bottom: 24px;
}
.history_title{
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-bottom: 8px;
}
.desc{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #55575C;
  text-align: left;
  margin-bottom: 16px;
}
.ok{
  margin-top: 30px;
  background: #E8F7F4;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
}
.cashup{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #2B2D33;
  background: #FEE600;
  border-radius: 8px;
  padding: 13px;
  margin-top: 40px;
  cursor: pointer;
}
.history{
  margin: 8px auto 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #00809C;
  cursor: pointer;
  position: relative;
  width: max-content;
}
.history:after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #00809C;
  position: absolute;
  bottom: -4px;
}
.save{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  padding: 9px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  
  line-height: 110%;
  color: #2B2D33;
  max-width: max-content;
  cursor: pointer;
}
.top{
  display: flex;
  flex-direction: column;
}
.inform{
  display: flex;
}
.left{
  background: #FFFFFF;
  padding: 24px;
  min-width: 600px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 50%;
  height: max-content;
  margin-bottom: 50px;
}
.content{
  display: flex;
  flex-wrap: wrap;
}
.right{
  width: 50%;
  padding-left: 32px;
}
h1{
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #404247;
}
h2{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  color: #404247;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 24px;
}
.label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  color: #404247;
  text-align: left;
  margin-bottom: 6px;
}
.balance{
  width: 40%;
}
.left .title{
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 120%;
  color: #55575C;
  text-align: left;
}
.left .num{
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #404247;
  text-align: left;
}
.alert{
  background: #FEF3EC;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}
.left_text{
  display: flex;
  align-items: center;
}
.left_text .text{
  margin-left: 18px;
}
.mmm_data{
  background: #FFFFFF;
  border: 1px solid #FEE600;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
  text-align: center;
  color: #2B2D33;
  padding: 7px 17px;
  cursor: pointer;
}
.ok .text{
  color: #008E7D;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}
@media(max-width: 820px){
  .alert{
    flex-direction: column;
  }
  .mmm_data{
    margin-top: 15px;
  }
}
@media(max-width: 650px){
  .left{
    margin: 15px;
    min-width: initial;
    width: 100%;
  }
  .right{
    width: 100%;
    padding: 15px;
  }
}
@media(max-width: 520px){
  .inform{
    flex-direction: column;
  }
  .balance{
    margin-bottom: 15px;
  }
  .history_title{
    padding: 10px;
  }
  .desc{
    padding: 10px;
  }
}
</style>
