<template>
	<div class="render">
		<div v-if="!this.$store.getters['user/getUserFields'].is_admin">Вы не администратор, авторизируйтесь как администратор!</div>
		<div v-else class="content_table">
			<div class="top">
				<h1>Дополнительные услуги</h1>
			</div>
			<div class="words_control">
				<div @click="addService()" class="word_control_save">Добавить услугу</div>
				<div @click="saveExtraServices()" class="word_control_save ml-6">Сохранить</div>
				<p class="save_message" v-if="save_message">Сохранено!</p>
			</div>
			<div class="words_container">

				<div v-for="(card, key) in servicesArr" :key="key" class="word_block">
					<div class="services_card">

						Загрузка картинки
						<v-file-input
							accept="image/*"
							hide-details="auto"
							height="44px"
							class="mr-5 ml-5"
							v-model="card.img"
						></v-file-input>
						Заголовок услуги:
						<v-text-field v-model="card.text" class="ml-5" hide-details="auto" placeholder="Заголовок услуги" type="text"></v-text-field>
					</div>
					<div class="services_card">
						Цена услуги, от:
						<v-text-field v-model="card.cost" class="mr-5 ml-5" hide-details="auto" placeholder="Цена услуги" type="number"></v-text-field>
						Ссылка на услугу:
						<v-text-field v-model="card.link" class="mr-5 ml-5" hide-details="auto" placeholder="Ссылка" type="text"></v-text-field>
						Приоритет:
						<div class="input_wrapper ml-5">
							<div class="minus" @click="card.priority--">
								<Icon name="minus"/>
							</div>
							<v-text-field class="mini_input" hide-details="auto" min="0" v-model="card.priority"></v-text-field>
							<div class="plus" @click="card.priority++">
								<Icon name="plus"/>
							</div>
						</div>
					</div>
					<div class="word_control">
<!--						<p v-show="accessSaveMassage[key]" style="transition-duration: 0.4s; color:green;margin-bottom: 0;">Сохранено успешно</p>-->
						<div @click="deleteService(key)" class="word_control_delete">Удалить услугу</div>
<!--						<div @click="saveExtraServices()" class="word_control_save">Сохранить</div>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//import Vue from 'vue'
import Icon from '../../components/SetIcon.vue'
export default {
	name: "ExtraServices",
	components: {
		Icon
	},
	data() {
		return {
			accessSaveMassage: {},
			autoAnswer: [],
			servicesArr: [],
			save_message: false
		}
	},
	mounted() {
		this.renderExtraServices()
	},
	methods: {
		saveExtraServices() { // saveAutoAnswer
			this.$http.post("/extra_services/save_services",
				{
					arrServices: this.servicesArr
				}, (result) => {
					if (result) {
						this.saveMessage()
						this.renderExtraServices()
					}
				})
		},
		renderExtraServices() { // renderAutoAnswer
			this.$http.post("/extra_services/list_services",
				{}, (result) => {
					if (result) {
						this.servicesArr = result
					}
				})
		},
		saveMessage(){
			this.save_message = true
			setTimeout(() => {
				this.save_message = false
			}, 3000)
		},
		addService(){
			this.servicesArr.push({
				img: null,
				text: '',
				cost: '',
				link: '',
				priority: 0
			})
		},
		deleteService(key) {
			this.servicesArr.splice(key, 1)
			this.saveExtraServices()
		},

	},
}
</script>
<style scoped>

.save_message {
	height: 41px;
	margin: 0 0 0 35px;
	color: #1caf1c;
	padding: 8px;
}

.services_card {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.services_card .v-text-field .v-input__prepend-inner,
.services_card .v-text-field .v-input__append-inner {
	margin: 0;
	width: 20%;
}
.services_card .v-text-field > .v-input__control > .v-input__slot{
	height: 44px;
}

.v-input {
    align-items: center;
}

.words_container {
	margin-top: 25px;
}

.plus{
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  cursor: pointer;
  padding: 8px;
}
.minus{
  margin-right: 6px;
  border: 1px solid #FEE600;
  border-radius: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 16px 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input_wrapper{
  display: flex;
  align-items: center;
}



.lexicotab {
	padding: 20px;
}

.tabs_answer {
	padding-bottom: 20px;
}

.autoanswer_elems {

}

.words_control {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.addWord {
	max-width: 249px;
}

.word_control {
	display: flex;
	justify-content: flex-end;
	align-items: center;

}

.word_control_delete:hover {
	cursor: pointer;
	opacity: 1;
	transition-duration: 0.3s;
}

.word_control_delete {
	color: red;
	text-align: center;
	width: 200px;
	opacity: 0.7;
	transition-duration: 0.3s;
}

.word_control_save:hover {
	opacity: 1;
	transition-duration: 0.3s;
}

.word_control_save {
	border: none;
	padding: 12px 20px 12px 22px;
	background: #fee600 !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	color: #2b2d33;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 200px;
	opacity: 0.8;
	transition-duration: 0.3s;
}

.subword_sec_plus {
	display: flex;
	align-items: center;
	justify-content: center;
}

.subword_sec_plus > .icon {
	background: #f4f4f4;
	padding: 15px;
	border-radius: 7px;
	opacity: 0.7;
	transition-duration: 0.3s;
}

.subword_sec_plus > .icon:hover {
	cursor: pointer;
	opacity: 1;
	transition-duration: 0.3s;
}

.subword_delete:hover {
	cursor: pointer;
	opacity: 1;
	transition-duration: 0.3s;
}

.subword_delete {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 15px;
	opacity: 0.7;
	transition-duration: 0.3s;
}

.subword_sec {
	display: flex;
	width: 24%;
	padding: 12px 12px 0px 12px;
	background: #f4f4f4;
	background: #f4f4f4;
	border-radius: 8px;
	justify-content: space-between;
}

.word_subwords {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 1rem;
}

.word_block {
	background: #FFFFFF;
	padding: 24px;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 6%), 0px 8px 24px rgb(0 0 0 / 10%);
	border-radius: 16px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-bottom: 10px;
}

div {
	text-align: left;
}

p {
	text-align: left;
}

.v_email_updated_massage {
	text-align: left;
	color: green;
}

.v_err_updated_massage {
	text-align: left;
	color: #b01111;
}

.mb26 {
	margin-bottom: 26px;
}

.top {
	margin-bottom: 24px;
}

.history_title {
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 8px;
}

.desc {
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 150%;
	color: #55575C;
	text-align: left;
	margin-bottom: 16px;
}

.ok {
	margin-top: 30px;
	background: #E8F7F4;
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px;
}

.cashup {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;
	color: #2B2D33;
	background: #FEE600;
	border-radius: 8px;
	padding: 13px;
	margin-top: 40px;
	cursor: pointer;
}

.history {
	margin: 8px auto 0 auto;
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 120%;
	color: #00809C;
	cursor: pointer;
	position: relative;
	width: max-content;
}

.history:after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	background: #00809C;
	position: absolute;
	bottom: -4px;
}

.save {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	padding: 9px 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	margin-top: 16px;
	line-height: 110%;
	color: #2B2D33;
	max-width: max-content;
	cursor: pointer;
}

.top {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.inform {
	display: flex;
}

.left {
	background: #FFFFFF;
	padding: 24px;

	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 8px 24px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	width: 50%;
	height: max-content;
}

.content {
	display: flex;
}

.right {
	width: 50%;
	padding-left: 32px;
}

h1 {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 130%;
	color: #404247;
}

h2 {
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 130%;
	color: #404247;
	text-align: left;
	margin-bottom: 24px;
}

.label {
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 120%;
	color: #404247;
	text-align: left;
	margin-bottom: 6px;
}

.balance {
	width: 40%;
}

.left .title {
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 120%;
	color: #55575C;
	text-align: left;
}

.left .num {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 130%;
	color: #404247;
	text-align: left;
}

.alert {
	background: #FEF3EC;
	padding: 16px 24px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;

}

.left_text {
	display: flex;
	align-items: center;
}

.left_text .text {
	margin-left: 18px;
}

.mmm_data {
	background: #FFFFFF;
	border: 1px solid #FEE600;
	border-radius: 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 110%;
	text-align: center;
	color: #2B2D33;
	padding: 7px 17px;
	cursor: pointer;
}

.vback {
	border: 1px solid #c3c4c6;
	padding: 10px;
	border-radius: 10px;
}

.vback:hover {
	cursor: pointer;
}

.ok .text {
	color: #008E7D;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 120%;
}

.autonans_sub {
	width: 99%;
	margin: 10px 0;
}
</style>
